@font-face {
  font-family: "game-icons";
  src:
    url("./fonts/game-icons.eot?817af6e52c83163eb30ece54d9f7d16d?#iefix")
      format("embedded-opentype"),
    url("./fonts/game-icons.woff?817af6e52c83163eb30ece54d9f7d16d")
      format("woff"),
    url("./fonts/game-icons.ttf?817af6e52c83163eb30ece54d9f7d16d")
      format("truetype");
}

.game-icon {
  line-height: 1;
}

.game-icon:before {
  font-family: game-icons !important;
  font-style: normal;
  font-weight: normal !important;
  vertical-align: top;
}

.game-icon-3d-glasses:before {
  content: "\ff000";
}
.game-icon-3d-hammer:before {
  content: "\ff001";
}
.game-icon-3d-meeple:before {
  content: "\ff002";
}
.game-icon-3d-stairs:before {
  content: "\ff003";
}
.game-icon-abacus:before {
  content: "\ff004";
}
.game-icon-abbot-meeple:before {
  content: "\ff005";
}
.game-icon-abdominal-armor:before {
  content: "\ff006";
}
.game-icon-abstract-001:before {
  content: "\ff007";
}
.game-icon-abstract-002:before {
  content: "\ff008";
}
.game-icon-abstract-003:before {
  content: "\ff009";
}
.game-icon-abstract-004:before {
  content: "\ff00a";
}
.game-icon-abstract-005:before {
  content: "\ff00b";
}
.game-icon-abstract-006:before {
  content: "\ff00c";
}
.game-icon-abstract-007:before {
  content: "\ff00d";
}
.game-icon-abstract-008:before {
  content: "\ff00e";
}
.game-icon-abstract-009:before {
  content: "\ff00f";
}
.game-icon-abstract-010:before {
  content: "\ff010";
}
.game-icon-abstract-011:before {
  content: "\ff011";
}
.game-icon-abstract-012:before {
  content: "\ff012";
}
.game-icon-abstract-013:before {
  content: "\ff013";
}
.game-icon-abstract-014:before {
  content: "\ff014";
}
.game-icon-abstract-015:before {
  content: "\ff015";
}
.game-icon-abstract-016:before {
  content: "\ff016";
}
.game-icon-abstract-017:before {
  content: "\ff017";
}
.game-icon-abstract-018:before {
  content: "\ff018";
}
.game-icon-abstract-019:before {
  content: "\ff019";
}
.game-icon-abstract-020:before {
  content: "\ff01a";
}
.game-icon-abstract-021:before {
  content: "\ff01b";
}
.game-icon-abstract-022:before {
  content: "\ff01c";
}
.game-icon-abstract-023:before {
  content: "\ff01d";
}
.game-icon-abstract-024:before {
  content: "\ff01e";
}
.game-icon-abstract-025:before {
  content: "\ff01f";
}
.game-icon-abstract-026:before {
  content: "\ff020";
}
.game-icon-abstract-027:before {
  content: "\ff021";
}
.game-icon-abstract-028:before {
  content: "\ff022";
}
.game-icon-abstract-029:before {
  content: "\ff023";
}
.game-icon-abstract-030:before {
  content: "\ff024";
}
.game-icon-abstract-031:before {
  content: "\ff025";
}
.game-icon-abstract-032:before {
  content: "\ff026";
}
.game-icon-abstract-033:before {
  content: "\ff027";
}
.game-icon-abstract-034:before {
  content: "\ff028";
}
.game-icon-abstract-035:before {
  content: "\ff029";
}
.game-icon-abstract-036:before {
  content: "\ff02a";
}
.game-icon-abstract-037:before {
  content: "\ff02b";
}
.game-icon-abstract-038:before {
  content: "\ff02c";
}
.game-icon-abstract-039:before {
  content: "\ff02d";
}
.game-icon-abstract-040:before {
  content: "\ff02e";
}
.game-icon-abstract-041:before {
  content: "\ff02f";
}
.game-icon-abstract-042:before {
  content: "\ff030";
}
.game-icon-abstract-043:before {
  content: "\ff031";
}
.game-icon-abstract-044:before {
  content: "\ff032";
}
.game-icon-abstract-045:before {
  content: "\ff033";
}
.game-icon-abstract-046:before {
  content: "\ff034";
}
.game-icon-abstract-047:before {
  content: "\ff035";
}
.game-icon-abstract-048:before {
  content: "\ff036";
}
.game-icon-abstract-049:before {
  content: "\ff037";
}
.game-icon-abstract-050:before {
  content: "\ff038";
}
.game-icon-abstract-051:before {
  content: "\ff039";
}
.game-icon-abstract-052:before {
  content: "\ff03a";
}
.game-icon-abstract-053:before {
  content: "\ff03b";
}
.game-icon-abstract-054:before {
  content: "\ff03c";
}
.game-icon-abstract-055:before {
  content: "\ff03d";
}
.game-icon-abstract-056:before {
  content: "\ff03e";
}
.game-icon-abstract-057:before {
  content: "\ff03f";
}
.game-icon-abstract-058:before {
  content: "\ff040";
}
.game-icon-abstract-059:before {
  content: "\ff041";
}
.game-icon-abstract-060:before {
  content: "\ff042";
}
.game-icon-abstract-061:before {
  content: "\ff043";
}
.game-icon-abstract-062:before {
  content: "\ff044";
}
.game-icon-abstract-063:before {
  content: "\ff045";
}
.game-icon-abstract-064:before {
  content: "\ff046";
}
.game-icon-abstract-065:before {
  content: "\ff047";
}
.game-icon-abstract-066:before {
  content: "\ff048";
}
.game-icon-abstract-067:before {
  content: "\ff049";
}
.game-icon-abstract-068:before {
  content: "\ff04a";
}
.game-icon-abstract-069:before {
  content: "\ff04b";
}
.game-icon-abstract-070:before {
  content: "\ff04c";
}
.game-icon-abstract-071:before {
  content: "\ff04d";
}
.game-icon-abstract-072:before {
  content: "\ff04e";
}
.game-icon-abstract-073:before {
  content: "\ff04f";
}
.game-icon-abstract-074:before {
  content: "\ff050";
}
.game-icon-abstract-075:before {
  content: "\ff051";
}
.game-icon-abstract-076:before {
  content: "\ff052";
}
.game-icon-abstract-077:before {
  content: "\ff053";
}
.game-icon-abstract-078:before {
  content: "\ff054";
}
.game-icon-abstract-079:before {
  content: "\ff055";
}
.game-icon-abstract-080:before {
  content: "\ff056";
}
.game-icon-abstract-081:before {
  content: "\ff057";
}
.game-icon-abstract-082:before {
  content: "\ff058";
}
.game-icon-abstract-083:before {
  content: "\ff059";
}
.game-icon-abstract-084:before {
  content: "\ff05a";
}
.game-icon-abstract-085:before {
  content: "\ff05b";
}
.game-icon-abstract-086:before {
  content: "\ff05c";
}
.game-icon-abstract-087:before {
  content: "\ff05d";
}
.game-icon-abstract-088:before {
  content: "\ff05e";
}
.game-icon-abstract-089:before {
  content: "\ff05f";
}
.game-icon-abstract-090:before {
  content: "\ff060";
}
.game-icon-abstract-091:before {
  content: "\ff061";
}
.game-icon-abstract-092:before {
  content: "\ff062";
}
.game-icon-abstract-093:before {
  content: "\ff063";
}
.game-icon-abstract-094:before {
  content: "\ff064";
}
.game-icon-abstract-095:before {
  content: "\ff065";
}
.game-icon-abstract-096:before {
  content: "\ff066";
}
.game-icon-abstract-097:before {
  content: "\ff067";
}
.game-icon-abstract-098:before {
  content: "\ff068";
}
.game-icon-abstract-099:before {
  content: "\ff069";
}
.game-icon-abstract-100:before {
  content: "\ff06a";
}
.game-icon-abstract-101:before {
  content: "\ff06b";
}
.game-icon-abstract-102:before {
  content: "\ff06c";
}
.game-icon-abstract-103:before {
  content: "\ff06d";
}
.game-icon-abstract-104:before {
  content: "\ff06e";
}
.game-icon-abstract-105:before {
  content: "\ff06f";
}
.game-icon-abstract-106:before {
  content: "\ff070";
}
.game-icon-abstract-107:before {
  content: "\ff071";
}
.game-icon-abstract-108:before {
  content: "\ff072";
}
.game-icon-abstract-109:before {
  content: "\ff073";
}
.game-icon-abstract-110:before {
  content: "\ff074";
}
.game-icon-abstract-111:before {
  content: "\ff075";
}
.game-icon-abstract-112:before {
  content: "\ff076";
}
.game-icon-abstract-113:before {
  content: "\ff077";
}
.game-icon-abstract-114:before {
  content: "\ff078";
}
.game-icon-abstract-115:before {
  content: "\ff079";
}
.game-icon-abstract-116:before {
  content: "\ff07a";
}
.game-icon-abstract-117:before {
  content: "\ff07b";
}
.game-icon-abstract-118:before {
  content: "\ff07c";
}
.game-icon-abstract-119:before {
  content: "\ff07d";
}
.game-icon-abstract-120:before {
  content: "\ff07e";
}
.game-icon-abstract-121:before {
  content: "\ff07f";
}
.game-icon-accordion:before {
  content: "\ff080";
}
.game-icon-ace:before {
  content: "\ff081";
}
.game-icon-achievement:before {
  content: "\ff082";
}
.game-icon-achilles-heel:before {
  content: "\ff083";
}
.game-icon-acid-blob:before {
  content: "\ff084";
}
.game-icon-acid-tube:before {
  content: "\ff085";
}
.game-icon-acid:before {
  content: "\ff086";
}
.game-icon-acorn:before {
  content: "\ff087";
}
.game-icon-acoustic-megaphone:before {
  content: "\ff088";
}
.game-icon-acrobatic:before {
  content: "\ff089";
}
.game-icon-aerial-signal:before {
  content: "\ff08a";
}
.game-icon-aerodynamic-harpoon:before {
  content: "\ff08b";
}
.game-icon-aerosol:before {
  content: "\ff08c";
}
.game-icon-africa:before {
  content: "\ff08d";
}
.game-icon-afterburn:before {
  content: "\ff08e";
}
.game-icon-agave:before {
  content: "\ff08f";
}
.game-icon-ages:before {
  content: "\ff090";
}
.game-icon-air-balloon:before {
  content: "\ff091";
}
.game-icon-air-force:before {
  content: "\ff092";
}
.game-icon-air-man:before {
  content: "\ff093";
}
.game-icon-air-zigzag:before {
  content: "\ff094";
}
.game-icon-airplane-arrival:before {
  content: "\ff095";
}
.game-icon-airplane-departure:before {
  content: "\ff096";
}
.game-icon-airplane:before {
  content: "\ff097";
}
.game-icon-airtight-hatch:before {
  content: "\ff098";
}
.game-icon-ak47:before {
  content: "\ff099";
}
.game-icon-ak47u:before {
  content: "\ff09a";
}
.game-icon-akhet:before {
  content: "\ff09b";
}
.game-icon-alarm-clock:before {
  content: "\ff09c";
}
.game-icon-algae:before {
  content: "\ff09d";
}
.game-icon-algeria:before {
  content: "\ff09e";
}
.game-icon-alien-bug:before {
  content: "\ff09f";
}
.game-icon-alien-egg:before {
  content: "\ff0a0";
}
.game-icon-alien-fire:before {
  content: "\ff0a1";
}
.game-icon-alien-skull:before {
  content: "\ff0a2";
}
.game-icon-alien-stare:before {
  content: "\ff0a3";
}
.game-icon-all-for-one:before {
  content: "\ff0a4";
}
.game-icon-all-seeing-eye:before {
  content: "\ff0a5";
}
.game-icon-allied-star:before {
  content: "\ff0a6";
}
.game-icon-alligator-clip:before {
  content: "\ff0a7";
}
.game-icon-almond:before {
  content: "\ff0a8";
}
.game-icon-amber-mosquito:before {
  content: "\ff0a9";
}
.game-icon-ambulance:before {
  content: "\ff0aa";
}
.game-icon-american-football-ball:before {
  content: "\ff0ab";
}
.game-icon-american-football-helmet:before {
  content: "\ff0ac";
}
.game-icon-american-football-player:before {
  content: "\ff0ad";
}
.game-icon-american-shield:before {
  content: "\ff0ae";
}
.game-icon-amethyst:before {
  content: "\ff0af";
}
.game-icon-ammo-box:before {
  content: "\ff0b0";
}
.game-icon-ammonite-fossil:before {
  content: "\ff0b1";
}
.game-icon-ammonite:before {
  content: "\ff0b2";
}
.game-icon-amphora:before {
  content: "\ff0b3";
}
.game-icon-ample-dress:before {
  content: "\ff0b4";
}
.game-icon-amplitude:before {
  content: "\ff0b5";
}
.game-icon-amputation:before {
  content: "\ff0b6";
}
.game-icon-anarchy:before {
  content: "\ff0b7";
}
.game-icon-anatomy:before {
  content: "\ff0b8";
}
.game-icon-anchor:before {
  content: "\ff0b9";
}
.game-icon-ancient-columns:before {
  content: "\ff0ba";
}
.game-icon-ancient-ruins:before {
  content: "\ff0bb";
}
.game-icon-ancient-screw:before {
  content: "\ff0bc";
}
.game-icon-ancient-sword:before {
  content: "\ff0bd";
}
.game-icon-android-mask:before {
  content: "\ff0be";
}
.game-icon-andromeda-chain:before {
  content: "\ff0bf";
}
.game-icon-angel-outfit:before {
  content: "\ff0c0";
}
.game-icon-angel-wings:before {
  content: "\ff0c1";
}
.game-icon-angler-fish:before {
  content: "\ff0c2";
}
.game-icon-angola:before {
  content: "\ff0c3";
}
.game-icon-angry-eyes:before {
  content: "\ff0c4";
}
.game-icon-angular-spider:before {
  content: "\ff0c5";
}
.game-icon-animal-hide:before {
  content: "\ff0c6";
}
.game-icon-animal-skull:before {
  content: "\ff0c7";
}
.game-icon-ankh:before {
  content: "\ff0c8";
}
.game-icon-annexation:before {
  content: "\ff0c9";
}
.game-icon-ant:before {
  content: "\ff0ca";
}
.game-icon-antarctica:before {
  content: "\ff0cb";
}
.game-icon-anteater:before {
  content: "\ff0cc";
}
.game-icon-anthem:before {
  content: "\ff0cd";
}
.game-icon-anti-aircraft-gun:before {
  content: "\ff0ce";
}
.game-icon-antibody:before {
  content: "\ff0cf";
}
.game-icon-anticlockwise-rotation:before {
  content: "\ff0d0";
}
.game-icon-ants:before {
  content: "\ff0d1";
}
.game-icon-anubis:before {
  content: "\ff0d2";
}
.game-icon-anvil-impact:before {
  content: "\ff0d3";
}
.game-icon-anvil:before {
  content: "\ff0d4";
}
.game-icon-apc:before {
  content: "\ff0d5";
}
.game-icon-apollo-capsule:before {
  content: "\ff0d6";
}
.game-icon-apothecary:before {
  content: "\ff0d7";
}
.game-icon-apple-core:before {
  content: "\ff0d8";
}
.game-icon-apple-maggot:before {
  content: "\ff0d9";
}
.game-icon-apple-seeds:before {
  content: "\ff0da";
}
.game-icon-aquarium:before {
  content: "\ff0db";
}
.game-icon-aquarius:before {
  content: "\ff0dc";
}
.game-icon-aqueduct:before {
  content: "\ff0dd";
}
.game-icon-arabic-door:before {
  content: "\ff0de";
}
.game-icon-arc-triomphe:before {
  content: "\ff0df";
}
.game-icon-arch-bridge:before {
  content: "\ff0e0";
}
.game-icon-archaeopteryx-fossil:before {
  content: "\ff0e1";
}
.game-icon-archer:before {
  content: "\ff0e2";
}
.game-icon-archery-target:before {
  content: "\ff0e3";
}
.game-icon-architect-mask:before {
  content: "\ff0e4";
}
.game-icon-archive-register:before {
  content: "\ff0e5";
}
.game-icon-archive-research:before {
  content: "\ff0e6";
}
.game-icon-arcing-bolt:before {
  content: "\ff0e7";
}
.game-icon-arena:before {
  content: "\ff0e8";
}
.game-icon-aries:before {
  content: "\ff0e9";
}
.game-icon-arm-bandage:before {
  content: "\ff0ea";
}
.game-icon-arm-sling:before {
  content: "\ff0eb";
}
.game-icon-arm:before {
  content: "\ff0ec";
}
.game-icon-armadillo-tail:before {
  content: "\ff0ed";
}
.game-icon-armadillo:before {
  content: "\ff0ee";
}
.game-icon-armor-downgrade:before {
  content: "\ff0ef";
}
.game-icon-armor-punch:before {
  content: "\ff0f0";
}
.game-icon-armor-upgrade:before {
  content: "\ff0f1";
}
.game-icon-armor-vest:before {
  content: "\ff0f2";
}
.game-icon-armored-boomerang:before {
  content: "\ff0f3";
}
.game-icon-armored-pants:before {
  content: "\ff0f4";
}
.game-icon-armoured-shell:before {
  content: "\ff0f5";
}
.game-icon-arrest:before {
  content: "\ff0f6";
}
.game-icon-arrow-cluster:before {
  content: "\ff0f7";
}
.game-icon-arrow-cursor:before {
  content: "\ff0f8";
}
.game-icon-arrow-dunk:before {
  content: "\ff0f9";
}
.game-icon-arrow-flights:before {
  content: "\ff0fa";
}
.game-icon-arrow-scope:before {
  content: "\ff0fb";
}
.game-icon-arrow-wings:before {
  content: "\ff0fc";
}
.game-icon-arrowed:before {
  content: "\ff0fd";
}
.game-icon-arrowhead:before {
  content: "\ff0fe";
}
.game-icon-arrows-shield:before {
  content: "\ff0ff";
}
.game-icon-arson:before {
  content: "\ff100";
}
.game-icon-artificial-hive:before {
  content: "\ff101";
}
.game-icon-artificial-intelligence:before {
  content: "\ff102";
}
.game-icon-artillery-shell:before {
  content: "\ff103";
}
.game-icon-ascending-block:before {
  content: "\ff104";
}
.game-icon-asian-lantern:before {
  content: "\ff105";
}
.game-icon-asparagus:before {
  content: "\ff106";
}
.game-icon-aspergillum:before {
  content: "\ff107";
}
.game-icon-assassin-pocket:before {
  content: "\ff108";
}
.game-icon-asteroid:before {
  content: "\ff109";
}
.game-icon-astrolabe:before {
  content: "\ff10a";
}
.game-icon-astronaut-helmet:before {
  content: "\ff10b";
}
.game-icon-at-sea:before {
  content: "\ff10c";
}
.game-icon-atlas:before {
  content: "\ff10d";
}
.game-icon-atom-core:before {
  content: "\ff10e";
}
.game-icon-atom:before {
  content: "\ff10f";
}
.game-icon-atomic-slashes:before {
  content: "\ff110";
}
.game-icon-attached-shield:before {
  content: "\ff111";
}
.game-icon-aubergine:before {
  content: "\ff112";
}
.game-icon-audio-cassette:before {
  content: "\ff113";
}
.game-icon-aura:before {
  content: "\ff114";
}
.game-icon-australia:before {
  content: "\ff115";
}
.game-icon-auto-repair:before {
  content: "\ff116";
}
.game-icon-autogun:before {
  content: "\ff117";
}
.game-icon-automatic-sas:before {
  content: "\ff118";
}
.game-icon-avocado:before {
  content: "\ff119";
}
.game-icon-avoidance:before {
  content: "\ff11a";
}
.game-icon-awareness:before {
  content: "\ff11b";
}
.game-icon-axe-in-log:before {
  content: "\ff11c";
}
.game-icon-axe-in-stump:before {
  content: "\ff11d";
}
.game-icon-axe-swing:before {
  content: "\ff11e";
}
.game-icon-axe-sword:before {
  content: "\ff11f";
}
.game-icon-axolotl:before {
  content: "\ff120";
}
.game-icon-aztec-calendar-sun:before {
  content: "\ff121";
}
.game-icon-azul-flake:before {
  content: "\ff122";
}
.game-icon-baby-bottle:before {
  content: "\ff123";
}
.game-icon-baby-face:before {
  content: "\ff124";
}
.game-icon-babyfoot-players:before {
  content: "\ff125";
}
.game-icon-back-forth:before {
  content: "\ff126";
}
.game-icon-back-pain:before {
  content: "\ff127";
}
.game-icon-backbone-shell:before {
  content: "\ff128";
}
.game-icon-backgammon:before {
  content: "\ff129";
}
.game-icon-backpack:before {
  content: "\ff12a";
}
.game-icon-backstab:before {
  content: "\ff12b";
}
.game-icon-backup:before {
  content: "\ff12c";
}
.game-icon-backward-time:before {
  content: "\ff12d";
}
.game-icon-bacon:before {
  content: "\ff12e";
}
.game-icon-bad-breath:before {
  content: "\ff12f";
}
.game-icon-bad-gnome:before {
  content: "\ff130";
}
.game-icon-bagpipes:before {
  content: "\ff131";
}
.game-icon-bal-leth:before {
  content: "\ff132";
}
.game-icon-balaclava:before {
  content: "\ff133";
}
.game-icon-balkenkreuz:before {
  content: "\ff134";
}
.game-icon-ball-glow:before {
  content: "\ff135";
}
.game-icon-ball-heart:before {
  content: "\ff136";
}
.game-icon-ball-pyramid:before {
  content: "\ff137";
}
.game-icon-ballerina-shoes:before {
  content: "\ff138";
}
.game-icon-ballista:before {
  content: "\ff139";
}
.game-icon-balloon-dog:before {
  content: "\ff13a";
}
.game-icon-balloons:before {
  content: "\ff13b";
}
.game-icon-bamboo-fountain:before {
  content: "\ff13c";
}
.game-icon-bamboo:before {
  content: "\ff13d";
}
.game-icon-banana-bunch:before {
  content: "\ff13e";
}
.game-icon-banana-peel:before {
  content: "\ff13f";
}
.game-icon-banana-peeled:before {
  content: "\ff140";
}
.game-icon-banana:before {
  content: "\ff141";
}
.game-icon-bandage-roll:before {
  content: "\ff142";
}
.game-icon-bandaged:before {
  content: "\ff143";
}
.game-icon-bandana:before {
  content: "\ff144";
}
.game-icon-bandit:before {
  content: "\ff145";
}
.game-icon-banging-gavel:before {
  content: "\ff146";
}
.game-icon-banjo:before {
  content: "\ff147";
}
.game-icon-bank:before {
  content: "\ff148";
}
.game-icon-banknote:before {
  content: "\ff149";
}
.game-icon-baobab:before {
  content: "\ff14a";
}
.game-icon-bar-stool:before {
  content: "\ff14b";
}
.game-icon-barbarian:before {
  content: "\ff14c";
}
.game-icon-barbecue:before {
  content: "\ff14d";
}
.game-icon-barbed-arrow:before {
  content: "\ff14e";
}
.game-icon-barbed-coil:before {
  content: "\ff14f";
}
.game-icon-barbed-nails:before {
  content: "\ff150";
}
.game-icon-barbed-spear:before {
  content: "\ff151";
}
.game-icon-barbed-star:before {
  content: "\ff152";
}
.game-icon-barbed-sun:before {
  content: "\ff153";
}
.game-icon-barbed-wire:before {
  content: "\ff154";
}
.game-icon-barbute:before {
  content: "\ff155";
}
.game-icon-barefoot:before {
  content: "\ff156";
}
.game-icon-barn:before {
  content: "\ff157";
}
.game-icon-barracks-tent:before {
  content: "\ff158";
}
.game-icon-barracks:before {
  content: "\ff159";
}
.game-icon-barrel-leak:before {
  content: "\ff15a";
}
.game-icon-barrel:before {
  content: "\ff15b";
}
.game-icon-barricade:before {
  content: "\ff15c";
}
.game-icon-barrier:before {
  content: "\ff15d";
}
.game-icon-base-dome:before {
  content: "\ff15e";
}
.game-icon-baseball-bat:before {
  content: "\ff15f";
}
.game-icon-baseball-glove:before {
  content: "\ff160";
}
.game-icon-basket:before {
  content: "\ff161";
}
.game-icon-basketball-ball:before {
  content: "\ff162";
}
.game-icon-basketball-basket:before {
  content: "\ff163";
}
.game-icon-basketball-jersey:before {
  content: "\ff164";
}
.game-icon-basset-hound-head:before {
  content: "\ff165";
}
.game-icon-bassoon:before {
  content: "\ff166";
}
.game-icon-bastet:before {
  content: "\ff167";
}
.game-icon-bat-2:before {
  content: "\ff168";
}
.game-icon-bat-blade:before {
  content: "\ff169";
}
.game-icon-bat-leth:before {
  content: "\ff16a";
}
.game-icon-bat-mask:before {
  content: "\ff16b";
}
.game-icon-bat-wing:before {
  content: "\ff16c";
}
.game-icon-bat:before {
  content: "\ff16d";
}
.game-icon-bathtub:before {
  content: "\ff16e";
}
.game-icon-baton:before {
  content: "\ff16f";
}
.game-icon-battered-axe:before {
  content: "\ff170";
}
.game-icon-batteries:before {
  content: "\ff171";
}
.game-icon-battery-0:before {
  content: "\ff172";
}
.game-icon-battery-100:before {
  content: "\ff173";
}
.game-icon-battery-25:before {
  content: "\ff174";
}
.game-icon-battery-50:before {
  content: "\ff175";
}
.game-icon-battery-75:before {
  content: "\ff176";
}
.game-icon-battery-minus:before {
  content: "\ff177";
}
.game-icon-battery-pack-alt:before {
  content: "\ff178";
}
.game-icon-battery-pack:before {
  content: "\ff179";
}
.game-icon-battery-plus:before {
  content: "\ff17a";
}
.game-icon-battle-axe:before {
  content: "\ff17b";
}
.game-icon-battle-gear:before {
  content: "\ff17c";
}
.game-icon-battle-mech:before {
  content: "\ff17d";
}
.game-icon-battle-tank:before {
  content: "\ff17e";
}
.game-icon-battleship:before {
  content: "\ff17f";
}
.game-icon-batwing-emblem:before {
  content: "\ff180";
}
.game-icon-bayonet:before {
  content: "\ff181";
}
.game-icon-beach-bag:before {
  content: "\ff182";
}
.game-icon-beach-ball:before {
  content: "\ff183";
}
.game-icon-beach-bucket:before {
  content: "\ff184";
}
.game-icon-beam-satellite:before {
  content: "\ff185";
}
.game-icon-beam-wake:before {
  content: "\ff186";
}
.game-icon-beams-aura:before {
  content: "\ff187";
}
.game-icon-beanstalk:before {
  content: "\ff188";
}
.game-icon-bear-face:before {
  content: "\ff189";
}
.game-icon-bear-head:before {
  content: "\ff18a";
}
.game-icon-beard:before {
  content: "\ff18b";
}
.game-icon-beast-eye:before {
  content: "\ff18c";
}
.game-icon-beaver:before {
  content: "\ff18d";
}
.game-icon-bed-lamp:before {
  content: "\ff18e";
}
.game-icon-bed:before {
  content: "\ff18f";
}
.game-icon-bee:before {
  content: "\ff190";
}
.game-icon-beech:before {
  content: "\ff191";
}
.game-icon-beehive:before {
  content: "\ff192";
}
.game-icon-beer-bottle:before {
  content: "\ff193";
}
.game-icon-beer-horn:before {
  content: "\ff194";
}
.game-icon-beer-stein:before {
  content: "\ff195";
}
.game-icon-beet:before {
  content: "\ff196";
}
.game-icon-beetle-shell:before {
  content: "\ff197";
}
.game-icon-behold:before {
  content: "\ff198";
}
.game-icon-belgium:before {
  content: "\ff199";
}
.game-icon-bell-pepper:before {
  content: "\ff19a";
}
.game-icon-bell-shield:before {
  content: "\ff19b";
}
.game-icon-bellows:before {
  content: "\ff19c";
}
.game-icon-belt-armor:before {
  content: "\ff19d";
}
.game-icon-belt-buckles:before {
  content: "\ff19e";
}
.game-icon-belt:before {
  content: "\ff19f";
}
.game-icon-berries-bowl:before {
  content: "\ff1a0";
}
.game-icon-berry-bush:before {
  content: "\ff1a1";
}
.game-icon-bestial-fangs:before {
  content: "\ff1a2";
}
.game-icon-beveled-star:before {
  content: "\ff1a3";
}
.game-icon-biceps:before {
  content: "\ff1a4";
}
.game-icon-big-diamond-ring:before {
  content: "\ff1a5";
}
.game-icon-big-egg:before {
  content: "\ff1a6";
}
.game-icon-big-gear:before {
  content: "\ff1a7";
}
.game-icon-big-wave:before {
  content: "\ff1a8";
}
.game-icon-billed-cap:before {
  content: "\ff1a9";
}
.game-icon-bindle:before {
  content: "\ff1aa";
}
.game-icon-binoculars:before {
  content: "\ff1ab";
}
.game-icon-biohazard:before {
  content: "\ff1ac";
}
.game-icon-biplane:before {
  content: "\ff1ad";
}
.game-icon-birch-trees:before {
  content: "\ff1ae";
}
.game-icon-bird-cage:before {
  content: "\ff1af";
}
.game-icon-bird-claw:before {
  content: "\ff1b0";
}
.game-icon-bird-house:before {
  content: "\ff1b1";
}
.game-icon-bird-limb:before {
  content: "\ff1b2";
}
.game-icon-bird-mask:before {
  content: "\ff1b3";
}
.game-icon-bird-twitter:before {
  content: "\ff1b4";
}
.game-icon-bison:before {
  content: "\ff1b5";
}
.game-icon-black-bar:before {
  content: "\ff1b6";
}
.game-icon-black-belt:before {
  content: "\ff1b7";
}
.game-icon-black-book:before {
  content: "\ff1b8";
}
.game-icon-black-bridge:before {
  content: "\ff1b9";
}
.game-icon-black-cat:before {
  content: "\ff1ba";
}
.game-icon-black-flag:before {
  content: "\ff1bb";
}
.game-icon-black-hand-shield:before {
  content: "\ff1bc";
}
.game-icon-black-hole-bolas:before {
  content: "\ff1bd";
}
.game-icon-black-knight-helm:before {
  content: "\ff1be";
}
.game-icon-black-sea:before {
  content: "\ff1bf";
}
.game-icon-blackball:before {
  content: "\ff1c0";
}
.game-icon-blackcurrant:before {
  content: "\ff1c1";
}
.game-icon-blacksmith:before {
  content: "\ff1c2";
}
.game-icon-blade-bite:before {
  content: "\ff1c3";
}
.game-icon-blade-drag:before {
  content: "\ff1c4";
}
.game-icon-blade-fall:before {
  content: "\ff1c5";
}
.game-icon-blast:before {
  content: "\ff1c6";
}
.game-icon-blaster:before {
  content: "\ff1c7";
}
.game-icon-bleeding-eye:before {
  content: "\ff1c8";
}
.game-icon-bleeding-heart:before {
  content: "\ff1c9";
}
.game-icon-bleeding-wound:before {
  content: "\ff1ca";
}
.game-icon-blender:before {
  content: "\ff1cb";
}
.game-icon-blindfold:before {
  content: "\ff1cc";
}
.game-icon-block-house:before {
  content: "\ff1cd";
}
.game-icon-blood:before {
  content: "\ff1ce";
}
.game-icon-bloody-stash:before {
  content: "\ff1cf";
}
.game-icon-bloody-sword:before {
  content: "\ff1d0";
}
.game-icon-blunderbuss:before {
  content: "\ff1d1";
}
.game-icon-bo:before {
  content: "\ff1d2";
}
.game-icon-boar-ensign:before {
  content: "\ff1d3";
}
.game-icon-boar-tusks:before {
  content: "\ff1d4";
}
.game-icon-boar:before {
  content: "\ff1d5";
}
.game-icon-boat-engine:before {
  content: "\ff1d6";
}
.game-icon-boat-fishing:before {
  content: "\ff1d7";
}
.game-icon-boat-horizon:before {
  content: "\ff1d8";
}
.game-icon-boat-propeller:before {
  content: "\ff1d9";
}
.game-icon-boba:before {
  content: "\ff1da";
}
.game-icon-body-balance:before {
  content: "\ff1db";
}
.game-icon-body-height:before {
  content: "\ff1dc";
}
.game-icon-body-swapping:before {
  content: "\ff1dd";
}
.game-icon-boiling-bubbles:before {
  content: "\ff1de";
}
.game-icon-bok-choy:before {
  content: "\ff1df";
}
.game-icon-bolas:before {
  content: "\ff1e0";
}
.game-icon-bolivia:before {
  content: "\ff1e1";
}
.game-icon-bolt-bomb:before {
  content: "\ff1e2";
}
.game-icon-bolt-cutter:before {
  content: "\ff1e3";
}
.game-icon-bolt-drop:before {
  content: "\ff1e4";
}
.game-icon-bolt-eye:before {
  content: "\ff1e5";
}
.game-icon-bolt-saw:before {
  content: "\ff1e6";
}
.game-icon-bolt-shield:before {
  content: "\ff1e7";
}
.game-icon-bolt-spell-cast:before {
  content: "\ff1e8";
}
.game-icon-bolter-gun:before {
  content: "\ff1e9";
}
.game-icon-bomber:before {
  content: "\ff1ea";
}
.game-icon-bombing-run:before {
  content: "\ff1eb";
}
.game-icon-bone-gnawer:before {
  content: "\ff1ec";
}
.game-icon-bone-knife-2:before {
  content: "\ff1ed";
}
.game-icon-bone-knife:before {
  content: "\ff1ee";
}
.game-icon-bone-mace:before {
  content: "\ff1ef";
}
.game-icon-bonsai-tree:before {
  content: "\ff1f0";
}
.game-icon-book-aura:before {
  content: "\ff1f1";
}
.game-icon-book-cover-2:before {
  content: "\ff1f2";
}
.game-icon-book-cover:before {
  content: "\ff1f3";
}
.game-icon-book-pile:before {
  content: "\ff1f4";
}
.game-icon-book-storm:before {
  content: "\ff1f5";
}
.game-icon-bookmark:before {
  content: "\ff1f6";
}
.game-icon-bookmarklet:before {
  content: "\ff1f7";
}
.game-icon-bookshelf:before {
  content: "\ff1f8";
}
.game-icon-boombox:before {
  content: "\ff1f9";
}
.game-icon-boomerang-cross:before {
  content: "\ff1fa";
}
.game-icon-boomerang-sun:before {
  content: "\ff1fb";
}
.game-icon-boomerang:before {
  content: "\ff1fc";
}
.game-icon-boot-kick:before {
  content: "\ff1fd";
}
.game-icon-boot-prints:before {
  content: "\ff1fe";
}
.game-icon-boot-stomp:before {
  content: "\ff1ff";
}
.game-icon-boots:before {
  content: "\ff200";
}
.game-icon-booze:before {
  content: "\ff201";
}
.game-icon-bordered-shield:before {
  content: "\ff202";
}
.game-icon-boss-key:before {
  content: "\ff203";
}
.game-icon-bottle-cap:before {
  content: "\ff204";
}
.game-icon-bottle-vapors:before {
  content: "\ff205";
}
.game-icon-bottled-bolt:before {
  content: "\ff206";
}
.game-icon-bottled-shadow:before {
  content: "\ff207";
}
.game-icon-bottom-right-3d-arrow:before {
  content: "\ff208";
}
.game-icon-boulder-dash:before {
  content: "\ff209";
}
.game-icon-bouncing-spring:before {
  content: "\ff20a";
}
.game-icon-bouncing-sword:before {
  content: "\ff20b";
}
.game-icon-bow-arrow:before {
  content: "\ff20c";
}
.game-icon-bow-string:before {
  content: "\ff20d";
}
.game-icon-bow-tie-ribbon:before {
  content: "\ff20e";
}
.game-icon-bow-tie:before {
  content: "\ff20f";
}
.game-icon-bowels:before {
  content: "\ff210";
}
.game-icon-bowen-knot:before {
  content: "\ff211";
}
.game-icon-bowie-knife-2:before {
  content: "\ff212";
}
.game-icon-bowie-knife:before {
  content: "\ff213";
}
.game-icon-bowl-of-rice:before {
  content: "\ff214";
}
.game-icon-bowl-spiral:before {
  content: "\ff215";
}
.game-icon-bowling-alley:before {
  content: "\ff216";
}
.game-icon-bowling-pin:before {
  content: "\ff217";
}
.game-icon-bowling-propulsion:before {
  content: "\ff218";
}
.game-icon-bowling-strike:before {
  content: "\ff219";
}
.game-icon-bowman:before {
  content: "\ff21a";
}
.game-icon-box-cutter:before {
  content: "\ff21b";
}
.game-icon-box-trap:before {
  content: "\ff21c";
}
.game-icon-box-unpacking:before {
  content: "\ff21d";
}
.game-icon-boxing-glove-surprise:before {
  content: "\ff21e";
}
.game-icon-boxing-glove:before {
  content: "\ff21f";
}
.game-icon-boxing-ring:before {
  content: "\ff220";
}
.game-icon-bracer:before {
  content: "\ff221";
}
.game-icon-bracers:before {
  content: "\ff222";
}
.game-icon-brain-dump:before {
  content: "\ff223";
}
.game-icon-brain-freeze:before {
  content: "\ff224";
}
.game-icon-brain-leak:before {
  content: "\ff225";
}
.game-icon-brain-stem:before {
  content: "\ff226";
}
.game-icon-brain-tentacle:before {
  content: "\ff227";
}
.game-icon-brain:before {
  content: "\ff228";
}
.game-icon-brainstorm:before {
  content: "\ff229";
}
.game-icon-branch-arrow:before {
  content: "\ff22a";
}
.game-icon-brandy-bottle:before {
  content: "\ff22b";
}
.game-icon-brasero:before {
  content: "\ff22c";
}
.game-icon-brass-eye:before {
  content: "\ff22d";
}
.game-icon-brass-knuckles:before {
  content: "\ff22e";
}
.game-icon-brazil-flag:before {
  content: "\ff22f";
}
.game-icon-brazil:before {
  content: "\ff230";
}
.game-icon-bread-slice:before {
  content: "\ff231";
}
.game-icon-bread:before {
  content: "\ff232";
}
.game-icon-breaking-chain:before {
  content: "\ff233";
}
.game-icon-breastplate:before {
  content: "\ff234";
}
.game-icon-brick-pile:before {
  content: "\ff235";
}
.game-icon-brick-wall:before {
  content: "\ff236";
}
.game-icon-bridge:before {
  content: "\ff237";
}
.game-icon-briefcase:before {
  content: "\ff238";
}
.game-icon-bright-explosion:before {
  content: "\ff239";
}
.game-icon-broad-dagger:before {
  content: "\ff23a";
}
.game-icon-broadhead-arrow:before {
  content: "\ff23b";
}
.game-icon-broadsword:before {
  content: "\ff23c";
}
.game-icon-broccoli:before {
  content: "\ff23d";
}
.game-icon-brodie-helmet:before {
  content: "\ff23e";
}
.game-icon-broken-arrow:before {
  content: "\ff23f";
}
.game-icon-broken-axe:before {
  content: "\ff240";
}
.game-icon-broken-bone:before {
  content: "\ff241";
}
.game-icon-broken-bottle:before {
  content: "\ff242";
}
.game-icon-broken-heart-zone:before {
  content: "\ff243";
}
.game-icon-broken-heart:before {
  content: "\ff244";
}
.game-icon-broken-pottery:before {
  content: "\ff245";
}
.game-icon-broken-ribbon:before {
  content: "\ff246";
}
.game-icon-broken-shield:before {
  content: "\ff247";
}
.game-icon-broken-skull:before {
  content: "\ff248";
}
.game-icon-broken-tablet:before {
  content: "\ff249";
}
.game-icon-broken-wall:before {
  content: "\ff24a";
}
.game-icon-broom:before {
  content: "\ff24b";
}
.game-icon-brutal-helm:before {
  content: "\ff24c";
}
.game-icon-brute:before {
  content: "\ff24d";
}
.game-icon-bubble-field:before {
  content: "\ff24e";
}
.game-icon-bubbles:before {
  content: "\ff24f";
}
.game-icon-bubbling-beam:before {
  content: "\ff250";
}
.game-icon-bubbling-bowl:before {
  content: "\ff251";
}
.game-icon-bubbling-flask:before {
  content: "\ff252";
}
.game-icon-bud:before {
  content: "\ff253";
}
.game-icon-buffalo-head:before {
  content: "\ff254";
}
.game-icon-bug-net:before {
  content: "\ff255";
}
.game-icon-bugle-call:before {
  content: "\ff256";
}
.game-icon-bulb:before {
  content: "\ff257";
}
.game-icon-bulgaria:before {
  content: "\ff258";
}
.game-icon-bull-horns:before {
  content: "\ff259";
}
.game-icon-bull:before {
  content: "\ff25a";
}
.game-icon-bulldozer:before {
  content: "\ff25b";
}
.game-icon-bullet-bill:before {
  content: "\ff25c";
}
.game-icon-bullet-impacts:before {
  content: "\ff25d";
}
.game-icon-bullets:before {
  content: "\ff25e";
}
.game-icon-bullseye:before {
  content: "\ff25f";
}
.game-icon-bully-minion:before {
  content: "\ff260";
}
.game-icon-bundle-grenade:before {
  content: "\ff261";
}
.game-icon-bunk-beds:before {
  content: "\ff262";
}
.game-icon-bunker-assault:before {
  content: "\ff263";
}
.game-icon-bunker:before {
  content: "\ff264";
}
.game-icon-bunny-slippers:before {
  content: "\ff265";
}
.game-icon-buoy:before {
  content: "\ff266";
}
.game-icon-burn:before {
  content: "\ff267";
}
.game-icon-burning-blobs:before {
  content: "\ff268";
}
.game-icon-burning-book:before {
  content: "\ff269";
}
.game-icon-burning-dot:before {
  content: "\ff26a";
}
.game-icon-burning-embers:before {
  content: "\ff26b";
}
.game-icon-burning-eye:before {
  content: "\ff26c";
}
.game-icon-burning-forest:before {
  content: "\ff26d";
}
.game-icon-burning-meteor:before {
  content: "\ff26e";
}
.game-icon-burning-passion:before {
  content: "\ff26f";
}
.game-icon-burning-round-shot:before {
  content: "\ff270";
}
.game-icon-burning-skull:before {
  content: "\ff271";
}
.game-icon-burning-tree:before {
  content: "\ff272";
}
.game-icon-burst-blob:before {
  content: "\ff273";
}
.game-icon-bus-doors:before {
  content: "\ff274";
}
.game-icon-bus-stop:before {
  content: "\ff275";
}
.game-icon-bus:before {
  content: "\ff276";
}
.game-icon-butter-toast:before {
  content: "\ff277";
}
.game-icon-butter:before {
  content: "\ff278";
}
.game-icon-butterfly-flower:before {
  content: "\ff279";
}
.game-icon-butterfly-knife-2:before {
  content: "\ff27a";
}
.game-icon-butterfly-knife:before {
  content: "\ff27b";
}
.game-icon-butterfly-warning:before {
  content: "\ff27c";
}
.game-icon-butterfly:before {
  content: "\ff27d";
}
.game-icon-button-finger:before {
  content: "\ff27e";
}
.game-icon-buy-card:before {
  content: "\ff27f";
}
.game-icon-byzantin-temple:before {
  content: "\ff280";
}
.game-icon-c96:before {
  content: "\ff281";
}
.game-icon-cabbage:before {
  content: "\ff282";
}
.game-icon-cable-stayed-bridge:before {
  content: "\ff283";
}
.game-icon-cactus-pot:before {
  content: "\ff284";
}
.game-icon-cactus-tap:before {
  content: "\ff285";
}
.game-icon-cactus:before {
  content: "\ff286";
}
.game-icon-cadillac-helm:before {
  content: "\ff287";
}
.game-icon-caduceus:before {
  content: "\ff288";
}
.game-icon-caesar:before {
  content: "\ff289";
}
.game-icon-cage:before {
  content: "\ff28a";
}
.game-icon-caged-ball:before {
  content: "\ff28b";
}
.game-icon-cake-slice:before {
  content: "\ff28c";
}
.game-icon-calavera:before {
  content: "\ff28d";
}
.game-icon-calculator:before {
  content: "\ff28e";
}
.game-icon-caldera:before {
  content: "\ff28f";
}
.game-icon-calendar-half-year:before {
  content: "\ff290";
}
.game-icon-calendar:before {
  content: "\ff291";
}
.game-icon-caltrops:before {
  content: "\ff292";
}
.game-icon-camargue-cross:before {
  content: "\ff293";
}
.game-icon-cambodia:before {
  content: "\ff294";
}
.game-icon-camel-head:before {
  content: "\ff295";
}
.game-icon-camel:before {
  content: "\ff296";
}
.game-icon-camp-cooking-pot:before {
  content: "\ff297";
}
.game-icon-campfire:before {
  content: "\ff298";
}
.game-icon-camping-tent:before {
  content: "\ff299";
}
.game-icon-cancel:before {
  content: "\ff29a";
}
.game-icon-cancer:before {
  content: "\ff29b";
}
.game-icon-candle-flame:before {
  content: "\ff29c";
}
.game-icon-candle-holder:before {
  content: "\ff29d";
}
.game-icon-candle-light:before {
  content: "\ff29e";
}
.game-icon-candle-skull:before {
  content: "\ff29f";
}
.game-icon-candlebright:before {
  content: "\ff2a0";
}
.game-icon-candles:before {
  content: "\ff2a1";
}
.game-icon-candlestick-phone:before {
  content: "\ff2a2";
}
.game-icon-candy-canes:before {
  content: "\ff2a3";
}
.game-icon-canned-fish:before {
  content: "\ff2a4";
}
.game-icon-cannister:before {
  content: "\ff2a5";
}
.game-icon-cannon-ball:before {
  content: "\ff2a6";
}
.game-icon-cannon-shot:before {
  content: "\ff2a7";
}
.game-icon-cannon:before {
  content: "\ff2a8";
}
.game-icon-canoe:before {
  content: "\ff2a9";
}
.game-icon-cantua:before {
  content: "\ff2aa";
}
.game-icon-cape-armor:before {
  content: "\ff2ab";
}
.game-icon-cape:before {
  content: "\ff2ac";
}
.game-icon-capitol:before {
  content: "\ff2ad";
}
.game-icon-capricorn:before {
  content: "\ff2ae";
}
.game-icon-captain-hat-profile:before {
  content: "\ff2af";
}
.game-icon-capybara:before {
  content: "\ff2b0";
}
.game-icon-car-battery:before {
  content: "\ff2b1";
}
.game-icon-car-door:before {
  content: "\ff2b2";
}
.game-icon-car-key:before {
  content: "\ff2b3";
}
.game-icon-car-seat:before {
  content: "\ff2b4";
}
.game-icon-car-wheel:before {
  content: "\ff2b5";
}
.game-icon-carabiner:before {
  content: "\ff2b6";
}
.game-icon-carambola:before {
  content: "\ff2b7";
}
.game-icon-caravan:before {
  content: "\ff2b8";
}
.game-icon-caravel:before {
  content: "\ff2b9";
}
.game-icon-card-10-clubs:before {
  content: "\ff2ba";
}
.game-icon-card-10-diamonds:before {
  content: "\ff2bb";
}
.game-icon-card-10-hearts:before {
  content: "\ff2bc";
}
.game-icon-card-10-spades:before {
  content: "\ff2bd";
}
.game-icon-card-2-clubs:before {
  content: "\ff2be";
}
.game-icon-card-2-diamonds:before {
  content: "\ff2bf";
}
.game-icon-card-2-hearts:before {
  content: "\ff2c0";
}
.game-icon-card-2-spades:before {
  content: "\ff2c1";
}
.game-icon-card-3-clubs:before {
  content: "\ff2c2";
}
.game-icon-card-3-diamonds:before {
  content: "\ff2c3";
}
.game-icon-card-3-hearts:before {
  content: "\ff2c4";
}
.game-icon-card-3-spades:before {
  content: "\ff2c5";
}
.game-icon-card-4-clubs:before {
  content: "\ff2c6";
}
.game-icon-card-4-diamonds:before {
  content: "\ff2c7";
}
.game-icon-card-4-hearts:before {
  content: "\ff2c8";
}
.game-icon-card-4-spades:before {
  content: "\ff2c9";
}
.game-icon-card-5-clubs:before {
  content: "\ff2ca";
}
.game-icon-card-5-diamonds:before {
  content: "\ff2cb";
}
.game-icon-card-5-hearts:before {
  content: "\ff2cc";
}
.game-icon-card-5-spades:before {
  content: "\ff2cd";
}
.game-icon-card-6-clubs:before {
  content: "\ff2ce";
}
.game-icon-card-6-diamonds:before {
  content: "\ff2cf";
}
.game-icon-card-6-hearts:before {
  content: "\ff2d0";
}
.game-icon-card-6-spades:before {
  content: "\ff2d1";
}
.game-icon-card-7-clubs:before {
  content: "\ff2d2";
}
.game-icon-card-7-diamonds:before {
  content: "\ff2d3";
}
.game-icon-card-7-hearts:before {
  content: "\ff2d4";
}
.game-icon-card-7-spades:before {
  content: "\ff2d5";
}
.game-icon-card-8-clubs:before {
  content: "\ff2d6";
}
.game-icon-card-8-diamonds:before {
  content: "\ff2d7";
}
.game-icon-card-8-hearts:before {
  content: "\ff2d8";
}
.game-icon-card-8-spades:before {
  content: "\ff2d9";
}
.game-icon-card-9-clubs:before {
  content: "\ff2da";
}
.game-icon-card-9-diamonds:before {
  content: "\ff2db";
}
.game-icon-card-9-hearts:before {
  content: "\ff2dc";
}
.game-icon-card-9-spades:before {
  content: "\ff2dd";
}
.game-icon-card-ace-clubs:before {
  content: "\ff2de";
}
.game-icon-card-ace-diamonds:before {
  content: "\ff2df";
}
.game-icon-card-ace-hearts:before {
  content: "\ff2e0";
}
.game-icon-card-ace-spades:before {
  content: "\ff2e1";
}
.game-icon-card-burn:before {
  content: "\ff2e2";
}
.game-icon-card-discard:before {
  content: "\ff2e3";
}
.game-icon-card-draw:before {
  content: "\ff2e4";
}
.game-icon-card-exchange:before {
  content: "\ff2e5";
}
.game-icon-card-jack-clubs:before {
  content: "\ff2e6";
}
.game-icon-card-jack-diamonds:before {
  content: "\ff2e7";
}
.game-icon-card-jack-hearts:before {
  content: "\ff2e8";
}
.game-icon-card-jack-spades:before {
  content: "\ff2e9";
}
.game-icon-card-joker:before {
  content: "\ff2ea";
}
.game-icon-card-king-clubs:before {
  content: "\ff2eb";
}
.game-icon-card-king-diamonds:before {
  content: "\ff2ec";
}
.game-icon-card-king-hearts:before {
  content: "\ff2ed";
}
.game-icon-card-king-spades:before {
  content: "\ff2ee";
}
.game-icon-card-pick:before {
  content: "\ff2ef";
}
.game-icon-card-pickup:before {
  content: "\ff2f0";
}
.game-icon-card-play:before {
  content: "\ff2f1";
}
.game-icon-card-queen-clubs:before {
  content: "\ff2f2";
}
.game-icon-card-queen-diamonds:before {
  content: "\ff2f3";
}
.game-icon-card-queen-hearts:before {
  content: "\ff2f4";
}
.game-icon-card-queen-spades:before {
  content: "\ff2f5";
}
.game-icon-card-random:before {
  content: "\ff2f6";
}
.game-icon-cardboard-box-closed:before {
  content: "\ff2f7";
}
.game-icon-cardboard-box:before {
  content: "\ff2f8";
}
.game-icon-cargo-crane:before {
  content: "\ff2f9";
}
.game-icon-cargo-crate:before {
  content: "\ff2fa";
}
.game-icon-cargo-ship:before {
  content: "\ff2fb";
}
.game-icon-carillon:before {
  content: "\ff2fc";
}
.game-icon-carnival-mask:before {
  content: "\ff2fd";
}
.game-icon-carnivore-mouth:before {
  content: "\ff2fe";
}
.game-icon-carnivorous-plant:before {
  content: "\ff2ff";
}
.game-icon-carnyx:before {
  content: "\ff300";
}
.game-icon-carousel:before {
  content: "\ff301";
}
.game-icon-carpet-bombing:before {
  content: "\ff302";
}
.game-icon-carrier:before {
  content: "\ff303";
}
.game-icon-carrion:before {
  content: "\ff304";
}
.game-icon-carrot:before {
  content: "\ff305";
}
.game-icon-cartwheel:before {
  content: "\ff306";
}
.game-icon-cash:before {
  content: "\ff307";
}
.game-icon-cassowary-head:before {
  content: "\ff308";
}
.game-icon-castle-2:before {
  content: "\ff309";
}
.game-icon-castle-ruins:before {
  content: "\ff30a";
}
.game-icon-castle:before {
  content: "\ff30b";
}
.game-icon-cat:before {
  content: "\ff30c";
}
.game-icon-catapult:before {
  content: "\ff30d";
}
.game-icon-catch:before {
  content: "\ff30e";
}
.game-icon-caterpillar:before {
  content: "\ff30f";
}
.game-icon-cauldron-2:before {
  content: "\ff310";
}
.game-icon-cauldron:before {
  content: "\ff311";
}
.game-icon-cavalry:before {
  content: "\ff312";
}
.game-icon-cave-entrance:before {
  content: "\ff313";
}
.game-icon-caveman:before {
  content: "\ff314";
}
.game-icon-cctv-camera:before {
  content: "\ff315";
}
.game-icon-ceiling-barnacle:before {
  content: "\ff316";
}
.game-icon-ceiling-light:before {
  content: "\ff317";
}
.game-icon-celebration-fire:before {
  content: "\ff318";
}
.game-icon-cellar-barrels:before {
  content: "\ff319";
}
.game-icon-cement-shoes:before {
  content: "\ff31a";
}
.game-icon-centaur-heart:before {
  content: "\ff31b";
}
.game-icon-centaur:before {
  content: "\ff31c";
}
.game-icon-centipede:before {
  content: "\ff31d";
}
.game-icon-centurion-helmet:before {
  content: "\ff31e";
}
.game-icon-ceremonial-mask:before {
  content: "\ff31f";
}
.game-icon-chain-lightning:before {
  content: "\ff320";
}
.game-icon-chain-mail:before {
  content: "\ff321";
}
.game-icon-chained-arrow-heads:before {
  content: "\ff322";
}
.game-icon-chained-heart:before {
  content: "\ff323";
}
.game-icon-chaingun:before {
  content: "\ff324";
}
.game-icon-chainsaw:before {
  content: "\ff325";
}
.game-icon-chakram:before {
  content: "\ff326";
}
.game-icon-chalice-drops:before {
  content: "\ff327";
}
.game-icon-chalk-outline-murder:before {
  content: "\ff328";
}
.game-icon-chameleon-glyph:before {
  content: "\ff329";
}
.game-icon-champagne-cork:before {
  content: "\ff32a";
}
.game-icon-champions:before {
  content: "\ff32b";
}
.game-icon-chanterelles:before {
  content: "\ff32c";
}
.game-icon-character:before {
  content: "\ff32d";
}
.game-icon-charcuterie:before {
  content: "\ff32e";
}
.game-icon-charged-arrow:before {
  content: "\ff32f";
}
.game-icon-charging-bull:before {
  content: "\ff330";
}
.game-icon-charging:before {
  content: "\ff331";
}
.game-icon-chariot:before {
  content: "\ff332";
}
.game-icon-charm:before {
  content: "\ff333";
}
.game-icon-chart:before {
  content: "\ff334";
}
.game-icon-chat-bubble:before {
  content: "\ff335";
}
.game-icon-check-mark:before {
  content: "\ff336";
}
.game-icon-checkbox-tree:before {
  content: "\ff337";
}
.game-icon-checked-shield:before {
  content: "\ff338";
}
.game-icon-checkered-diamond:before {
  content: "\ff339";
}
.game-icon-checkered-flag:before {
  content: "\ff33a";
}
.game-icon-checklist:before {
  content: "\ff33b";
}
.game-icon-cheerful:before {
  content: "\ff33c";
}
.game-icon-cheese-wedge:before {
  content: "\ff33d";
}
.game-icon-chef-toque:before {
  content: "\ff33e";
}
.game-icon-chelsea-boot:before {
  content: "\ff33f";
}
.game-icon-chemical-arrow:before {
  content: "\ff340";
}
.game-icon-chemical-bolt:before {
  content: "\ff341";
}
.game-icon-chemical-drop:before {
  content: "\ff342";
}
.game-icon-chemical-tank:before {
  content: "\ff343";
}
.game-icon-cherish:before {
  content: "\ff344";
}
.game-icon-cherry:before {
  content: "\ff345";
}
.game-icon-chess-bishop:before {
  content: "\ff346";
}
.game-icon-chess-king:before {
  content: "\ff347";
}
.game-icon-chess-knight:before {
  content: "\ff348";
}
.game-icon-chess-pawn:before {
  content: "\ff349";
}
.game-icon-chess-queen:before {
  content: "\ff34a";
}
.game-icon-chess-rook:before {
  content: "\ff34b";
}
.game-icon-chest-armor:before {
  content: "\ff34c";
}
.game-icon-chest:before {
  content: "\ff34d";
}
.game-icon-chestnut-leaf:before {
  content: "\ff34e";
}
.game-icon-chewed-heart:before {
  content: "\ff34f";
}
.game-icon-chewed-skull:before {
  content: "\ff350";
}
.game-icon-chicken-leg:before {
  content: "\ff351";
}
.game-icon-chicken-oven:before {
  content: "\ff352";
}
.game-icon-chicken:before {
  content: "\ff353";
}
.game-icon-chili-pepper:before {
  content: "\ff354";
}
.game-icon-chimney:before {
  content: "\ff355";
}
.game-icon-chips-bag:before {
  content: "\ff356";
}
.game-icon-chisel:before {
  content: "\ff357";
}
.game-icon-chocolate-bar:before {
  content: "\ff358";
}
.game-icon-choice:before {
  content: "\ff359";
}
.game-icon-chopped-skull:before {
  content: "\ff35a";
}
.game-icon-chopsticks:before {
  content: "\ff35b";
}
.game-icon-church:before {
  content: "\ff35c";
}
.game-icon-cigale:before {
  content: "\ff35d";
}
.game-icon-cigar:before {
  content: "\ff35e";
}
.game-icon-cigarette:before {
  content: "\ff35f";
}
.game-icon-circle-cage:before {
  content: "\ff360";
}
.game-icon-circle-claws:before {
  content: "\ff361";
}
.game-icon-circle-forest:before {
  content: "\ff362";
}
.game-icon-circle-sparks:before {
  content: "\ff363";
}
.game-icon-circle:before {
  content: "\ff364";
}
.game-icon-circling-fish:before {
  content: "\ff365";
}
.game-icon-circuitry:before {
  content: "\ff366";
}
.game-icon-circular-saw:before {
  content: "\ff367";
}
.game-icon-circular-sawblade:before {
  content: "\ff368";
}
.game-icon-city-car:before {
  content: "\ff369";
}
.game-icon-clamp:before {
  content: "\ff36a";
}
.game-icon-clapperboard:before {
  content: "\ff36b";
}
.game-icon-clarinet:before {
  content: "\ff36c";
}
.game-icon-classical-knowledge:before {
  content: "\ff36d";
}
.game-icon-claw-hammer:before {
  content: "\ff36e";
}
.game-icon-claw-slashes:before {
  content: "\ff36f";
}
.game-icon-claw-string:before {
  content: "\ff370";
}
.game-icon-claw:before {
  content: "\ff371";
}
.game-icon-claws:before {
  content: "\ff372";
}
.game-icon-clay-brick:before {
  content: "\ff373";
}
.game-icon-claymore-explosive:before {
  content: "\ff374";
}
.game-icon-cleaver:before {
  content: "\ff375";
}
.game-icon-cleopatra:before {
  content: "\ff376";
}
.game-icon-click:before {
  content: "\ff377";
}
.game-icon-cliff-crossing:before {
  content: "\ff378";
}
.game-icon-cloak-dagger:before {
  content: "\ff379";
}
.game-icon-cloak:before {
  content: "\ff37a";
}
.game-icon-cloaked-figure-on-horseback:before {
  content: "\ff37b";
}
.game-icon-clockwise-rotation:before {
  content: "\ff37c";
}
.game-icon-clockwork:before {
  content: "\ff37d";
}
.game-icon-closed-barbute:before {
  content: "\ff37e";
}
.game-icon-closed-doors:before {
  content: "\ff37f";
}
.game-icon-cloth-jar:before {
  content: "\ff380";
}
.game-icon-clothes:before {
  content: "\ff381";
}
.game-icon-clothesline:before {
  content: "\ff382";
}
.game-icon-clothespin:before {
  content: "\ff383";
}
.game-icon-cloud-download:before {
  content: "\ff384";
}
.game-icon-cloud-ring:before {
  content: "\ff385";
}
.game-icon-cloud-upload:before {
  content: "\ff386";
}
.game-icon-cloudy-fork:before {
  content: "\ff387";
}
.game-icon-clout:before {
  content: "\ff388";
}
.game-icon-clover-2:before {
  content: "\ff389";
}
.game-icon-clover-spiked:before {
  content: "\ff38a";
}
.game-icon-clover:before {
  content: "\ff38b";
}
.game-icon-clown:before {
  content: "\ff38c";
}
.game-icon-clownfish:before {
  content: "\ff38d";
}
.game-icon-clubs:before {
  content: "\ff38e";
}
.game-icon-cluster-bomb:before {
  content: "\ff38f";
}
.game-icon-coa-de-jima:before {
  content: "\ff390";
}
.game-icon-coal-pile:before {
  content: "\ff391";
}
.game-icon-coal-wagon:before {
  content: "\ff392";
}
.game-icon-cobra-2:before {
  content: "\ff393";
}
.game-icon-cobra:before {
  content: "\ff394";
}
.game-icon-cobweb:before {
  content: "\ff395";
}
.game-icon-coconuts:before {
  content: "\ff396";
}
.game-icon-coffee-beans:before {
  content: "\ff397";
}
.game-icon-coffee-cup:before {
  content: "\ff398";
}
.game-icon-coffee-mug:before {
  content: "\ff399";
}
.game-icon-coffee-pot:before {
  content: "\ff39a";
}
.game-icon-coffin:before {
  content: "\ff39b";
}
.game-icon-cog-lock:before {
  content: "\ff39c";
}
.game-icon-cog:before {
  content: "\ff39d";
}
.game-icon-cogsplosion:before {
  content: "\ff39e";
}
.game-icon-coiled-nail:before {
  content: "\ff39f";
}
.game-icon-coiling-curl:before {
  content: "\ff3a0";
}
.game-icon-coinflip:before {
  content: "\ff3a1";
}
.game-icon-coins-pile:before {
  content: "\ff3a2";
}
.game-icon-coins:before {
  content: "\ff3a3";
}
.game-icon-cold-heart:before {
  content: "\ff3a4";
}
.game-icon-coliseum:before {
  content: "\ff3a5";
}
.game-icon-colombia:before {
  content: "\ff3a6";
}
.game-icon-colombian-statue:before {
  content: "\ff3a7";
}
.game-icon-colt-m1911:before {
  content: "\ff3a8";
}
.game-icon-column-vase:before {
  content: "\ff3a9";
}
.game-icon-coma:before {
  content: "\ff3aa";
}
.game-icon-comb:before {
  content: "\ff3ab";
}
.game-icon-combination-lock:before {
  content: "\ff3ac";
}
.game-icon-comet-spark:before {
  content: "\ff3ad";
}
.game-icon-commercial-airplane:before {
  content: "\ff3ae";
}
.game-icon-compact-disc:before {
  content: "\ff3af";
}
.game-icon-companion-cube:before {
  content: "\ff3b0";
}
.game-icon-compass:before {
  content: "\ff3b1";
}
.game-icon-computer-fan:before {
  content: "\ff3b2";
}
.game-icon-computing:before {
  content: "\ff3b3";
}
.game-icon-concentration-orb:before {
  content: "\ff3b4";
}
.game-icon-concentric-crescents:before {
  content: "\ff3b5";
}
.game-icon-concrete-bag:before {
  content: "\ff3b6";
}
.game-icon-condor-emblem:before {
  content: "\ff3b7";
}
.game-icon-condylura-skull:before {
  content: "\ff3b8";
}
.game-icon-confirmed:before {
  content: "\ff3b9";
}
.game-icon-confrontation:before {
  content: "\ff3ba";
}
.game-icon-congress:before {
  content: "\ff3bb";
}
.game-icon-conqueror:before {
  content: "\ff3bc";
}
.game-icon-console-controller:before {
  content: "\ff3bd";
}
.game-icon-contortionist:before {
  content: "\ff3be";
}
.game-icon-contract:before {
  content: "\ff3bf";
}
.game-icon-control-tower:before {
  content: "\ff3c0";
}
.game-icon-convergence-target:before {
  content: "\ff3c1";
}
.game-icon-conversation:before {
  content: "\ff3c2";
}
.game-icon-converse-shoe:before {
  content: "\ff3c3";
}
.game-icon-convict:before {
  content: "\ff3c4";
}
.game-icon-convince:before {
  content: "\ff3c5";
}
.game-icon-conway-life-glider:before {
  content: "\ff3c6";
}
.game-icon-cook:before {
  content: "\ff3c7";
}
.game-icon-cookie:before {
  content: "\ff3c8";
}
.game-icon-cooking-glove:before {
  content: "\ff3c9";
}
.game-icon-cooking-pot:before {
  content: "\ff3ca";
}
.game-icon-cool-spices:before {
  content: "\ff3cb";
}
.game-icon-cooler:before {
  content: "\ff3cc";
}
.game-icon-cootie-catcher:before {
  content: "\ff3cd";
}
.game-icon-coral:before {
  content: "\ff3ce";
}
.game-icon-cork-hat:before {
  content: "\ff3cf";
}
.game-icon-corked-tube:before {
  content: "\ff3d0";
}
.game-icon-corkscrew:before {
  content: "\ff3d1";
}
.game-icon-corn:before {
  content: "\ff3d2";
}
.game-icon-corner-explosion:before {
  content: "\ff3d3";
}
.game-icon-corner-flag:before {
  content: "\ff3d4";
}
.game-icon-cornucopia:before {
  content: "\ff3d5";
}
.game-icon-coronation:before {
  content: "\ff3d6";
}
.game-icon-corporal:before {
  content: "\ff3d7";
}
.game-icon-corset:before {
  content: "\ff3d8";
}
.game-icon-corsica:before {
  content: "\ff3d9";
}
.game-icon-cosmic-egg:before {
  content: "\ff3da";
}
.game-icon-cotton-flower:before {
  content: "\ff3db";
}
.game-icon-covered-jar:before {
  content: "\ff3dc";
}
.game-icon-cow:before {
  content: "\ff3dd";
}
.game-icon-cowboy-boot:before {
  content: "\ff3de";
}
.game-icon-cowboy-holster:before {
  content: "\ff3df";
}
.game-icon-cowled:before {
  content: "\ff3e0";
}
.game-icon-cpu-shot:before {
  content: "\ff3e1";
}
.game-icon-cpu:before {
  content: "\ff3e2";
}
.game-icon-crab-claw:before {
  content: "\ff3e3";
}
.game-icon-crab:before {
  content: "\ff3e4";
}
.game-icon-cracked-alien-skull:before {
  content: "\ff3e5";
}
.game-icon-cracked-ball-dunk:before {
  content: "\ff3e6";
}
.game-icon-cracked-disc:before {
  content: "\ff3e7";
}
.game-icon-cracked-glass:before {
  content: "\ff3e8";
}
.game-icon-cracked-helm:before {
  content: "\ff3e9";
}
.game-icon-cracked-mask:before {
  content: "\ff3ea";
}
.game-icon-cracked-saber:before {
  content: "\ff3eb";
}
.game-icon-cracked-shield:before {
  content: "\ff3ec";
}
.game-icon-crafting:before {
  content: "\ff3ed";
}
.game-icon-crags:before {
  content: "\ff3ee";
}
.game-icon-crane:before {
  content: "\ff3ef";
}
.game-icon-credits-currency:before {
  content: "\ff3f0";
}
.game-icon-crenel-crown:before {
  content: "\ff3f1";
}
.game-icon-crenulated-shield:before {
  content: "\ff3f2";
}
.game-icon-crescent-blade-2:before {
  content: "\ff3f3";
}
.game-icon-crescent-blade:before {
  content: "\ff3f4";
}
.game-icon-crescent-staff:before {
  content: "\ff3f5";
}
.game-icon-crested-helmet:before {
  content: "\ff3f6";
}
.game-icon-cricket-bat:before {
  content: "\ff3f7";
}
.game-icon-cricket:before {
  content: "\ff3f8";
}
.game-icon-crime-scene-tape:before {
  content: "\ff3f9";
}
.game-icon-croc-jaws:before {
  content: "\ff3fa";
}
.game-icon-croc-sword:before {
  content: "\ff3fb";
}
.game-icon-croissant:before {
  content: "\ff3fc";
}
.game-icon-croissants-pupil:before {
  content: "\ff3fd";
}
.game-icon-crook-flail:before {
  content: "\ff3fe";
}
.game-icon-cross-flare:before {
  content: "\ff3ff";
}
.game-icon-cross-mark:before {
  content: "\ff400";
}
.game-icon-cross-shield:before {
  content: "\ff401";
}
.game-icon-crossbow:before {
  content: "\ff402";
}
.game-icon-crosscut-saw:before {
  content: "\ff403";
}
.game-icon-crossed-air-flows:before {
  content: "\ff404";
}
.game-icon-crossed-axes:before {
  content: "\ff405";
}
.game-icon-crossed-bones:before {
  content: "\ff406";
}
.game-icon-crossed-chains:before {
  content: "\ff407";
}
.game-icon-crossed-claws:before {
  content: "\ff408";
}
.game-icon-crossed-pistols:before {
  content: "\ff409";
}
.game-icon-crossed-sabres:before {
  content: "\ff40a";
}
.game-icon-crossed-slashes:before {
  content: "\ff40b";
}
.game-icon-crossed-swords:before {
  content: "\ff40c";
}
.game-icon-crosshair-arrow:before {
  content: "\ff40d";
}
.game-icon-crosshair:before {
  content: "\ff40e";
}
.game-icon-crossroad:before {
  content: "\ff40f";
}
.game-icon-crow-dive:before {
  content: "\ff410";
}
.game-icon-crow-nest:before {
  content: "\ff411";
}
.game-icon-crowbar:before {
  content: "\ff412";
}
.game-icon-crown-coin:before {
  content: "\ff413";
}
.game-icon-crown-of-thorns:before {
  content: "\ff414";
}
.game-icon-crown:before {
  content: "\ff415";
}
.game-icon-crowned-explosion:before {
  content: "\ff416";
}
.game-icon-crowned-heart:before {
  content: "\ff417";
}
.game-icon-crowned-skull:before {
  content: "\ff418";
}
.game-icon-crucifix:before {
  content: "\ff419";
}
.game-icon-cruiser:before {
  content: "\ff41a";
}
.game-icon-crumbling-ball:before {
  content: "\ff41b";
}
.game-icon-crush:before {
  content: "\ff41c";
}
.game-icon-cryo-chamber:before {
  content: "\ff41d";
}
.game-icon-crypt-entrance:before {
  content: "\ff41e";
}
.game-icon-crystal-ball:before {
  content: "\ff41f";
}
.game-icon-crystal-bars:before {
  content: "\ff420";
}
.game-icon-crystal-cluster:before {
  content: "\ff421";
}
.game-icon-crystal-earrings:before {
  content: "\ff422";
}
.game-icon-crystal-eye:before {
  content: "\ff423";
}
.game-icon-crystal-growth:before {
  content: "\ff424";
}
.game-icon-crystal-shine:before {
  content: "\ff425";
}
.game-icon-crystal-shrine:before {
  content: "\ff426";
}
.game-icon-crystal-wand:before {
  content: "\ff427";
}
.game-icon-crystalize:before {
  content: "\ff428";
}
.game-icon-cuauhtli:before {
  content: "\ff429";
}
.game-icon-cube:before {
  content: "\ff42a";
}
.game-icon-cubeforce:before {
  content: "\ff42b";
}
.game-icon-cubes:before {
  content: "\ff42c";
}
.game-icon-cuckoo-clock:before {
  content: "\ff42d";
}
.game-icon-cultist-2:before {
  content: "\ff42e";
}
.game-icon-cultist:before {
  content: "\ff42f";
}
.game-icon-cupcake:before {
  content: "\ff430";
}
.game-icon-cupidon-arrow:before {
  content: "\ff431";
}
.game-icon-curled-leaf:before {
  content: "\ff432";
}
.game-icon-curled-tentacle:before {
  content: "\ff433";
}
.game-icon-curling-stone:before {
  content: "\ff434";
}
.game-icon-curling-vines:before {
  content: "\ff435";
}
.game-icon-curly-mask:before {
  content: "\ff436";
}
.game-icon-curly-wing:before {
  content: "\ff437";
}
.game-icon-cursed-star:before {
  content: "\ff438";
}
.game-icon-curvy-knife:before {
  content: "\ff439";
}
.game-icon-custodian-helmet:before {
  content: "\ff43a";
}
.game-icon-cut-diamond:before {
  content: "\ff43b";
}
.game-icon-cut-lemon:before {
  content: "\ff43c";
}
.game-icon-cut-palm:before {
  content: "\ff43d";
}
.game-icon-cyber-eye:before {
  content: "\ff43e";
}
.game-icon-cyborg-face:before {
  content: "\ff43f";
}
.game-icon-cycle:before {
  content: "\ff440";
}
.game-icon-cycling:before {
  content: "\ff441";
}
.game-icon-cyclops:before {
  content: "\ff442";
}
.game-icon-cz-skorpion:before {
  content: "\ff443";
}
.game-icon-d10:before {
  content: "\ff444";
}
.game-icon-d12:before {
  content: "\ff445";
}
.game-icon-d4:before {
  content: "\ff446";
}
.game-icon-daemon-pull:before {
  content: "\ff447";
}
.game-icon-daemon-skull:before {
  content: "\ff448";
}
.game-icon-dagger-rose:before {
  content: "\ff449";
}
.game-icon-daggers:before {
  content: "\ff44a";
}
.game-icon-daisy:before {
  content: "\ff44b";
}
.game-icon-dam:before {
  content: "\ff44c";
}
.game-icon-damaged-house:before {
  content: "\ff44d";
}
.game-icon-dandelion-flower:before {
  content: "\ff44e";
}
.game-icon-dango:before {
  content: "\ff44f";
}
.game-icon-dark-squad:before {
  content: "\ff450";
}
.game-icon-dart:before {
  content: "\ff451";
}
.game-icon-database:before {
  content: "\ff452";
}
.game-icon-dead-eye:before {
  content: "\ff453";
}
.game-icon-dead-head:before {
  content: "\ff454";
}
.game-icon-dead-wood:before {
  content: "\ff455";
}
.game-icon-deadly-strike:before {
  content: "\ff456";
}
.game-icon-death-juice:before {
  content: "\ff457";
}
.game-icon-death-note:before {
  content: "\ff458";
}
.game-icon-death-skull:before {
  content: "\ff459";
}
.game-icon-death-star:before {
  content: "\ff45a";
}
.game-icon-death-zone:before {
  content: "\ff45b";
}
.game-icon-deathcab:before {
  content: "\ff45c";
}
.game-icon-decapitation:before {
  content: "\ff45d";
}
.game-icon-deer-head:before {
  content: "\ff45e";
}
.game-icon-deer-track:before {
  content: "\ff45f";
}
.game-icon-deer:before {
  content: "\ff460";
}
.game-icon-defense-satellite:before {
  content: "\ff461";
}
.game-icon-defensive-wall:before {
  content: "\ff462";
}
.game-icon-defibrilate:before {
  content: "\ff463";
}
.game-icon-deku-tree:before {
  content: "\ff464";
}
.game-icon-delicate-perfume:before {
  content: "\ff465";
}
.game-icon-delighted:before {
  content: "\ff466";
}
.game-icon-delivery-drone:before {
  content: "\ff467";
}
.game-icon-demolish:before {
  content: "\ff468";
}
.game-icon-dervish-swords:before {
  content: "\ff469";
}
.game-icon-desert-camp:before {
  content: "\ff46a";
}
.game-icon-desert-eagle:before {
  content: "\ff46b";
}
.game-icon-desert-skull:before {
  content: "\ff46c";
}
.game-icon-desert:before {
  content: "\ff46d";
}
.game-icon-deshret-red-crown:before {
  content: "\ff46e";
}
.game-icon-desk-lamp:before {
  content: "\ff46f";
}
.game-icon-desk:before {
  content: "\ff470";
}
.game-icon-despair:before {
  content: "\ff471";
}
.game-icon-detonator:before {
  content: "\ff472";
}
.game-icon-detour:before {
  content: "\ff473";
}
.game-icon-devil-mask:before {
  content: "\ff474";
}
.game-icon-dew:before {
  content: "\ff475";
}
.game-icon-diablo-skull:before {
  content: "\ff476";
}
.game-icon-diagram:before {
  content: "\ff477";
}
.game-icon-dial-padlock:before {
  content: "\ff478";
}
.game-icon-diamond-hard:before {
  content: "\ff479";
}
.game-icon-diamond-hilt:before {
  content: "\ff47a";
}
.game-icon-diamond-ring:before {
  content: "\ff47b";
}
.game-icon-diamond-trophy:before {
  content: "\ff47c";
}
.game-icon-diamonds-smile:before {
  content: "\ff47d";
}
.game-icon-diamonds:before {
  content: "\ff47e";
}
.game-icon-dice-eight-faces-eight:before {
  content: "\ff47f";
}
.game-icon-dice-fire:before {
  content: "\ff480";
}
.game-icon-dice-shield:before {
  content: "\ff481";
}
.game-icon-dice-six-faces-five:before {
  content: "\ff482";
}
.game-icon-dice-six-faces-four:before {
  content: "\ff483";
}
.game-icon-dice-six-faces-one:before {
  content: "\ff484";
}
.game-icon-dice-six-faces-six:before {
  content: "\ff485";
}
.game-icon-dice-six-faces-three:before {
  content: "\ff486";
}
.game-icon-dice-six-faces-two:before {
  content: "\ff487";
}
.game-icon-dice-target:before {
  content: "\ff488";
}
.game-icon-dice-twenty-faces-one:before {
  content: "\ff489";
}
.game-icon-dice-twenty-faces-twenty:before {
  content: "\ff48a";
}
.game-icon-dig-dug:before {
  content: "\ff48b";
}
.game-icon-dig-hole:before {
  content: "\ff48c";
}
.game-icon-digital-trace:before {
  content: "\ff48d";
}
.game-icon-dimetrodon:before {
  content: "\ff48e";
}
.game-icon-dinosaur-bones:before {
  content: "\ff48f";
}
.game-icon-dinosaur-egg:before {
  content: "\ff490";
}
.game-icon-dinosaur-rex:before {
  content: "\ff491";
}
.game-icon-diplodocus:before {
  content: "\ff492";
}
.game-icon-diploma:before {
  content: "\ff493";
}
.game-icon-direction-sign:before {
  content: "\ff494";
}
.game-icon-direction-signs:before {
  content: "\ff495";
}
.game-icon-director-chair:before {
  content: "\ff496";
}
.game-icon-direwolf:before {
  content: "\ff497";
}
.game-icon-disc-golf-bag:before {
  content: "\ff498";
}
.game-icon-disc-golf-basket:before {
  content: "\ff499";
}
.game-icon-disc:before {
  content: "\ff49a";
}
.game-icon-discobolus:before {
  content: "\ff49b";
}
.game-icon-discussion:before {
  content: "\ff49c";
}
.game-icon-disintegrate:before {
  content: "\ff49d";
}
.game-icon-distraction-2:before {
  content: "\ff49e";
}
.game-icon-distraction:before {
  content: "\ff49f";
}
.game-icon-distress-signal:before {
  content: "\ff4a0";
}
.game-icon-divergence:before {
  content: "\ff4a1";
}
.game-icon-divert:before {
  content: "\ff4a2";
}
.game-icon-divided-spiral:before {
  content: "\ff4a3";
}
.game-icon-divided-square:before {
  content: "\ff4a4";
}
.game-icon-diving-dagger:before {
  content: "\ff4a5";
}
.game-icon-diving-helmet:before {
  content: "\ff4a6";
}
.game-icon-djed-pillar:before {
  content: "\ff4a7";
}
.game-icon-djembe:before {
  content: "\ff4a8";
}
.game-icon-djinn:before {
  content: "\ff4a9";
}
.game-icon-dna1:before {
  content: "\ff4aa";
}
.game-icon-dna2:before {
  content: "\ff4ab";
}
.game-icon-doctor-face:before {
  content: "\ff4ac";
}
.game-icon-dodge:before {
  content: "\ff4ad";
}
.game-icon-dodging:before {
  content: "\ff4ae";
}
.game-icon-dog-bowl:before {
  content: "\ff4af";
}
.game-icon-dog-house:before {
  content: "\ff4b0";
}
.game-icon-dolmen:before {
  content: "\ff4b1";
}
.game-icon-dolphin:before {
  content: "\ff4b2";
}
.game-icon-domino-mask:before {
  content: "\ff4b3";
}
.game-icon-domino-tiles:before {
  content: "\ff4b4";
}
.game-icon-doner-kebab:before {
  content: "\ff4b5";
}
.game-icon-donkey:before {
  content: "\ff4b6";
}
.game-icon-donut:before {
  content: "\ff4b7";
}
.game-icon-door-handle:before {
  content: "\ff4b8";
}
.game-icon-door-ring-handle:before {
  content: "\ff4b9";
}
.game-icon-door-watcher:before {
  content: "\ff4ba";
}
.game-icon-door:before {
  content: "\ff4bb";
}
.game-icon-doorway:before {
  content: "\ff4bc";
}
.game-icon-dorsal-scales:before {
  content: "\ff4bd";
}
.game-icon-double-diaphragm:before {
  content: "\ff4be";
}
.game-icon-double-dragon:before {
  content: "\ff4bf";
}
.game-icon-double-face-mask:before {
  content: "\ff4c0";
}
.game-icon-double-fish:before {
  content: "\ff4c1";
}
.game-icon-double-necklace:before {
  content: "\ff4c2";
}
.game-icon-double-quaver:before {
  content: "\ff4c3";
}
.game-icon-double-ringed-orb:before {
  content: "\ff4c4";
}
.game-icon-double-shot:before {
  content: "\ff4c5";
}
.game-icon-double-street-lights:before {
  content: "\ff4c6";
}
.game-icon-doubled:before {
  content: "\ff4c7";
}
.game-icon-dough-roller:before {
  content: "\ff4c8";
}
.game-icon-dove:before {
  content: "\ff4c9";
}
.game-icon-dozen:before {
  content: "\ff4ca";
}
.game-icon-dragon-balls:before {
  content: "\ff4cb";
}
.game-icon-dragon-breath:before {
  content: "\ff4cc";
}
.game-icon-dragon-head-2:before {
  content: "\ff4cd";
}
.game-icon-dragon-head:before {
  content: "\ff4ce";
}
.game-icon-dragon-orb:before {
  content: "\ff4cf";
}
.game-icon-dragon-shield:before {
  content: "\ff4d0";
}
.game-icon-dragon-spiral:before {
  content: "\ff4d1";
}
.game-icon-dragonfly:before {
  content: "\ff4d2";
}
.game-icon-drakkar-dragon:before {
  content: "\ff4d3";
}
.game-icon-drakkar:before {
  content: "\ff4d4";
}
.game-icon-drama-masks:before {
  content: "\ff4d5";
}
.game-icon-drawbridge:before {
  content: "\ff4d6";
}
.game-icon-dread-skull:before {
  content: "\ff4d7";
}
.game-icon-dread:before {
  content: "\ff4d8";
}
.game-icon-dreadnought:before {
  content: "\ff4d9";
}
.game-icon-dream-catcher:before {
  content: "\ff4da";
}
.game-icon-dress:before {
  content: "\ff4db";
}
.game-icon-drill-2:before {
  content: "\ff4dc";
}
.game-icon-drill:before {
  content: "\ff4dd";
}
.game-icon-drink-me:before {
  content: "\ff4de";
}
.game-icon-drinking:before {
  content: "\ff4df";
}
.game-icon-dripping-blade:before {
  content: "\ff4e0";
}
.game-icon-dripping-goo:before {
  content: "\ff4e1";
}
.game-icon-dripping-honey:before {
  content: "\ff4e2";
}
.game-icon-dripping-knife:before {
  content: "\ff4e3";
}
.game-icon-dripping-star:before {
  content: "\ff4e4";
}
.game-icon-dripping-stone:before {
  content: "\ff4e5";
}
.game-icon-dripping-sword:before {
  content: "\ff4e6";
}
.game-icon-dripping-tube:before {
  content: "\ff4e7";
}
.game-icon-drop-earrings:before {
  content: "\ff4e8";
}
.game-icon-drop-weapon:before {
  content: "\ff4e9";
}
.game-icon-drop:before {
  content: "\ff4ea";
}
.game-icon-droplet-splash:before {
  content: "\ff4eb";
}
.game-icon-droplets:before {
  content: "\ff4ec";
}
.game-icon-drowning:before {
  content: "\ff4ed";
}
.game-icon-drum-kit:before {
  content: "\ff4ee";
}
.game-icon-drum:before {
  content: "\ff4ef";
}
.game-icon-duality-mask:before {
  content: "\ff4f0";
}
.game-icon-duality:before {
  content: "\ff4f1";
}
.game-icon-duck-palm:before {
  content: "\ff4f2";
}
.game-icon-duck:before {
  content: "\ff4f3";
}
.game-icon-duel:before {
  content: "\ff4f4";
}
.game-icon-duffel-bag:before {
  content: "\ff4f5";
}
.game-icon-dumpling-bao:before {
  content: "\ff4f6";
}
.game-icon-dumpling:before {
  content: "\ff4f7";
}
.game-icon-dunce-cap:before {
  content: "\ff4f8";
}
.game-icon-dungeon-gate:before {
  content: "\ff4f9";
}
.game-icon-dungeon-light:before {
  content: "\ff4fa";
}
.game-icon-duration:before {
  content: "\ff4fb";
}
.game-icon-dust-cloud:before {
  content: "\ff4fc";
}
.game-icon-dutch-bike:before {
  content: "\ff4fd";
}
.game-icon-dwarf-face:before {
  content: "\ff4fe";
}
.game-icon-dwarf-helmet:before {
  content: "\ff4ff";
}
.game-icon-dwarf-king:before {
  content: "\ff500";
}
.game-icon-dwennimmen:before {
  content: "\ff501";
}
.game-icon-dynamite:before {
  content: "\ff502";
}
.game-icon-eagle-emblem:before {
  content: "\ff503";
}
.game-icon-eagle-head:before {
  content: "\ff504";
}
.game-icon-earbuds:before {
  content: "\ff505";
}
.game-icon-earrings:before {
  content: "\ff506";
}
.game-icon-earth-africa-europe:before {
  content: "\ff507";
}
.game-icon-earth-america:before {
  content: "\ff508";
}
.game-icon-earth-asia-oceania:before {
  content: "\ff509";
}
.game-icon-earth-crack:before {
  content: "\ff50a";
}
.game-icon-earth-spit:before {
  content: "\ff50b";
}
.game-icon-earth-worm:before {
  content: "\ff50c";
}
.game-icon-earwig:before {
  content: "\ff50d";
}
.game-icon-easel:before {
  content: "\ff50e";
}
.game-icon-easter-egg:before {
  content: "\ff50f";
}
.game-icon-eating-pelican:before {
  content: "\ff510";
}
.game-icon-eating:before {
  content: "\ff511";
}
.game-icon-echo-ripples:before {
  content: "\ff512";
}
.game-icon-eclipse-flare:before {
  content: "\ff513";
}
.game-icon-eclipse-saw:before {
  content: "\ff514";
}
.game-icon-eclipse:before {
  content: "\ff515";
}
.game-icon-ecology:before {
  content: "\ff516";
}
.game-icon-edge-crack:before {
  content: "\ff517";
}
.game-icon-edged-shield:before {
  content: "\ff518";
}
.game-icon-eel:before {
  content: "\ff519";
}
.game-icon-egg-clutch:before {
  content: "\ff51a";
}
.game-icon-egg-defense:before {
  content: "\ff51b";
}
.game-icon-egg-eye:before {
  content: "\ff51c";
}
.game-icon-egg-pod:before {
  content: "\ff51d";
}
.game-icon-egypt:before {
  content: "\ff51e";
}
.game-icon-egyptian-bird:before {
  content: "\ff51f";
}
.game-icon-egyptian-profile:before {
  content: "\ff520";
}
.game-icon-egyptian-pyramids:before {
  content: "\ff521";
}
.game-icon-egyptian-sphinx:before {
  content: "\ff522";
}
.game-icon-egyptian-temple:before {
  content: "\ff523";
}
.game-icon-egyptian-urns:before {
  content: "\ff524";
}
.game-icon-egyptian-walk:before {
  content: "\ff525";
}
.game-icon-eight-ball:before {
  content: "\ff526";
}
.game-icon-elbow-pad:before {
  content: "\ff527";
}
.game-icon-elderberry:before {
  content: "\ff528";
}
.game-icon-electric-whip:before {
  content: "\ff529";
}
.game-icon-electric:before {
  content: "\ff52a";
}
.game-icon-electrical-crescent:before {
  content: "\ff52b";
}
.game-icon-electrical-resistance:before {
  content: "\ff52c";
}
.game-icon-electrical-socket:before {
  content: "\ff52d";
}
.game-icon-elephant-head:before {
  content: "\ff52e";
}
.game-icon-elephant:before {
  content: "\ff52f";
}
.game-icon-elevator:before {
  content: "\ff530";
}
.game-icon-elf-ear:before {
  content: "\ff531";
}
.game-icon-elf-helmet:before {
  content: "\ff532";
}
.game-icon-elven-castle:before {
  content: "\ff533";
}
.game-icon-elysium-shade:before {
  content: "\ff534";
}
.game-icon-ember-shot:before {
  content: "\ff535";
}
.game-icon-embrassed-energy:before {
  content: "\ff536";
}
.game-icon-embryo:before {
  content: "\ff537";
}
.game-icon-emerald-necklace:before {
  content: "\ff538";
}
.game-icon-emerald:before {
  content: "\ff539";
}
.game-icon-empty-chessboard:before {
  content: "\ff53a";
}
.game-icon-empty-hourglass:before {
  content: "\ff53b";
}
.game-icon-empty-metal-bucket-handle:before {
  content: "\ff53c";
}
.game-icon-empty-metal-bucket:before {
  content: "\ff53d";
}
.game-icon-empty-wood-bucket-handle:before {
  content: "\ff53e";
}
.game-icon-empty-wood-bucket:before {
  content: "\ff53f";
}
.game-icon-encirclement:before {
  content: "\ff540";
}
.game-icon-energise:before {
  content: "\ff541";
}
.game-icon-energy-arrow:before {
  content: "\ff542";
}
.game-icon-energy-breath:before {
  content: "\ff543";
}
.game-icon-energy-shield:before {
  content: "\ff544";
}
.game-icon-energy-sword:before {
  content: "\ff545";
}
.game-icon-energy-tank:before {
  content: "\ff546";
}
.game-icon-engagement-ring:before {
  content: "\ff547";
}
.game-icon-enlightenment:before {
  content: "\ff548";
}
.game-icon-enrage:before {
  content: "\ff549";
}
.game-icon-ent-mouth:before {
  content: "\ff54a";
}
.game-icon-entangled-typhoon:before {
  content: "\ff54b";
}
.game-icon-entry-door:before {
  content: "\ff54c";
}
.game-icon-envelope:before {
  content: "\ff54d";
}
.game-icon-erlenmeyer:before {
  content: "\ff54e";
}
.game-icon-ermine:before {
  content: "\ff54f";
}
.game-icon-eruption:before {
  content: "\ff550";
}
.game-icon-escalator:before {
  content: "\ff551";
}
.game-icon-eskimo:before {
  content: "\ff552";
}
.game-icon-eternal-love:before {
  content: "\ff553";
}
.game-icon-european-flag:before {
  content: "\ff554";
}
.game-icon-evasion:before {
  content: "\ff555";
}
.game-icon-evil-bat:before {
  content: "\ff556";
}
.game-icon-evil-book:before {
  content: "\ff557";
}
.game-icon-evil-bud:before {
  content: "\ff558";
}
.game-icon-evil-comet:before {
  content: "\ff559";
}
.game-icon-evil-eyes:before {
  content: "\ff55a";
}
.game-icon-evil-fork:before {
  content: "\ff55b";
}
.game-icon-evil-hand:before {
  content: "\ff55c";
}
.game-icon-evil-love:before {
  content: "\ff55d";
}
.game-icon-evil-minion:before {
  content: "\ff55e";
}
.game-icon-evil-moon:before {
  content: "\ff55f";
}
.game-icon-evil-tower:before {
  content: "\ff560";
}
.game-icon-evil-tree:before {
  content: "\ff561";
}
.game-icon-evil-wings:before {
  content: "\ff562";
}
.game-icon-executioner-hood:before {
  content: "\ff563";
}
.game-icon-exit-door:before {
  content: "\ff564";
}
.game-icon-expand:before {
  content: "\ff565";
}
.game-icon-expanded-rays:before {
  content: "\ff566";
}
.game-icon-expander:before {
  content: "\ff567";
}
.game-icon-expense:before {
  content: "\ff568";
}
.game-icon-exploding-planet:before {
  content: "\ff569";
}
.game-icon-explosion-rays:before {
  content: "\ff56a";
}
.game-icon-explosive-materials:before {
  content: "\ff56b";
}
.game-icon-explosive-meeting:before {
  content: "\ff56c";
}
.game-icon-extra-lucid:before {
  content: "\ff56d";
}
.game-icon-extra-time:before {
  content: "\ff56e";
}
.game-icon-extraction-orb:before {
  content: "\ff56f";
}
.game-icon-eye-of-horus:before {
  content: "\ff570";
}
.game-icon-eye-shield:before {
  content: "\ff571";
}
.game-icon-eye-target:before {
  content: "\ff572";
}
.game-icon-eyeball:before {
  content: "\ff573";
}
.game-icon-eyedropper:before {
  content: "\ff574";
}
.game-icon-eyelashes:before {
  content: "\ff575";
}
.game-icon-eyepatch:before {
  content: "\ff576";
}
.game-icon-eyestalk:before {
  content: "\ff577";
}
.game-icon-f-clef:before {
  content: "\ff578";
}
.game-icon-f1-car:before {
  content: "\ff579";
}
.game-icon-face-to-face:before {
  content: "\ff57a";
}
.game-icon-factory-arm:before {
  content: "\ff57b";
}
.game-icon-factory:before {
  content: "\ff57c";
}
.game-icon-fairy-2:before {
  content: "\ff57d";
}
.game-icon-fairy-wand:before {
  content: "\ff57e";
}
.game-icon-fairy-wings:before {
  content: "\ff57f";
}
.game-icon-fairy:before {
  content: "\ff580";
}
.game-icon-falcon-moon:before {
  content: "\ff581";
}
.game-icon-fall-down:before {
  content: "\ff582";
}
.game-icon-falling-blob:before {
  content: "\ff583";
}
.game-icon-falling-bomb:before {
  content: "\ff584";
}
.game-icon-falling-boulder:before {
  content: "\ff585";
}
.game-icon-falling-eye:before {
  content: "\ff586";
}
.game-icon-falling-leaf:before {
  content: "\ff587";
}
.game-icon-falling-ovoid:before {
  content: "\ff588";
}
.game-icon-falling-rocks:before {
  content: "\ff589";
}
.game-icon-falling-star:before {
  content: "\ff58a";
}
.game-icon-falling:before {
  content: "\ff58b";
}
.game-icon-fallout-shelter:before {
  content: "\ff58c";
}
.game-icon-famas:before {
  content: "\ff58d";
}
.game-icon-family-house:before {
  content: "\ff58e";
}
.game-icon-family-tree:before {
  content: "\ff58f";
}
.game-icon-fanged-skull:before {
  content: "\ff590";
}
.game-icon-fangs-circle:before {
  content: "\ff591";
}
.game-icon-fangs:before {
  content: "\ff592";
}
.game-icon-farm-tractor:before {
  content: "\ff593";
}
.game-icon-farmer:before {
  content: "\ff594";
}
.game-icon-fast-arrow:before {
  content: "\ff595";
}
.game-icon-fast-backward-button:before {
  content: "\ff596";
}
.game-icon-fast-forward-button:before {
  content: "\ff597";
}
.game-icon-fast-noodles:before {
  content: "\ff598";
}
.game-icon-fat:before {
  content: "\ff599";
}
.game-icon-feather-necklace:before {
  content: "\ff59a";
}
.game-icon-feather-wound:before {
  content: "\ff59b";
}
.game-icon-feather:before {
  content: "\ff59c";
}
.game-icon-feathered-wing:before {
  content: "\ff59d";
}
.game-icon-fedora:before {
  content: "\ff59e";
}
.game-icon-feline:before {
  content: "\ff59f";
}
.game-icon-female-legs:before {
  content: "\ff5a0";
}
.game-icon-female-vampire:before {
  content: "\ff5a1";
}
.game-icon-female:before {
  content: "\ff5a2";
}
.game-icon-fencer:before {
  content: "\ff5a3";
}
.game-icon-fern:before {
  content: "\ff5a4";
}
.game-icon-fertilizer-bag:before {
  content: "\ff5a5";
}
.game-icon-fetus:before {
  content: "\ff5a6";
}
.game-icon-fez:before {
  content: "\ff5a7";
}
.game-icon-field-gun:before {
  content: "\ff5a8";
}
.game-icon-field:before {
  content: "\ff5a9";
}
.game-icon-figurehead:before {
  content: "\ff5aa";
}
.game-icon-files:before {
  content: "\ff5ab";
}
.game-icon-film-projector:before {
  content: "\ff5ac";
}
.game-icon-film-spool:before {
  content: "\ff5ad";
}
.game-icon-film-strip:before {
  content: "\ff5ae";
}
.game-icon-finch:before {
  content: "\ff5af";
}
.game-icon-finger-print:before {
  content: "\ff5b0";
}
.game-icon-fingernail:before {
  content: "\ff5b1";
}
.game-icon-fingers-crossed:before {
  content: "\ff5b2";
}
.game-icon-finish-line:before {
  content: "\ff5b3";
}
.game-icon-fire-ace:before {
  content: "\ff5b4";
}
.game-icon-fire-axe:before {
  content: "\ff5b5";
}
.game-icon-fire-bomb:before {
  content: "\ff5b6";
}
.game-icon-fire-bottle:before {
  content: "\ff5b7";
}
.game-icon-fire-bowl:before {
  content: "\ff5b8";
}
.game-icon-fire-breath:before {
  content: "\ff5b9";
}
.game-icon-fire-dash:before {
  content: "\ff5ba";
}
.game-icon-fire-extinguisher:before {
  content: "\ff5bb";
}
.game-icon-fire-flower:before {
  content: "\ff5bc";
}
.game-icon-fire-gem:before {
  content: "\ff5bd";
}
.game-icon-fire-punch:before {
  content: "\ff5be";
}
.game-icon-fire-ray:before {
  content: "\ff5bf";
}
.game-icon-fire-ring:before {
  content: "\ff5c0";
}
.game-icon-fire-shield:before {
  content: "\ff5c1";
}
.game-icon-fire-shrine:before {
  content: "\ff5c2";
}
.game-icon-fire-silhouette:before {
  content: "\ff5c3";
}
.game-icon-fire-spell-cast:before {
  content: "\ff5c4";
}
.game-icon-fire-tail:before {
  content: "\ff5c5";
}
.game-icon-fire-wave:before {
  content: "\ff5c6";
}
.game-icon-fire-zone:before {
  content: "\ff5c7";
}
.game-icon-fire:before {
  content: "\ff5c8";
}
.game-icon-fireball:before {
  content: "\ff5c9";
}
.game-icon-fireflake:before {
  content: "\ff5ca";
}
.game-icon-fireplace:before {
  content: "\ff5cb";
}
.game-icon-firewall:before {
  content: "\ff5cc";
}
.game-icon-firework-rocket:before {
  content: "\ff5cd";
}
.game-icon-first-aid-kit:before {
  content: "\ff5ce";
}
.game-icon-fish-bucket:before {
  content: "\ff5cf";
}
.game-icon-fish-cooked:before {
  content: "\ff5d0";
}
.game-icon-fish-corpse:before {
  content: "\ff5d1";
}
.game-icon-fish-eggs:before {
  content: "\ff5d2";
}
.game-icon-fish-escape:before {
  content: "\ff5d3";
}
.game-icon-fish-monster:before {
  content: "\ff5d4";
}
.game-icon-fish-scales:before {
  content: "\ff5d5";
}
.game-icon-fish-smoking:before {
  content: "\ff5d6";
}
.game-icon-fishbone:before {
  content: "\ff5d7";
}
.game-icon-fishhook-fork:before {
  content: "\ff5d8";
}
.game-icon-fishing-boat:before {
  content: "\ff5d9";
}
.game-icon-fishing-hook:before {
  content: "\ff5da";
}
.game-icon-fishing-jig:before {
  content: "\ff5db";
}
.game-icon-fishing-lure:before {
  content: "\ff5dc";
}
.game-icon-fishing-net:before {
  content: "\ff5dd";
}
.game-icon-fishing-pole:before {
  content: "\ff5de";
}
.game-icon-fishing-spoon:before {
  content: "\ff5df";
}
.game-icon-fishing:before {
  content: "\ff5e0";
}
.game-icon-fission:before {
  content: "\ff5e1";
}
.game-icon-fist-2:before {
  content: "\ff5e2";
}
.game-icon-fist:before {
  content: "\ff5e3";
}
.game-icon-fizzing-flask:before {
  content: "\ff5e4";
}
.game-icon-flag-objective:before {
  content: "\ff5e5";
}
.game-icon-flail:before {
  content: "\ff5e6";
}
.game-icon-flake:before {
  content: "\ff5e7";
}
.game-icon-flame-claws:before {
  content: "\ff5e8";
}
.game-icon-flame-spin:before {
  content: "\ff5e9";
}
.game-icon-flame-tunnel:before {
  content: "\ff5ea";
}
.game-icon-flame:before {
  content: "\ff5eb";
}
.game-icon-flamed-leaf:before {
  content: "\ff5ec";
}
.game-icon-flamer:before {
  content: "\ff5ed";
}
.game-icon-flamethrower-soldier:before {
  content: "\ff5ee";
}
.game-icon-flamethrower:before {
  content: "\ff5ef";
}
.game-icon-flaming-arrow:before {
  content: "\ff5f0";
}
.game-icon-flaming-claw:before {
  content: "\ff5f1";
}
.game-icon-flaming-sheet:before {
  content: "\ff5f2";
}
.game-icon-flaming-trident:before {
  content: "\ff5f3";
}
.game-icon-flamingo:before {
  content: "\ff5f4";
}
.game-icon-flanged-mace:before {
  content: "\ff5f5";
}
.game-icon-flash-grenade:before {
  content: "\ff5f6";
}
.game-icon-flashlight:before {
  content: "\ff5f7";
}
.game-icon-flat-hammer:before {
  content: "\ff5f8";
}
.game-icon-flat-paw-print:before {
  content: "\ff5f9";
}
.game-icon-flat-platform:before {
  content: "\ff5fa";
}
.game-icon-flat-star:before {
  content: "\ff5fb";
}
.game-icon-flat-tire:before {
  content: "\ff5fc";
}
.game-icon-flatbed-covered:before {
  content: "\ff5fd";
}
.game-icon-flatbed:before {
  content: "\ff5fe";
}
.game-icon-flatfish:before {
  content: "\ff5ff";
}
.game-icon-flax:before {
  content: "\ff600";
}
.game-icon-fleshy-mass:before {
  content: "\ff601";
}
.game-icon-fleur-de-lys:before {
  content: "\ff602";
}
.game-icon-flexible-lamp:before {
  content: "\ff603";
}
.game-icon-flexible-star:before {
  content: "\ff604";
}
.game-icon-flint-spark:before {
  content: "\ff605";
}
.game-icon-flip-flops:before {
  content: "\ff606";
}
.game-icon-floating-crystal:before {
  content: "\ff607";
}
.game-icon-floating-ghost:before {
  content: "\ff608";
}
.game-icon-floating-platforms:before {
  content: "\ff609";
}
.game-icon-floating-tentacles:before {
  content: "\ff60a";
}
.game-icon-flood:before {
  content: "\ff60b";
}
.game-icon-floor-hatch:before {
  content: "\ff60c";
}
.game-icon-floor-polisher:before {
  content: "\ff60d";
}
.game-icon-flour:before {
  content: "\ff60e";
}
.game-icon-flower-emblem:before {
  content: "\ff60f";
}
.game-icon-flower-hat:before {
  content: "\ff610";
}
.game-icon-flower-pot:before {
  content: "\ff611";
}
.game-icon-flower-star:before {
  content: "\ff612";
}
.game-icon-flower-twirl:before {
  content: "\ff613";
}
.game-icon-flowers:before {
  content: "\ff614";
}
.game-icon-fluffy-cloud:before {
  content: "\ff615";
}
.game-icon-fluffy-flame:before {
  content: "\ff616";
}
.game-icon-fluffy-swirl:before {
  content: "\ff617";
}
.game-icon-fluffy-trefoil:before {
  content: "\ff618";
}
.game-icon-fluffy-wing:before {
  content: "\ff619";
}
.game-icon-flute:before {
  content: "\ff61a";
}
.game-icon-fly:before {
  content: "\ff61b";
}
.game-icon-flying-beetle:before {
  content: "\ff61c";
}
.game-icon-flying-dagger:before {
  content: "\ff61d";
}
.game-icon-flying-flag:before {
  content: "\ff61e";
}
.game-icon-flying-fox:before {
  content: "\ff61f";
}
.game-icon-flying-shuriken:before {
  content: "\ff620";
}
.game-icon-flying-target:before {
  content: "\ff621";
}
.game-icon-flying-trout:before {
  content: "\ff622";
}
.game-icon-fn-fal:before {
  content: "\ff623";
}
.game-icon-foam:before {
  content: "\ff624";
}
.game-icon-foamy-disc:before {
  content: "\ff625";
}
.game-icon-focused-lightning:before {
  content: "\ff626";
}
.game-icon-fog-light:before {
  content: "\ff627";
}
.game-icon-fog:before {
  content: "\ff628";
}
.game-icon-folded-paper:before {
  content: "\ff629";
}
.game-icon-fomorian:before {
  content: "\ff62a";
}
.game-icon-food-chain:before {
  content: "\ff62b";
}
.game-icon-food-truck:before {
  content: "\ff62c";
}
.game-icon-foot-plaster:before {
  content: "\ff62d";
}
.game-icon-foot-trip:before {
  content: "\ff62e";
}
.game-icon-footprint:before {
  content: "\ff62f";
}
.game-icon-footsteps:before {
  content: "\ff630";
}
.game-icon-footy-field:before {
  content: "\ff631";
}
.game-icon-forearm:before {
  content: "\ff632";
}
.game-icon-forest-camp:before {
  content: "\ff633";
}
.game-icon-forest-entrance:before {
  content: "\ff634";
}
.game-icon-forest:before {
  content: "\ff635";
}
.game-icon-fork-knife-spoon:before {
  content: "\ff636";
}
.game-icon-forklift:before {
  content: "\ff637";
}
.game-icon-forward-field:before {
  content: "\ff638";
}
.game-icon-forward-sun:before {
  content: "\ff639";
}
.game-icon-fossil:before {
  content: "\ff63a";
}
.game-icon-foundry-bucket:before {
  content: "\ff63b";
}
.game-icon-fountain-pen:before {
  content: "\ff63c";
}
.game-icon-fountain:before {
  content: "\ff63d";
}
.game-icon-fox-head:before {
  content: "\ff63e";
}
.game-icon-fox-tail:before {
  content: "\ff63f";
}
.game-icon-fox:before {
  content: "\ff640";
}
.game-icon-fragmented-meteor:before {
  content: "\ff641";
}
.game-icon-fragmented-sword:before {
  content: "\ff642";
}
.game-icon-fragrance:before {
  content: "\ff643";
}
.game-icon-france:before {
  content: "\ff644";
}
.game-icon-frankenstein-creature:before {
  content: "\ff645";
}
.game-icon-frayed-arrow:before {
  content: "\ff646";
}
.game-icon-freedom-dove:before {
  content: "\ff647";
}
.game-icon-freemasonry:before {
  content: "\ff648";
}
.game-icon-french-fries:before {
  content: "\ff649";
}
.game-icon-french-horn:before {
  content: "\ff64a";
}
.game-icon-fridge:before {
  content: "\ff64b";
}
.game-icon-fried-eggs:before {
  content: "\ff64c";
}
.game-icon-fried-fish:before {
  content: "\ff64d";
}
.game-icon-frisbee:before {
  content: "\ff64e";
}
.game-icon-froe-and-mallet:before {
  content: "\ff64f";
}
.game-icon-frog-foot:before {
  content: "\ff650";
}
.game-icon-frog-prince:before {
  content: "\ff651";
}
.game-icon-frog:before {
  content: "\ff652";
}
.game-icon-front-teeth:before {
  content: "\ff653";
}
.game-icon-frontal-lobe:before {
  content: "\ff654";
}
.game-icon-frostfire:before {
  content: "\ff655";
}
.game-icon-frozen-arrow:before {
  content: "\ff656";
}
.game-icon-frozen-block:before {
  content: "\ff657";
}
.game-icon-frozen-body:before {
  content: "\ff658";
}
.game-icon-frozen-orb:before {
  content: "\ff659";
}
.game-icon-frozen-ring:before {
  content: "\ff65a";
}
.game-icon-fruit-bowl:before {
  content: "\ff65b";
}
.game-icon-fruit-tree:before {
  content: "\ff65c";
}
.game-icon-fruiting:before {
  content: "\ff65d";
}
.game-icon-fuel-tank:before {
  content: "\ff65e";
}
.game-icon-fuji:before {
  content: "\ff65f";
}
.game-icon-fulguro-punch:before {
  content: "\ff660";
}
.game-icon-full-folder:before {
  content: "\ff661";
}
.game-icon-full-metal-bucket-handle:before {
  content: "\ff662";
}
.game-icon-full-metal-bucket:before {
  content: "\ff663";
}
.game-icon-full-motorcycle-helmet:before {
  content: "\ff664";
}
.game-icon-full-pizza:before {
  content: "\ff665";
}
.game-icon-full-wood-bucket-handle:before {
  content: "\ff666";
}
.game-icon-full-wood-bucket:before {
  content: "\ff667";
}
.game-icon-funnel:before {
  content: "\ff668";
}
.game-icon-fur-boot:before {
  content: "\ff669";
}
.game-icon-fur-shirt:before {
  content: "\ff66a";
}
.game-icon-furnace:before {
  content: "\ff66b";
}
.game-icon-g-clef:before {
  content: "\ff66c";
}
.game-icon-galaxy:before {
  content: "\ff66d";
}
.game-icon-galea:before {
  content: "\ff66e";
}
.game-icon-galleon:before {
  content: "\ff66f";
}
.game-icon-galley:before {
  content: "\ff670";
}
.game-icon-game-console:before {
  content: "\ff671";
}
.game-icon-gamepad-cross:before {
  content: "\ff672";
}
.game-icon-gamepad:before {
  content: "\ff673";
}
.game-icon-gardening-shears:before {
  content: "\ff674";
}
.game-icon-gargoyle:before {
  content: "\ff675";
}
.game-icon-garlic:before {
  content: "\ff676";
}
.game-icon-gas-mask-2:before {
  content: "\ff677";
}
.game-icon-gas-mask:before {
  content: "\ff678";
}
.game-icon-gas-pump:before {
  content: "\ff679";
}
.game-icon-gas-stove:before {
  content: "\ff67a";
}
.game-icon-gate:before {
  content: "\ff67b";
}
.game-icon-gauls-helm:before {
  content: "\ff67c";
}
.game-icon-gauntlet:before {
  content: "\ff67d";
}
.game-icon-gavel:before {
  content: "\ff67e";
}
.game-icon-gaze:before {
  content: "\ff67f";
}
.game-icon-gear-hammer:before {
  content: "\ff680";
}
.game-icon-gear-stick-pattern:before {
  content: "\ff681";
}
.game-icon-gear-stick:before {
  content: "\ff682";
}
.game-icon-gears:before {
  content: "\ff683";
}
.game-icon-gecko:before {
  content: "\ff684";
}
.game-icon-gem-chain:before {
  content: "\ff685";
}
.game-icon-gem-necklace:before {
  content: "\ff686";
}
.game-icon-gem-pendant:before {
  content: "\ff687";
}
.game-icon-gemini:before {
  content: "\ff688";
}
.game-icon-gems:before {
  content: "\ff689";
}
.game-icon-ghost-ally:before {
  content: "\ff68a";
}
.game-icon-ghost:before {
  content: "\ff68b";
}
.game-icon-giant-squid:before {
  content: "\ff68c";
}
.game-icon-giant:before {
  content: "\ff68d";
}
.game-icon-gibbet:before {
  content: "\ff68e";
}
.game-icon-gift-of-knowledge:before {
  content: "\ff68f";
}
.game-icon-gift-trap:before {
  content: "\ff690";
}
.game-icon-gingerbread-man:before {
  content: "\ff691";
}
.game-icon-ginkgo-leaf:before {
  content: "\ff692";
}
.game-icon-gladius:before {
  content: "\ff693";
}
.game-icon-glaive:before {
  content: "\ff694";
}
.game-icon-glass-ball:before {
  content: "\ff695";
}
.game-icon-glass-celebration:before {
  content: "\ff696";
}
.game-icon-glass-heart:before {
  content: "\ff697";
}
.game-icon-glass-shot:before {
  content: "\ff698";
}
.game-icon-glider:before {
  content: "\ff699";
}
.game-icon-globe-ring:before {
  content: "\ff69a";
}
.game-icon-globe:before {
  content: "\ff69b";
}
.game-icon-glock:before {
  content: "\ff69c";
}
.game-icon-gloop:before {
  content: "\ff69d";
}
.game-icon-gloves:before {
  content: "\ff69e";
}
.game-icon-glowing-artifact:before {
  content: "\ff69f";
}
.game-icon-glowing-hands:before {
  content: "\ff6a0";
}
.game-icon-gluttonous-smile:before {
  content: "\ff6a1";
}
.game-icon-gluttony:before {
  content: "\ff6a2";
}
.game-icon-goal-keeper:before {
  content: "\ff6a3";
}
.game-icon-goat:before {
  content: "\ff6a4";
}
.game-icon-goblin-camp:before {
  content: "\ff6a5";
}
.game-icon-goblin-head:before {
  content: "\ff6a6";
}
.game-icon-gold-bar:before {
  content: "\ff6a7";
}
.game-icon-gold-mine:before {
  content: "\ff6a8";
}
.game-icon-gold-nuggets:before {
  content: "\ff6a9";
}
.game-icon-gold-scarab:before {
  content: "\ff6aa";
}
.game-icon-gold-shell:before {
  content: "\ff6ab";
}
.game-icon-gold-stack:before {
  content: "\ff6ac";
}
.game-icon-golem-head:before {
  content: "\ff6ad";
}
.game-icon-golf-flag:before {
  content: "\ff6ae";
}
.game-icon-golf-tee:before {
  content: "\ff6af";
}
.game-icon-gong:before {
  content: "\ff6b0";
}
.game-icon-goo-explosion:before {
  content: "\ff6b1";
}
.game-icon-goo-skull:before {
  content: "\ff6b2";
}
.game-icon-goo-spurt:before {
  content: "\ff6b3";
}
.game-icon-gooey-daemon:before {
  content: "\ff6b4";
}
.game-icon-gooey-eyed-sun:before {
  content: "\ff6b5";
}
.game-icon-gooey-impact:before {
  content: "\ff6b6";
}
.game-icon-gooey-molecule:before {
  content: "\ff6b7";
}
.game-icon-gooey-sword:before {
  content: "\ff6b8";
}
.game-icon-goose:before {
  content: "\ff6b9";
}
.game-icon-gorilla:before {
  content: "\ff6ba";
}
.game-icon-gothic-cross:before {
  content: "\ff6bb";
}
.game-icon-gps:before {
  content: "\ff6bc";
}
.game-icon-grab:before {
  content: "\ff6bd";
}
.game-icon-graduate-cap:before {
  content: "\ff6be";
}
.game-icon-grain-bundle:before {
  content: "\ff6bf";
}
.game-icon-grain:before {
  content: "\ff6c0";
}
.game-icon-granary:before {
  content: "\ff6c1";
}
.game-icon-grand-piano:before {
  content: "\ff6c2";
}
.game-icon-grapes:before {
  content: "\ff6c3";
}
.game-icon-grapple:before {
  content: "\ff6c4";
}
.game-icon-grasping-claws:before {
  content: "\ff6c5";
}
.game-icon-grasping-slug:before {
  content: "\ff6c6";
}
.game-icon-grass-mushroom:before {
  content: "\ff6c7";
}
.game-icon-grass:before {
  content: "\ff6c8";
}
.game-icon-grave-flowers:before {
  content: "\ff6c9";
}
.game-icon-graveyard:before {
  content: "\ff6ca";
}
.game-icon-grease-trap:before {
  content: "\ff6cb";
}
.game-icon-great-pyramid:before {
  content: "\ff6cc";
}
.game-icon-great-war-tank:before {
  content: "\ff6cd";
}
.game-icon-greaves:before {
  content: "\ff6ce";
}
.game-icon-greek-sphinx:before {
  content: "\ff6cf";
}
.game-icon-greek-temple:before {
  content: "\ff6d0";
}
.game-icon-green-power:before {
  content: "\ff6d1";
}
.game-icon-greenhouse:before {
  content: "\ff6d2";
}
.game-icon-grenade-2:before {
  content: "\ff6d3";
}
.game-icon-grenade:before {
  content: "\ff6d4";
}
.game-icon-griffin-shield:before {
  content: "\ff6d5";
}
.game-icon-griffin-symbol:before {
  content: "\ff6d6";
}
.game-icon-grim-reaper:before {
  content: "\ff6d7";
}
.game-icon-ground-sprout:before {
  content: "\ff6d8";
}
.game-icon-groundbreaker:before {
  content: "\ff6d9";
}
.game-icon-grouped-drops:before {
  content: "\ff6da";
}
.game-icon-growth:before {
  content: "\ff6db";
}
.game-icon-guarded-tower:before {
  content: "\ff6dc";
}
.game-icon-guards:before {
  content: "\ff6dd";
}
.game-icon-guatemala:before {
  content: "\ff6de";
}
.game-icon-guillotine:before {
  content: "\ff6df";
}
.game-icon-guitar-bass-head:before {
  content: "\ff6e0";
}
.game-icon-guitar-head:before {
  content: "\ff6e1";
}
.game-icon-guitar:before {
  content: "\ff6e2";
}
.game-icon-gun-rose:before {
  content: "\ff6e3";
}
.game-icon-gun-stock:before {
  content: "\ff6e4";
}
.game-icon-gunshot:before {
  content: "\ff6e5";
}
.game-icon-gym-bag:before {
  content: "\ff6e6";
}
.game-icon-h2o:before {
  content: "\ff6e7";
}
.game-icon-habitat-dome:before {
  content: "\ff6e8";
}
.game-icon-hades-symbol:before {
  content: "\ff6e9";
}
.game-icon-hair-strands:before {
  content: "\ff6ea";
}
.game-icon-halberd-shuriken:before {
  content: "\ff6eb";
}
.game-icon-halberd:before {
  content: "\ff6ec";
}
.game-icon-half-body-crawling:before {
  content: "\ff6ed";
}
.game-icon-half-dead:before {
  content: "\ff6ee";
}
.game-icon-half-heart:before {
  content: "\ff6ef";
}
.game-icon-half-log:before {
  content: "\ff6f0";
}
.game-icon-half-tornado:before {
  content: "\ff6f1";
}
.game-icon-halt:before {
  content: "\ff6f2";
}
.game-icon-ham-shank:before {
  content: "\ff6f3";
}
.game-icon-hamburger-menu:before {
  content: "\ff6f4";
}
.game-icon-hamburger:before {
  content: "\ff6f5";
}
.game-icon-hammer-break:before {
  content: "\ff6f6";
}
.game-icon-hammer-drop:before {
  content: "\ff6f7";
}
.game-icon-hammer-nails:before {
  content: "\ff6f8";
}
.game-icon-hammer-sickle:before {
  content: "\ff6f9";
}
.game-icon-hand-2:before {
  content: "\ff6fa";
}
.game-icon-hand-bag:before {
  content: "\ff6fb";
}
.game-icon-hand-bandage:before {
  content: "\ff6fc";
}
.game-icon-hand-grip:before {
  content: "\ff6fd";
}
.game-icon-hand-of-god:before {
  content: "\ff6fe";
}
.game-icon-hand-ok:before {
  content: "\ff6ff";
}
.game-icon-hand-saw:before {
  content: "\ff700";
}
.game-icon-hand-truck:before {
  content: "\ff701";
}
.game-icon-hand-wing:before {
  content: "\ff702";
}
.game-icon-hand:before {
  content: "\ff703";
}
.game-icon-handcuffed:before {
  content: "\ff704";
}
.game-icon-handcuffs:before {
  content: "\ff705";
}
.game-icon-handheld-fan:before {
  content: "\ff706";
}
.game-icon-hang-glider-2:before {
  content: "\ff707";
}
.game-icon-hang-glider:before {
  content: "\ff708";
}
.game-icon-hanger:before {
  content: "\ff709";
}
.game-icon-hanging-sign:before {
  content: "\ff70a";
}
.game-icon-hanging-spider:before {
  content: "\ff70b";
}
.game-icon-happy-skull:before {
  content: "\ff70c";
}
.game-icon-harbor-dock:before {
  content: "\ff70d";
}
.game-icon-harp:before {
  content: "\ff70e";
}
.game-icon-harpoon-chain:before {
  content: "\ff70f";
}
.game-icon-harpoon-trident:before {
  content: "\ff710";
}
.game-icon-harpy:before {
  content: "\ff711";
}
.game-icon-harry-potter-skull:before {
  content: "\ff712";
}
.game-icon-hasty-grave:before {
  content: "\ff713";
}
.game-icon-hatchet:before {
  content: "\ff714";
}
.game-icon-hatchets:before {
  content: "\ff715";
}
.game-icon-haunting:before {
  content: "\ff716";
}
.game-icon-hawk-emblem:before {
  content: "\ff717";
}
.game-icon-hazard-sign:before {
  content: "\ff718";
}
.game-icon-hazmat-suit:before {
  content: "\ff719";
}
.game-icon-head-shot:before {
  content: "\ff71a";
}
.game-icon-headband-knot:before {
  content: "\ff71b";
}
.game-icon-headphones:before {
  content: "\ff71c";
}
.game-icon-headshot-2:before {
  content: "\ff71d";
}
.game-icon-headshot:before {
  content: "\ff71e";
}
.game-icon-healing-shield:before {
  content: "\ff71f";
}
.game-icon-healing:before {
  content: "\ff720";
}
.game-icon-health-capsule:before {
  content: "\ff721";
}
.game-icon-health-decrease:before {
  content: "\ff722";
}
.game-icon-health-increase:before {
  content: "\ff723";
}
.game-icon-health-normal:before {
  content: "\ff724";
}
.game-icon-health-potion:before {
  content: "\ff725";
}
.game-icon-hearing-disabled:before {
  content: "\ff726";
}
.game-icon-heart-armor:before {
  content: "\ff727";
}
.game-icon-heart-battery:before {
  content: "\ff728";
}
.game-icon-heart-beats:before {
  content: "\ff729";
}
.game-icon-heart-bottle:before {
  content: "\ff72a";
}
.game-icon-heart-drop:before {
  content: "\ff72b";
}
.game-icon-heart-earrings:before {
  content: "\ff72c";
}
.game-icon-heart-inside:before {
  content: "\ff72d";
}
.game-icon-heart-key:before {
  content: "\ff72e";
}
.game-icon-heart-minus:before {
  content: "\ff72f";
}
.game-icon-heart-necklace:before {
  content: "\ff730";
}
.game-icon-heart-organ:before {
  content: "\ff731";
}
.game-icon-heart-plus:before {
  content: "\ff732";
}
.game-icon-heart-shield:before {
  content: "\ff733";
}
.game-icon-heart-stake:before {
  content: "\ff734";
}
.game-icon-heart-tower:before {
  content: "\ff735";
}
.game-icon-heart-wings:before {
  content: "\ff736";
}
.game-icon-heartburn:before {
  content: "\ff737";
}
.game-icon-hearts:before {
  content: "\ff738";
}
.game-icon-heat-haze:before {
  content: "\ff739";
}
.game-icon-heaven-gate:before {
  content: "\ff73a";
}
.game-icon-heavy-arrow:before {
  content: "\ff73b";
}
.game-icon-heavy-bullets:before {
  content: "\ff73c";
}
.game-icon-heavy-collar:before {
  content: "\ff73d";
}
.game-icon-heavy-fall:before {
  content: "\ff73e";
}
.game-icon-heavy-fighter:before {
  content: "\ff73f";
}
.game-icon-heavy-helm:before {
  content: "\ff740";
}
.game-icon-heavy-lightning:before {
  content: "\ff741";
}
.game-icon-heavy-rain:before {
  content: "\ff742";
}
.game-icon-heavy-thorny-triskelion:before {
  content: "\ff743";
}
.game-icon-heavy-timer:before {
  content: "\ff744";
}
.game-icon-hedgehog:before {
  content: "\ff745";
}
.game-icon-hedjet-white-crown:before {
  content: "\ff746";
}
.game-icon-helicoprion:before {
  content: "\ff747";
}
.game-icon-helicopter-tail:before {
  content: "\ff748";
}
.game-icon-helicopter:before {
  content: "\ff749";
}
.game-icon-hell-crosses:before {
  content: "\ff74a";
}
.game-icon-helmet-head-shot:before {
  content: "\ff74b";
}
.game-icon-helmet:before {
  content: "\ff74c";
}
.game-icon-help:before {
  content: "\ff74d";
}
.game-icon-hemp:before {
  content: "\ff74e";
}
.game-icon-heptagram:before {
  content: "\ff74f";
}
.game-icon-heraldic-sun:before {
  content: "\ff750";
}
.game-icon-herbs-bundle:before {
  content: "\ff751";
}
.game-icon-heron:before {
  content: "\ff752";
}
.game-icon-hexagonal-nut:before {
  content: "\ff753";
}
.game-icon-hexes:before {
  content: "\ff754";
}
.game-icon-hidden:before {
  content: "\ff755";
}
.game-icon-hieroglyph-legs:before {
  content: "\ff756";
}
.game-icon-hieroglyph-y:before {
  content: "\ff757";
}
.game-icon-high-five:before {
  content: "\ff758";
}
.game-icon-high-grass:before {
  content: "\ff759";
}
.game-icon-high-heel:before {
  content: "\ff75a";
}
.game-icon-high-kick:before {
  content: "\ff75b";
}
.game-icon-high-punch:before {
  content: "\ff75c";
}
.game-icon-high-shot:before {
  content: "\ff75d";
}
.game-icon-high-tide:before {
  content: "\ff75e";
}
.game-icon-highlighter:before {
  content: "\ff75f";
}
.game-icon-hiking:before {
  content: "\ff760";
}
.game-icon-hill-conquest:before {
  content: "\ff761";
}
.game-icon-hill-fort:before {
  content: "\ff762";
}
.game-icon-hills:before {
  content: "\ff763";
}
.game-icon-histogram:before {
  content: "\ff764";
}
.game-icon-hive-mind:before {
  content: "\ff765";
}
.game-icon-hive:before {
  content: "\ff766";
}
.game-icon-hobbit-door:before {
  content: "\ff767";
}
.game-icon-hobbit-dwelling:before {
  content: "\ff768";
}
.game-icon-hockey:before {
  content: "\ff769";
}
.game-icon-hole-ladder:before {
  content: "\ff76a";
}
.game-icon-hole:before {
  content: "\ff76b";
}
.game-icon-hollow-cat:before {
  content: "\ff76c";
}
.game-icon-holosphere:before {
  content: "\ff76d";
}
.game-icon-holy-grail:before {
  content: "\ff76e";
}
.game-icon-holy-hand-grenade:before {
  content: "\ff76f";
}
.game-icon-holy-oak:before {
  content: "\ff770";
}
.game-icon-holy-symbol:before {
  content: "\ff771";
}
.game-icon-holy-water:before {
  content: "\ff772";
}
.game-icon-home-garage:before {
  content: "\ff773";
}
.game-icon-honey-jar:before {
  content: "\ff774";
}
.game-icon-honeycomb:before {
  content: "\ff775";
}
.game-icon-honeypot:before {
  content: "\ff776";
}
.game-icon-hood:before {
  content: "\ff777";
}
.game-icon-hooded-assassin:before {
  content: "\ff778";
}
.game-icon-hooded-figure:before {
  content: "\ff779";
}
.game-icon-hoodie:before {
  content: "\ff77a";
}
.game-icon-hoof:before {
  content: "\ff77b";
}
.game-icon-hook:before {
  content: "\ff77c";
}
.game-icon-hops:before {
  content: "\ff77d";
}
.game-icon-horizon-road:before {
  content: "\ff77e";
}
.game-icon-horizontal-flip:before {
  content: "\ff77f";
}
.game-icon-horn-internal:before {
  content: "\ff780";
}
.game-icon-horned-helm:before {
  content: "\ff781";
}
.game-icon-horned-reptile:before {
  content: "\ff782";
}
.game-icon-horned-skull:before {
  content: "\ff783";
}
.game-icon-horse-head-2:before {
  content: "\ff784";
}
.game-icon-horse-head:before {
  content: "\ff785";
}
.game-icon-horseshoe:before {
  content: "\ff786";
}
.game-icon-horus:before {
  content: "\ff787";
}
.game-icon-hospital-cross:before {
  content: "\ff788";
}
.game-icon-hospital:before {
  content: "\ff789";
}
.game-icon-hot-dog:before {
  content: "\ff78a";
}
.game-icon-hot-meal:before {
  content: "\ff78b";
}
.game-icon-hot-spices:before {
  content: "\ff78c";
}
.game-icon-hot-surface:before {
  content: "\ff78d";
}
.game-icon-hound:before {
  content: "\ff78e";
}
.game-icon-hourglass:before {
  content: "\ff78f";
}
.game-icon-house-keys:before {
  content: "\ff790";
}
.game-icon-house:before {
  content: "\ff791";
}
.game-icon-human-cannonball:before {
  content: "\ff792";
}
.game-icon-human-ear:before {
  content: "\ff793";
}
.game-icon-human-pyramid:before {
  content: "\ff794";
}
.game-icon-human-target:before {
  content: "\ff795";
}
.game-icon-hummingbird:before {
  content: "\ff796";
}
.game-icon-hungary:before {
  content: "\ff797";
}
.game-icon-hunter-eyes:before {
  content: "\ff798";
}
.game-icon-hunting-bolas:before {
  content: "\ff799";
}
.game-icon-hunting-horn:before {
  content: "\ff79a";
}
.game-icon-hut:before {
  content: "\ff79b";
}
.game-icon-huts-village:before {
  content: "\ff79c";
}
.game-icon-hydra-shot:before {
  content: "\ff79d";
}
.game-icon-hydra:before {
  content: "\ff79e";
}
.game-icon-hyena-head:before {
  content: "\ff79f";
}
.game-icon-hypersonic-bolt:before {
  content: "\ff7a0";
}
.game-icon-hypersonic-melon:before {
  content: "\ff7a1";
}
.game-icon-hypodermic-test:before {
  content: "\ff7a2";
}
.game-icon-i-beam:before {
  content: "\ff7a3";
}
.game-icon-i-brick:before {
  content: "\ff7a4";
}
.game-icon-ibis:before {
  content: "\ff7a5";
}
.game-icon-icarus:before {
  content: "\ff7a6";
}
.game-icon-ice-bolt:before {
  content: "\ff7a7";
}
.game-icon-ice-bomb:before {
  content: "\ff7a8";
}
.game-icon-ice-cream-cone:before {
  content: "\ff7a9";
}
.game-icon-ice-cream-scoop:before {
  content: "\ff7aa";
}
.game-icon-ice-cube:before {
  content: "\ff7ab";
}
.game-icon-ice-cubes:before {
  content: "\ff7ac";
}
.game-icon-ice-golem:before {
  content: "\ff7ad";
}
.game-icon-ice-iris:before {
  content: "\ff7ae";
}
.game-icon-ice-pop:before {
  content: "\ff7af";
}
.game-icon-ice-shield:before {
  content: "\ff7b0";
}
.game-icon-ice-skate:before {
  content: "\ff7b1";
}
.game-icon-ice-spear:before {
  content: "\ff7b2";
}
.game-icon-ice-spell-cast:before {
  content: "\ff7b3";
}
.game-icon-iceberg:before {
  content: "\ff7b4";
}
.game-icon-icebergs:before {
  content: "\ff7b5";
}
.game-icon-iceland:before {
  content: "\ff7b6";
}
.game-icon-icicles-aura:before {
  content: "\ff7b7";
}
.game-icon-icicles-fence:before {
  content: "\ff7b8";
}
.game-icon-id-card:before {
  content: "\ff7b9";
}
.game-icon-idea:before {
  content: "\ff7ba";
}
.game-icon-ifrit:before {
  content: "\ff7bb";
}
.game-icon-igloo:before {
  content: "\ff7bc";
}
.game-icon-imbricated-arrows:before {
  content: "\ff7bd";
}
.game-icon-imp-laugh:before {
  content: "\ff7be";
}
.game-icon-imp:before {
  content: "\ff7bf";
}
.game-icon-impact-point:before {
  content: "\ff7c0";
}
.game-icon-imperial-crown:before {
  content: "\ff7c1";
}
.game-icon-implosion:before {
  content: "\ff7c2";
}
.game-icon-imprisoned:before {
  content: "\ff7c3";
}
.game-icon-inauguration:before {
  content: "\ff7c4";
}
.game-icon-incense:before {
  content: "\ff7c5";
}
.game-icon-incisors:before {
  content: "\ff7c6";
}
.game-icon-incoming-rocket:before {
  content: "\ff7c7";
}
.game-icon-incubator:before {
  content: "\ff7c8";
}
.game-icon-india-gate:before {
  content: "\ff7c9";
}
.game-icon-indian-palace:before {
  content: "\ff7ca";
}
.game-icon-inferno-bomb:before {
  content: "\ff7cb";
}
.game-icon-infested-mass:before {
  content: "\ff7cc";
}
.game-icon-infinity:before {
  content: "\ff7cd";
}
.game-icon-info:before {
  content: "\ff7ce";
}
.game-icon-injustice:before {
  content: "\ff7cf";
}
.game-icon-ink-swirl:before {
  content: "\ff7d0";
}
.game-icon-inner-self:before {
  content: "\ff7d1";
}
.game-icon-insect-jaws:before {
  content: "\ff7d2";
}
.game-icon-inspiration:before {
  content: "\ff7d3";
}
.game-icon-interceptor-ship:before {
  content: "\ff7d4";
}
.game-icon-interdiction:before {
  content: "\ff7d5";
}
.game-icon-interlaced-tentacles:before {
  content: "\ff7d6";
}
.game-icon-interleaved-arrows:before {
  content: "\ff7d7";
}
.game-icon-interleaved-claws:before {
  content: "\ff7d8";
}
.game-icon-internal-injury:before {
  content: "\ff7d9";
}
.game-icon-internal-organ:before {
  content: "\ff7da";
}
.game-icon-interstellar-path:before {
  content: "\ff7db";
}
.game-icon-intricate-necklace:before {
  content: "\ff7dc";
}
.game-icon-inverted-dice-1:before {
  content: "\ff7dd";
}
.game-icon-inverted-dice-2:before {
  content: "\ff7de";
}
.game-icon-inverted-dice-3:before {
  content: "\ff7df";
}
.game-icon-inverted-dice-4:before {
  content: "\ff7e0";
}
.game-icon-inverted-dice-5:before {
  content: "\ff7e1";
}
.game-icon-inverted-dice-6:before {
  content: "\ff7e2";
}
.game-icon-invisible-face:before {
  content: "\ff7e3";
}
.game-icon-invisible:before {
  content: "\ff7e4";
}
.game-icon-ion-cannon-blast:before {
  content: "\ff7e5";
}
.game-icon-ionic-column:before {
  content: "\ff7e6";
}
.game-icon-iraq:before {
  content: "\ff7e7";
}
.game-icon-iron-cross:before {
  content: "\ff7e8";
}
.game-icon-iron-hulled-warship:before {
  content: "\ff7e9";
}
.game-icon-iron-mask:before {
  content: "\ff7ea";
}
.game-icon-island:before {
  content: "\ff7eb";
}
.game-icon-italia:before {
  content: "\ff7ec";
}
.game-icon-ivory-tusks:before {
  content: "\ff7ed";
}
.game-icon-j-brick:before {
  content: "\ff7ee";
}
.game-icon-jack-plug:before {
  content: "\ff7ef";
}
.game-icon-james-bond-aperture:before {
  content: "\ff7f0";
}
.game-icon-japan:before {
  content: "\ff7f1";
}
.game-icon-japanese-bridge:before {
  content: "\ff7f2";
}
.game-icon-jasmine:before {
  content: "\ff7f3";
}
.game-icon-jason-mask:before {
  content: "\ff7f4";
}
.game-icon-jawbone:before {
  content: "\ff7f5";
}
.game-icon-jawless-cyclop:before {
  content: "\ff7f6";
}
.game-icon-jeep-2:before {
  content: "\ff7f7";
}
.game-icon-jeep:before {
  content: "\ff7f8";
}
.game-icon-jelly-beans:before {
  content: "\ff7f9";
}
.game-icon-jelly:before {
  content: "\ff7fa";
}
.game-icon-jellyfish:before {
  content: "\ff7fb";
}
.game-icon-jerrycan:before {
  content: "\ff7fc";
}
.game-icon-jerusalem-cross:before {
  content: "\ff7fd";
}
.game-icon-jester-hat:before {
  content: "\ff7fe";
}
.game-icon-jet-fighter:before {
  content: "\ff7ff";
}
.game-icon-jet-pack:before {
  content: "\ff800";
}
.game-icon-jetpack:before {
  content: "\ff801";
}
.game-icon-jewel-crown:before {
  content: "\ff802";
}
.game-icon-jeweled-chalice:before {
  content: "\ff803";
}
.game-icon-jigsaw-box:before {
  content: "\ff804";
}
.game-icon-jigsaw-piece:before {
  content: "\ff805";
}
.game-icon-join:before {
  content: "\ff806";
}
.game-icon-joint:before {
  content: "\ff807";
}
.game-icon-journey:before {
  content: "\ff808";
}
.game-icon-joystick:before {
  content: "\ff809";
}
.game-icon-jug:before {
  content: "\ff80a";
}
.game-icon-juggler:before {
  content: "\ff80b";
}
.game-icon-juggling-clubs:before {
  content: "\ff80c";
}
.game-icon-juggling-seal:before {
  content: "\ff80d";
}
.game-icon-jump-across:before {
  content: "\ff80e";
}
.game-icon-jumping-dog:before {
  content: "\ff80f";
}
.game-icon-jumping-rope:before {
  content: "\ff810";
}
.game-icon-jungle:before {
  content: "\ff811";
}
.game-icon-jupiter:before {
  content: "\ff812";
}
.game-icon-justice-star:before {
  content: "\ff813";
}
.game-icon-kaleidoscope-pearls:before {
  content: "\ff814";
}
.game-icon-kangaroo:before {
  content: "\ff815";
}
.game-icon-katana:before {
  content: "\ff816";
}
.game-icon-kebab-spit:before {
  content: "\ff817";
}
.game-icon-kenku-head:before {
  content: "\ff818";
}
.game-icon-kenya:before {
  content: "\ff819";
}
.game-icon-ketchup:before {
  content: "\ff81a";
}
.game-icon-kevlar-vest:before {
  content: "\ff81b";
}
.game-icon-kevlar:before {
  content: "\ff81c";
}
.game-icon-key-2:before {
  content: "\ff81d";
}
.game-icon-key-card:before {
  content: "\ff81e";
}
.game-icon-key-lock:before {
  content: "\ff81f";
}
.game-icon-key:before {
  content: "\ff820";
}
.game-icon-keyboard:before {
  content: "\ff821";
}
.game-icon-keyring:before {
  content: "\ff822";
}
.game-icon-kick-scooter:before {
  content: "\ff823";
}
.game-icon-kid-slide:before {
  content: "\ff824";
}
.game-icon-kidneys:before {
  content: "\ff825";
}
.game-icon-kimono:before {
  content: "\ff826";
}
.game-icon-kindle:before {
  content: "\ff827";
}
.game-icon-king-ju-mask:before {
  content: "\ff828";
}
.game-icon-king:before {
  content: "\ff829";
}
.game-icon-kitchen-knives:before {
  content: "\ff82a";
}
.game-icon-kitchen-scale:before {
  content: "\ff82b";
}
.game-icon-kitchen-tap:before {
  content: "\ff82c";
}
.game-icon-kite:before {
  content: "\ff82d";
}
.game-icon-kiwi-bird:before {
  content: "\ff82e";
}
.game-icon-kiwi-fruit:before {
  content: "\ff82f";
}
.game-icon-klingon:before {
  content: "\ff830";
}
.game-icon-knapsack:before {
  content: "\ff831";
}
.game-icon-knee-bandage:before {
  content: "\ff832";
}
.game-icon-knee-cap:before {
  content: "\ff833";
}
.game-icon-knee-pad:before {
  content: "\ff834";
}
.game-icon-kneeling:before {
  content: "\ff835";
}
.game-icon-knife-fork:before {
  content: "\ff836";
}
.game-icon-knife-thrust:before {
  content: "\ff837";
}
.game-icon-knight-banner:before {
  content: "\ff838";
}
.game-icon-knocked-out-stars:before {
  content: "\ff839";
}
.game-icon-knockout:before {
  content: "\ff83a";
}
.game-icon-knot:before {
  content: "\ff83b";
}
.game-icon-koala:before {
  content: "\ff83c";
}
.game-icon-koholint-egg:before {
  content: "\ff83d";
}
.game-icon-kraken-tentacle:before {
  content: "\ff83e";
}
.game-icon-kusarigama:before {
  content: "\ff83f";
}
.game-icon-l-brick:before {
  content: "\ff840";
}
.game-icon-lab-coat:before {
  content: "\ff841";
}
.game-icon-labrador-head:before {
  content: "\ff842";
}
.game-icon-ladder:before {
  content: "\ff843";
}
.game-icon-ladders-platform:before {
  content: "\ff844";
}
.game-icon-ladle:before {
  content: "\ff845";
}
.game-icon-ladybug:before {
  content: "\ff846";
}
.game-icon-lamellar:before {
  content: "\ff847";
}
.game-icon-lamprey-mouth:before {
  content: "\ff848";
}
.game-icon-land-mine:before {
  content: "\ff849";
}
.game-icon-lantern-flame:before {
  content: "\ff84a";
}
.game-icon-lantern:before {
  content: "\ff84b";
}
.game-icon-laptop:before {
  content: "\ff84c";
}
.game-icon-large-dress:before {
  content: "\ff84d";
}
.game-icon-large-paint-brush:before {
  content: "\ff84e";
}
.game-icon-large-wound:before {
  content: "\ff84f";
}
.game-icon-laser-blast:before {
  content: "\ff850";
}
.game-icon-laser-burst:before {
  content: "\ff851";
}
.game-icon-laser-gun:before {
  content: "\ff852";
}
.game-icon-laser-precision:before {
  content: "\ff853";
}
.game-icon-laser-sparks:before {
  content: "\ff854";
}
.game-icon-laser-turret:before {
  content: "\ff855";
}
.game-icon-laser-warning:before {
  content: "\ff856";
}
.game-icon-laserburn:before {
  content: "\ff857";
}
.game-icon-lasso:before {
  content: "\ff858";
}
.game-icon-latvia:before {
  content: "\ff859";
}
.game-icon-laurel-crown:before {
  content: "\ff85a";
}
.game-icon-laurels-trophy:before {
  content: "\ff85b";
}
.game-icon-laurels:before {
  content: "\ff85c";
}
.game-icon-lava:before {
  content: "\ff85d";
}
.game-icon-law-star:before {
  content: "\ff85e";
}
.game-icon-layered-armor:before {
  content: "\ff85f";
}
.game-icon-lead-pipe:before {
  content: "\ff860";
}
.game-icon-leaf-skeleton:before {
  content: "\ff861";
}
.game-icon-leaf-swirl:before {
  content: "\ff862";
}
.game-icon-leak:before {
  content: "\ff863";
}
.game-icon-leaky-skull:before {
  content: "\ff864";
}
.game-icon-leapfrog:before {
  content: "\ff865";
}
.game-icon-leather-armor:before {
  content: "\ff866";
}
.game-icon-leather-boot:before {
  content: "\ff867";
}
.game-icon-leather-vest:before {
  content: "\ff868";
}
.game-icon-led:before {
  content: "\ff869";
}
.game-icon-lee-enfield:before {
  content: "\ff86a";
}
.game-icon-leeching-worm:before {
  content: "\ff86b";
}
.game-icon-leek:before {
  content: "\ff86c";
}
.game-icon-leg-armor:before {
  content: "\ff86d";
}
.game-icon-leg:before {
  content: "\ff86e";
}
.game-icon-lemon:before {
  content: "\ff86f";
}
.game-icon-leo:before {
  content: "\ff870";
}
.game-icon-letter-bomb:before {
  content: "\ff871";
}
.game-icon-level-crossing:before {
  content: "\ff872";
}
.game-icon-level-end-flag:before {
  content: "\ff873";
}
.game-icon-level-four-advanced:before {
  content: "\ff874";
}
.game-icon-level-four:before {
  content: "\ff875";
}
.game-icon-level-three-advanced:before {
  content: "\ff876";
}
.game-icon-level-three:before {
  content: "\ff877";
}
.game-icon-level-two-advanced:before {
  content: "\ff878";
}
.game-icon-level-two:before {
  content: "\ff879";
}
.game-icon-lever:before {
  content: "\ff87a";
}
.game-icon-liar:before {
  content: "\ff87b";
}
.game-icon-liberty-wing:before {
  content: "\ff87c";
}
.game-icon-libra:before {
  content: "\ff87d";
}
.game-icon-libya:before {
  content: "\ff87e";
}
.game-icon-life-bar:before {
  content: "\ff87f";
}
.game-icon-life-buoy:before {
  content: "\ff880";
}
.game-icon-life-in-the-balance:before {
  content: "\ff881";
}
.game-icon-life-jacket:before {
  content: "\ff882";
}
.game-icon-life-support:before {
  content: "\ff883";
}
.game-icon-life-tap:before {
  content: "\ff884";
}
.game-icon-lift:before {
  content: "\ff885";
}
.game-icon-light-backpack:before {
  content: "\ff886";
}
.game-icon-light-bulb:before {
  content: "\ff887";
}
.game-icon-light-fighter:before {
  content: "\ff888";
}
.game-icon-light-helm:before {
  content: "\ff889";
}
.game-icon-light-projector:before {
  content: "\ff88a";
}
.game-icon-light-sabers:before {
  content: "\ff88b";
}
.game-icon-light-thorny-triskelion:before {
  content: "\ff88c";
}
.game-icon-lighter:before {
  content: "\ff88d";
}
.game-icon-lighthouse:before {
  content: "\ff88e";
}
.game-icon-lightning-arc:before {
  content: "\ff88f";
}
.game-icon-lightning-bow:before {
  content: "\ff890";
}
.game-icon-lightning-branches:before {
  content: "\ff891";
}
.game-icon-lightning-dissipation:before {
  content: "\ff892";
}
.game-icon-lightning-dome:before {
  content: "\ff893";
}
.game-icon-lightning-electron:before {
  content: "\ff894";
}
.game-icon-lightning-flame:before {
  content: "\ff895";
}
.game-icon-lightning-frequency:before {
  content: "\ff896";
}
.game-icon-lightning-helix:before {
  content: "\ff897";
}
.game-icon-lightning-mask:before {
  content: "\ff898";
}
.game-icon-lightning-saber:before {
  content: "\ff899";
}
.game-icon-lightning-shadow:before {
  content: "\ff89a";
}
.game-icon-lightning-shield:before {
  content: "\ff89b";
}
.game-icon-lightning-shout:before {
  content: "\ff89c";
}
.game-icon-lightning-slashes:before {
  content: "\ff89d";
}
.game-icon-lightning-spanner:before {
  content: "\ff89e";
}
.game-icon-lightning-storm:before {
  content: "\ff89f";
}
.game-icon-lightning-tear:before {
  content: "\ff8a0";
}
.game-icon-lightning-tree:before {
  content: "\ff8a1";
}
.game-icon-lightning-trio:before {
  content: "\ff8a2";
}
.game-icon-lily-pads:before {
  content: "\ff8a3";
}
.game-icon-linden-leaf:before {
  content: "\ff8a4";
}
.game-icon-linked-rings:before {
  content: "\ff8a5";
}
.game-icon-lion:before {
  content: "\ff8a6";
}
.game-icon-lips:before {
  content: "\ff8a7";
}
.game-icon-lipstick:before {
  content: "\ff8a8";
}
.game-icon-liquid-soap:before {
  content: "\ff8a9";
}
.game-icon-lit-candelabra:before {
  content: "\ff8aa";
}
.game-icon-liver:before {
  content: "\ff8ab";
}
.game-icon-lizard-tongue:before {
  content: "\ff8ac";
}
.game-icon-lizardman:before {
  content: "\ff8ad";
}
.game-icon-load:before {
  content: "\ff8ae";
}
.game-icon-lob-arrow:before {
  content: "\ff8af";
}
.game-icon-lock-picking:before {
  content: "\ff8b0";
}
.game-icon-lock-spy:before {
  content: "\ff8b1";
}
.game-icon-locked-box:before {
  content: "\ff8b2";
}
.game-icon-locked-chest:before {
  content: "\ff8b3";
}
.game-icon-locked-door:before {
  content: "\ff8b4";
}
.game-icon-locked-fortress:before {
  content: "\ff8b5";
}
.game-icon-locked-heart:before {
  content: "\ff8b6";
}
.game-icon-lockers:before {
  content: "\ff8b7";
}
.game-icon-lockpicks:before {
  content: "\ff8b8";
}
.game-icon-log:before {
  content: "\ff8b9";
}
.game-icon-logging:before {
  content: "\ff8ba";
}
.game-icon-logic-gate-and:before {
  content: "\ff8bb";
}
.game-icon-logic-gate-nand:before {
  content: "\ff8bc";
}
.game-icon-logic-gate-nor:before {
  content: "\ff8bd";
}
.game-icon-logic-gate-not:before {
  content: "\ff8be";
}
.game-icon-logic-gate-nxor:before {
  content: "\ff8bf";
}
.game-icon-logic-gate-or:before {
  content: "\ff8c0";
}
.game-icon-logic-gate-xor:before {
  content: "\ff8c1";
}
.game-icon-loincloth:before {
  content: "\ff8c2";
}
.game-icon-long-antennae-bug:before {
  content: "\ff8c3";
}
.game-icon-long-legged-spider:before {
  content: "\ff8c4";
}
.game-icon-look-at:before {
  content: "\ff8c5";
}
.game-icon-lorgnette:before {
  content: "\ff8c6";
}
.game-icon-lost-limb:before {
  content: "\ff8c7";
}
.game-icon-lotus-flower:before {
  content: "\ff8c8";
}
.game-icon-lotus:before {
  content: "\ff8c9";
}
.game-icon-louvre-pyramid:before {
  content: "\ff8ca";
}
.game-icon-love-howl:before {
  content: "\ff8cb";
}
.game-icon-love-injection:before {
  content: "\ff8cc";
}
.game-icon-love-letter:before {
  content: "\ff8cd";
}
.game-icon-love-mystery:before {
  content: "\ff8ce";
}
.game-icon-love-song:before {
  content: "\ff8cf";
}
.game-icon-lovers:before {
  content: "\ff8d0";
}
.game-icon-low-tide:before {
  content: "\ff8d1";
}
.game-icon-luchador:before {
  content: "\ff8d2";
}
.game-icon-lucifer-cannon:before {
  content: "\ff8d3";
}
.game-icon-lucky-fisherman:before {
  content: "\ff8d4";
}
.game-icon-luger:before {
  content: "\ff8d5";
}
.game-icon-lunar-module:before {
  content: "\ff8d6";
}
.game-icon-lunar-wand:before {
  content: "\ff8d7";
}
.game-icon-lungs:before {
  content: "\ff8d8";
}
.game-icon-lynx-head:before {
  content: "\ff8d9";
}
.game-icon-lyre:before {
  content: "\ff8da";
}
.game-icon-m3-grease-gun:before {
  content: "\ff8db";
}
.game-icon-mac-10:before {
  content: "\ff8dc";
}
.game-icon-mace-head:before {
  content: "\ff8dd";
}
.game-icon-machete:before {
  content: "\ff8de";
}
.game-icon-machine-gun-magazine:before {
  content: "\ff8df";
}
.game-icon-machine-gun:before {
  content: "\ff8e0";
}
.game-icon-mad-scientist:before {
  content: "\ff8e1";
}
.game-icon-maggot:before {
  content: "\ff8e2";
}
.game-icon-magic-axe:before {
  content: "\ff8e3";
}
.game-icon-magic-broom:before {
  content: "\ff8e4";
}
.game-icon-magic-gate:before {
  content: "\ff8e5";
}
.game-icon-magic-hat:before {
  content: "\ff8e6";
}
.game-icon-magic-lamp:before {
  content: "\ff8e7";
}
.game-icon-magic-palm:before {
  content: "\ff8e8";
}
.game-icon-magic-portal:before {
  content: "\ff8e9";
}
.game-icon-magic-potion:before {
  content: "\ff8ea";
}
.game-icon-magic-shield:before {
  content: "\ff8eb";
}
.game-icon-magic-swirl:before {
  content: "\ff8ec";
}
.game-icon-magic-trident:before {
  content: "\ff8ed";
}
.game-icon-magick-trick:before {
  content: "\ff8ee";
}
.game-icon-magnet-blast:before {
  content: "\ff8ef";
}
.game-icon-magnet-man:before {
  content: "\ff8f0";
}
.game-icon-magnet:before {
  content: "\ff8f1";
}
.game-icon-magnifying-glass:before {
  content: "\ff8f2";
}
.game-icon-mail-shirt:before {
  content: "\ff8f3";
}
.game-icon-mailbox:before {
  content: "\ff8f4";
}
.game-icon-mailed-fist:before {
  content: "\ff8f5";
}
.game-icon-male:before {
  content: "\ff8f6";
}
.game-icon-mammoth:before {
  content: "\ff8f7";
}
.game-icon-manacles:before {
  content: "\ff8f8";
}
.game-icon-mandrill-head:before {
  content: "\ff8f9";
}
.game-icon-manta-ray:before {
  content: "\ff8fa";
}
.game-icon-mantrap:before {
  content: "\ff8fb";
}
.game-icon-manual-juicer:before {
  content: "\ff8fc";
}
.game-icon-manual-meat-grinder:before {
  content: "\ff8fd";
}
.game-icon-maple-leaf:before {
  content: "\ff8fe";
}
.game-icon-maracas:before {
  content: "\ff8ff";
}
.game-icon-marble-tap:before {
  content: "\ff900";
}
.game-icon-marbles:before {
  content: "\ff901";
}
.game-icon-marrow-drain:before {
  content: "\ff902";
}
.game-icon-mars-curiosity:before {
  content: "\ff903";
}
.game-icon-mars-pathfinder:before {
  content: "\ff904";
}
.game-icon-marshmallows:before {
  content: "\ff905";
}
.game-icon-martini:before {
  content: "\ff906";
}
.game-icon-martyr-memorial:before {
  content: "\ff907";
}
.game-icon-masked-spider:before {
  content: "\ff908";
}
.game-icon-mason-jar:before {
  content: "\ff909";
}
.game-icon-mass-driver:before {
  content: "\ff90a";
}
.game-icon-master-of-arms:before {
  content: "\ff90b";
}
.game-icon-match-head:before {
  content: "\ff90c";
}
.game-icon-match-tip:before {
  content: "\ff90d";
}
.game-icon-matchbox:before {
  content: "\ff90e";
}
.game-icon-materials-science:before {
  content: "\ff90f";
}
.game-icon-matryoshka-dolls:before {
  content: "\ff910";
}
.game-icon-matter-states:before {
  content: "\ff911";
}
.game-icon-mayan-pyramid:before {
  content: "\ff912";
}
.game-icon-maze-cornea:before {
  content: "\ff913";
}
.game-icon-maze-saw:before {
  content: "\ff914";
}
.game-icon-maze:before {
  content: "\ff915";
}
.game-icon-meal:before {
  content: "\ff916";
}
.game-icon-measure-tape:before {
  content: "\ff917";
}
.game-icon-meat-cleaver:before {
  content: "\ff918";
}
.game-icon-meat-hook:before {
  content: "\ff919";
}
.game-icon-meat:before {
  content: "\ff91a";
}
.game-icon-mecha-head:before {
  content: "\ff91b";
}
.game-icon-mecha-mask:before {
  content: "\ff91c";
}
.game-icon-mechanic-garage:before {
  content: "\ff91d";
}
.game-icon-mechanical-arm:before {
  content: "\ff91e";
}
.game-icon-medal-skull:before {
  content: "\ff91f";
}
.game-icon-medal:before {
  content: "\ff920";
}
.game-icon-medallist:before {
  content: "\ff921";
}
.game-icon-medical-drip:before {
  content: "\ff922";
}
.game-icon-medical-pack-alt:before {
  content: "\ff923";
}
.game-icon-medical-pack:before {
  content: "\ff924";
}
.game-icon-medical-thermometer:before {
  content: "\ff925";
}
.game-icon-medicine-pills:before {
  content: "\ff926";
}
.game-icon-medicines:before {
  content: "\ff927";
}
.game-icon-medieval-barracks:before {
  content: "\ff928";
}
.game-icon-medieval-gate:before {
  content: "\ff929";
}
.game-icon-medieval-pavilion:before {
  content: "\ff92a";
}
.game-icon-meditation:before {
  content: "\ff92b";
}
.game-icon-medusa-head:before {
  content: "\ff92c";
}
.game-icon-meeple-army:before {
  content: "\ff92d";
}
.game-icon-meeple-circle:before {
  content: "\ff92e";
}
.game-icon-meeple-group:before {
  content: "\ff92f";
}
.game-icon-meeple-king:before {
  content: "\ff930";
}
.game-icon-meeple:before {
  content: "\ff931";
}
.game-icon-megabot:before {
  content: "\ff932";
}
.game-icon-megaphone:before {
  content: "\ff933";
}
.game-icon-melting-ice-cube:before {
  content: "\ff934";
}
.game-icon-melting-metal:before {
  content: "\ff935";
}
.game-icon-menhir:before {
  content: "\ff936";
}
.game-icon-mermaid:before {
  content: "\ff937";
}
.game-icon-mesh-ball:before {
  content: "\ff938";
}
.game-icon-mesh-network:before {
  content: "\ff939";
}
.game-icon-metal-bar:before {
  content: "\ff93a";
}
.game-icon-metal-boot:before {
  content: "\ff93b";
}
.game-icon-metal-detector:before {
  content: "\ff93c";
}
.game-icon-metal-disc:before {
  content: "\ff93d";
}
.game-icon-metal-golem-head:before {
  content: "\ff93e";
}
.game-icon-metal-hand:before {
  content: "\ff93f";
}
.game-icon-metal-plate:before {
  content: "\ff940";
}
.game-icon-metal-scales:before {
  content: "\ff941";
}
.game-icon-metal-skirt:before {
  content: "\ff942";
}
.game-icon-meteor-impact:before {
  content: "\ff943";
}
.game-icon-metroid:before {
  content: "\ff944";
}
.game-icon-metronome:before {
  content: "\ff945";
}
.game-icon-mexico:before {
  content: "\ff946";
}
.game-icon-microchip:before {
  content: "\ff947";
}
.game-icon-microphone:before {
  content: "\ff948";
}
.game-icon-microscope-lens:before {
  content: "\ff949";
}
.game-icon-microscope:before {
  content: "\ff94a";
}
.game-icon-middle-arrow:before {
  content: "\ff94b";
}
.game-icon-midnight-claw:before {
  content: "\ff94c";
}
.game-icon-mighty-boosh:before {
  content: "\ff94d";
}
.game-icon-mighty-force:before {
  content: "\ff94e";
}
.game-icon-mighty-horn:before {
  content: "\ff94f";
}
.game-icon-mighty-spanner:before {
  content: "\ff950";
}
.game-icon-military-ambulance:before {
  content: "\ff951";
}
.game-icon-military-fort:before {
  content: "\ff952";
}
.game-icon-milk-carton:before {
  content: "\ff953";
}
.game-icon-millenium-key:before {
  content: "\ff954";
}
.game-icon-mimic-chest:before {
  content: "\ff955";
}
.game-icon-mine-explosion:before {
  content: "\ff956";
}
.game-icon-mine-truck:before {
  content: "\ff957";
}
.game-icon-mine-wagon:before {
  content: "\ff958";
}
.game-icon-minefield:before {
  content: "\ff959";
}
.game-icon-miner:before {
  content: "\ff95a";
}
.game-icon-mineral-heart:before {
  content: "\ff95b";
}
.game-icon-mineral-pearls:before {
  content: "\ff95c";
}
.game-icon-minerals:before {
  content: "\ff95d";
}
.game-icon-mini-submarine:before {
  content: "\ff95e";
}
.game-icon-minigun:before {
  content: "\ff95f";
}
.game-icon-mining-helmet:before {
  content: "\ff960";
}
.game-icon-mining:before {
  content: "\ff961";
}
.game-icon-minions:before {
  content: "\ff962";
}
.game-icon-minotaur:before {
  content: "\ff963";
}
.game-icon-miracle-medecine:before {
  content: "\ff964";
}
.game-icon-mirror-mirror:before {
  content: "\ff965";
}
.game-icon-misdirection:before {
  content: "\ff966";
}
.game-icon-missile-launcher:before {
  content: "\ff967";
}
.game-icon-missile-mech:before {
  content: "\ff968";
}
.game-icon-missile-pod:before {
  content: "\ff969";
}
.game-icon-missile-swarm:before {
  content: "\ff96a";
}
.game-icon-mite-alt:before {
  content: "\ff96b";
}
.game-icon-mite:before {
  content: "\ff96c";
}
.game-icon-moai:before {
  content: "\ff96d";
}
.game-icon-modern-city:before {
  content: "\ff96e";
}
.game-icon-moebius-star:before {
  content: "\ff96f";
}
.game-icon-moebius-trefoil:before {
  content: "\ff970";
}
.game-icon-moebius-triangle:before {
  content: "\ff971";
}
.game-icon-moka-pot:before {
  content: "\ff972";
}
.game-icon-moldova:before {
  content: "\ff973";
}
.game-icon-molecule-2:before {
  content: "\ff974";
}
.game-icon-molecule:before {
  content: "\ff975";
}
.game-icon-molotov:before {
  content: "\ff976";
}
.game-icon-mona-lisa:before {
  content: "\ff977";
}
.game-icon-moncler-jacket:before {
  content: "\ff978";
}
.game-icon-money-stack:before {
  content: "\ff979";
}
.game-icon-mongolia:before {
  content: "\ff97a";
}
.game-icon-monk-face:before {
  content: "\ff97b";
}
.game-icon-monkey-wrench:before {
  content: "\ff97c";
}
.game-icon-monkey:before {
  content: "\ff97d";
}
.game-icon-mono-wheel-robot:before {
  content: "\ff97e";
}
.game-icon-monster-grasp:before {
  content: "\ff97f";
}
.game-icon-monstera-leaf:before {
  content: "\ff980";
}
.game-icon-monument-valley:before {
  content: "\ff981";
}
.game-icon-moon-bats:before {
  content: "\ff982";
}
.game-icon-moon-claws:before {
  content: "\ff983";
}
.game-icon-moon-orbit:before {
  content: "\ff984";
}
.game-icon-moon:before {
  content: "\ff985";
}
.game-icon-mooring-bollard:before {
  content: "\ff986";
}
.game-icon-morbid-humour:before {
  content: "\ff987";
}
.game-icon-morgue-feet:before {
  content: "\ff988";
}
.game-icon-morph-ball:before {
  content: "\ff989";
}
.game-icon-mortar:before {
  content: "\ff98a";
}
.game-icon-mountain-cave:before {
  content: "\ff98b";
}
.game-icon-mountain-climbing:before {
  content: "\ff98c";
}
.game-icon-mountain-road:before {
  content: "\ff98d";
}
.game-icon-mountains:before {
  content: "\ff98e";
}
.game-icon-mountaintop:before {
  content: "\ff98f";
}
.game-icon-mounted-knight:before {
  content: "\ff990";
}
.game-icon-mouse-2:before {
  content: "\ff991";
}
.game-icon-mouse:before {
  content: "\ff992";
}
.game-icon-mouth-watering:before {
  content: "\ff993";
}
.game-icon-move:before {
  content: "\ff994";
}
.game-icon-movement-sensor:before {
  content: "\ff995";
}
.game-icon-mp-40:before {
  content: "\ff996";
}
.game-icon-mp5-2:before {
  content: "\ff997";
}
.game-icon-mp5:before {
  content: "\ff998";
}
.game-icon-mp5k:before {
  content: "\ff999";
}
.game-icon-mucous-pillar:before {
  content: "\ff99a";
}
.game-icon-mug-shot:before {
  content: "\ff99b";
}
.game-icon-multi-directions:before {
  content: "\ff99c";
}
.game-icon-multiple-targets:before {
  content: "\ff99d";
}
.game-icon-mummy-head:before {
  content: "\ff99e";
}
.game-icon-muscle-fat:before {
  content: "\ff99f";
}
.game-icon-muscle-up:before {
  content: "\ff9a0";
}
.game-icon-muscular-torso:before {
  content: "\ff9a1";
}
.game-icon-mushroom-cloud:before {
  content: "\ff9a2";
}
.game-icon-mushroom-gills:before {
  content: "\ff9a3";
}
.game-icon-mushroom-house:before {
  content: "\ff9a4";
}
.game-icon-mushroom:before {
  content: "\ff9a5";
}
.game-icon-mushrooms-cluster:before {
  content: "\ff9a6";
}
.game-icon-mushrooms:before {
  content: "\ff9a7";
}
.game-icon-music-spell:before {
  content: "\ff9a8";
}
.game-icon-musical-keyboard:before {
  content: "\ff9a9";
}
.game-icon-musical-notes:before {
  content: "\ff9aa";
}
.game-icon-musical-score:before {
  content: "\ff9ab";
}
.game-icon-musket:before {
  content: "\ff9ac";
}
.game-icon-mussel:before {
  content: "\ff9ad";
}
.game-icon-mustache:before {
  content: "\ff9ae";
}
.game-icon-mute:before {
  content: "\ff9af";
}
.game-icon-nachos:before {
  content: "\ff9b0";
}
.game-icon-nailed-foot:before {
  content: "\ff9b1";
}
.game-icon-nailed-head:before {
  content: "\ff9b2";
}
.game-icon-nails:before {
  content: "\ff9b3";
}
.game-icon-nano-bot:before {
  content: "\ff9b4";
}
.game-icon-nautilus-shell:before {
  content: "\ff9b5";
}
.game-icon-neck-bite:before {
  content: "\ff9b6";
}
.game-icon-necklace-display:before {
  content: "\ff9b7";
}
.game-icon-necklace:before {
  content: "\ff9b8";
}
.game-icon-nectar:before {
  content: "\ff9b9";
}
.game-icon-needle-drill:before {
  content: "\ff9ba";
}
.game-icon-needle-jaws:before {
  content: "\ff9bb";
}
.game-icon-nefertiti:before {
  content: "\ff9bc";
}
.game-icon-nest-birds:before {
  content: "\ff9bd";
}
.game-icon-nest-eggs:before {
  content: "\ff9be";
}
.game-icon-nested-eclipses:before {
  content: "\ff9bf";
}
.game-icon-nested-hearts:before {
  content: "\ff9c0";
}
.game-icon-nested-hexagons:before {
  content: "\ff9c1";
}
.game-icon-network-bars:before {
  content: "\ff9c2";
}
.game-icon-new-born:before {
  content: "\ff9c3";
}
.game-icon-new-shoot:before {
  content: "\ff9c4";
}
.game-icon-newspaper:before {
  content: "\ff9c5";
}
.game-icon-next-button:before {
  content: "\ff9c6";
}
.game-icon-nigeria:before {
  content: "\ff9c7";
}
.game-icon-night-sky:before {
  content: "\ff9c8";
}
.game-icon-night-sleep:before {
  content: "\ff9c9";
}
.game-icon-night-vision:before {
  content: "\ff9ca";
}
.game-icon-ninja-armor:before {
  content: "\ff9cb";
}
.game-icon-ninja-head:before {
  content: "\ff9cc";
}
.game-icon-ninja-heroic-stance:before {
  content: "\ff9cd";
}
.game-icon-ninja-mask:before {
  content: "\ff9ce";
}
.game-icon-ninja-star:before {
  content: "\ff9cf";
}
.game-icon-ninja-velociraptor:before {
  content: "\ff9d0";
}
.game-icon-nodular:before {
  content: "\ff9d1";
}
.game-icon-noodle-ball:before {
  content: "\ff9d2";
}
.game-icon-noodles:before {
  content: "\ff9d3";
}
.game-icon-north-star-shuriken:before {
  content: "\ff9d4";
}
.game-icon-nose-front:before {
  content: "\ff9d5";
}
.game-icon-nose-side:before {
  content: "\ff9d6";
}
.game-icon-notebook:before {
  content: "\ff9d7";
}
.game-icon-nothing-to-say:before {
  content: "\ff9d8";
}
.game-icon-nuclear-bomb:before {
  content: "\ff9d9";
}
.game-icon-nuclear-plant:before {
  content: "\ff9da";
}
.game-icon-nuclear-waste:before {
  content: "\ff9db";
}
.game-icon-nuclear:before {
  content: "\ff9dc";
}
.game-icon-nun-face:before {
  content: "\ff9dd";
}
.game-icon-nunchaku:before {
  content: "\ff9de";
}
.game-icon-nurse-female:before {
  content: "\ff9df";
}
.game-icon-nurse-male:before {
  content: "\ff9e0";
}
.game-icon-o-brick:before {
  content: "\ff9e1";
}
.game-icon-oak-leaf:before {
  content: "\ff9e2";
}
.game-icon-oak:before {
  content: "\ff9e3";
}
.game-icon-oasis:before {
  content: "\ff9e4";
}
.game-icon-oat:before {
  content: "\ff9e5";
}
.game-icon-obelisk:before {
  content: "\ff9e6";
}
.game-icon-observatory:before {
  content: "\ff9e7";
}
.game-icon-ocarina:before {
  content: "\ff9e8";
}
.game-icon-occupy:before {
  content: "\ff9e9";
}
.game-icon-octogonal-eye:before {
  content: "\ff9ea";
}
.game-icon-octoman:before {
  content: "\ff9eb";
}
.game-icon-octopus:before {
  content: "\ff9ec";
}
.game-icon-oden:before {
  content: "\ff9ed";
}
.game-icon-office-chair:before {
  content: "\ff9ee";
}
.game-icon-offshore-platform:before {
  content: "\ff9ef";
}
.game-icon-ogre:before {
  content: "\ff9f0";
}
.game-icon-oil-drum:before {
  content: "\ff9f1";
}
.game-icon-oil-pump:before {
  content: "\ff9f2";
}
.game-icon-oil-rig:before {
  content: "\ff9f3";
}
.game-icon-oily-spiral:before {
  content: "\ff9f4";
}
.game-icon-old-king:before {
  content: "\ff9f5";
}
.game-icon-old-lantern:before {
  content: "\ff9f6";
}
.game-icon-old-microphone:before {
  content: "\ff9f7";
}
.game-icon-old-wagon:before {
  content: "\ff9f8";
}
.game-icon-olive:before {
  content: "\ff9f9";
}
.game-icon-omega:before {
  content: "\ff9fa";
}
.game-icon-on-sight:before {
  content: "\ff9fb";
}
.game-icon-on-target:before {
  content: "\ff9fc";
}
.game-icon-one-eyed:before {
  content: "\ff9fd";
}
.game-icon-oni:before {
  content: "\ff9fe";
}
.game-icon-onigori:before {
  content: "\ff9ff";
}
.game-icon-open-book:before {
  content: "\ffa00";
}
.game-icon-open-chest:before {
  content: "\ffa01";
}
.game-icon-open-folder:before {
  content: "\ffa02";
}
.game-icon-open-gate:before {
  content: "\ffa03";
}
.game-icon-open-palm:before {
  content: "\ffa04";
}
.game-icon-open-treasure-chest:before {
  content: "\ffa05";
}
.game-icon-open-wound:before {
  content: "\ffa06";
}
.game-icon-opened-food-can:before {
  content: "\ffa07";
}
.game-icon-opening-shell:before {
  content: "\ffa08";
}
.game-icon-ophiuchus:before {
  content: "\ffa09";
}
.game-icon-oppidum:before {
  content: "\ffa0a";
}
.game-icon-opposite-hearts:before {
  content: "\ffa0b";
}
.game-icon-oppression:before {
  content: "\ffa0c";
}
.game-icon-orange-slice:before {
  content: "\ffa0d";
}
.game-icon-orange:before {
  content: "\ffa0e";
}
.game-icon-orb-direction:before {
  content: "\ffa0f";
}
.game-icon-orb-wand:before {
  content: "\ffa10";
}
.game-icon-orbit:before {
  content: "\ffa11";
}
.game-icon-orbital-rays:before {
  content: "\ffa12";
}
.game-icon-orbital:before {
  content: "\ffa13";
}
.game-icon-orc-head:before {
  content: "\ffa14";
}
.game-icon-ore:before {
  content: "\ffa15";
}
.game-icon-organigram:before {
  content: "\ffa16";
}
.game-icon-ostrich:before {
  content: "\ffa17";
}
.game-icon-ouroboros:before {
  content: "\ffa18";
}
.game-icon-outback-hat:before {
  content: "\ffa19";
}
.game-icon-over-infinity:before {
  content: "\ffa1a";
}
.game-icon-overdose:before {
  content: "\ffa1b";
}
.game-icon-overdrive:before {
  content: "\ffa1c";
}
.game-icon-overhead:before {
  content: "\ffa1d";
}
.game-icon-overkill:before {
  content: "\ffa1e";
}
.game-icon-overlord-helm:before {
  content: "\ffa1f";
}
.game-icon-overmind:before {
  content: "\ffa20";
}
.game-icon-owl:before {
  content: "\ffa21";
}
.game-icon-oyster-pearl:before {
  content: "\ffa22";
}
.game-icon-oyster:before {
  content: "\ffa23";
}
.game-icon-p90:before {
  content: "\ffa24";
}
.game-icon-packed-planks:before {
  content: "\ffa25";
}
.game-icon-paddle-steamer:before {
  content: "\ffa26";
}
.game-icon-paddles:before {
  content: "\ffa27";
}
.game-icon-padlock-open:before {
  content: "\ffa28";
}
.game-icon-padlock:before {
  content: "\ffa29";
}
.game-icon-pagoda:before {
  content: "\ffa2a";
}
.game-icon-paint-brush:before {
  content: "\ffa2b";
}
.game-icon-paint-bucket:before {
  content: "\ffa2c";
}
.game-icon-paint-roller:before {
  content: "\ffa2d";
}
.game-icon-painted-pottery:before {
  content: "\ffa2e";
}
.game-icon-palette:before {
  content: "\ffa2f";
}
.game-icon-palisade:before {
  content: "\ffa30";
}
.game-icon-palm-tree:before {
  content: "\ffa31";
}
.game-icon-palm:before {
  content: "\ffa32";
}
.game-icon-pan-flute:before {
  content: "\ffa33";
}
.game-icon-panda:before {
  content: "\ffa34";
}
.game-icon-pangolin:before {
  content: "\ffa35";
}
.game-icon-panzerfaust:before {
  content: "\ffa36";
}
.game-icon-paper-arrow:before {
  content: "\ffa37";
}
.game-icon-paper-bag-crumpled:before {
  content: "\ffa38";
}
.game-icon-paper-bag-folded:before {
  content: "\ffa39";
}
.game-icon-paper-bag-open:before {
  content: "\ffa3a";
}
.game-icon-paper-boat:before {
  content: "\ffa3b";
}
.game-icon-paper-bomb:before {
  content: "\ffa3c";
}
.game-icon-paper-clip:before {
  content: "\ffa3d";
}
.game-icon-paper-crane:before {
  content: "\ffa3e";
}
.game-icon-paper-frog:before {
  content: "\ffa3f";
}
.game-icon-paper-lantern:before {
  content: "\ffa40";
}
.game-icon-paper-plane:before {
  content: "\ffa41";
}
.game-icon-paper-tray:before {
  content: "\ffa42";
}
.game-icon-paper-windmill:before {
  content: "\ffa43";
}
.game-icon-paper:before {
  content: "\ffa44";
}
.game-icon-papers:before {
  content: "\ffa45";
}
.game-icon-papyrus:before {
  content: "\ffa46";
}
.game-icon-parachute:before {
  content: "\ffa47";
}
.game-icon-paraguay:before {
  content: "\ffa48";
}
.game-icon-paranoia:before {
  content: "\ffa49";
}
.game-icon-parasaurolophus:before {
  content: "\ffa4a";
}
.game-icon-park-bench:before {
  content: "\ffa4b";
}
.game-icon-parmecia:before {
  content: "\ffa4c";
}
.game-icon-parrot-head:before {
  content: "\ffa4d";
}
.game-icon-party-flags:before {
  content: "\ffa4e";
}
.game-icon-party-hat:before {
  content: "\ffa4f";
}
.game-icon-party-popper:before {
  content: "\ffa50";
}
.game-icon-passport:before {
  content: "\ffa51";
}
.game-icon-path-distance:before {
  content: "\ffa52";
}
.game-icon-path-tile:before {
  content: "\ffa53";
}
.game-icon-pauldrons:before {
  content: "\ffa54";
}
.game-icon-pause-button:before {
  content: "\ffa55";
}
.game-icon-paw-front:before {
  content: "\ffa56";
}
.game-icon-paw-heart:before {
  content: "\ffa57";
}
.game-icon-paw-print:before {
  content: "\ffa58";
}
.game-icon-paw:before {
  content: "\ffa59";
}
.game-icon-pawn:before {
  content: "\ffa5a";
}
.game-icon-pawprint:before {
  content: "\ffa5b";
}
.game-icon-pay-money:before {
  content: "\ffa5c";
}
.game-icon-pc:before {
  content: "\ffa5d";
}
.game-icon-peace-dove:before {
  content: "\ffa5e";
}
.game-icon-peach:before {
  content: "\ffa5f";
}
.game-icon-peaks:before {
  content: "\ffa60";
}
.game-icon-peanut:before {
  content: "\ffa61";
}
.game-icon-pear:before {
  content: "\ffa62";
}
.game-icon-pearl-earring:before {
  content: "\ffa63";
}
.game-icon-pearl-necklace:before {
  content: "\ffa64";
}
.game-icon-peas:before {
  content: "\ffa65";
}
.game-icon-pegasus:before {
  content: "\ffa66";
}
.game-icon-pelvis-bone:before {
  content: "\ffa67";
}
.game-icon-pencil-brush:before {
  content: "\ffa68";
}
.game-icon-pencil-ruler:before {
  content: "\ffa69";
}
.game-icon-pencil:before {
  content: "\ffa6a";
}
.game-icon-pendant-key:before {
  content: "\ffa6b";
}
.game-icon-pendulum-swing:before {
  content: "\ffa6c";
}
.game-icon-penguin:before {
  content: "\ffa6d";
}
.game-icon-pentacle:before {
  content: "\ffa6e";
}
.game-icon-pentagram-rose:before {
  content: "\ffa6f";
}
.game-icon-pentarrows-tornado:before {
  content: "\ffa70";
}
.game-icon-perfume-bottle:before {
  content: "\ffa71";
}
.game-icon-periscope:before {
  content: "\ffa72";
}
.game-icon-perpendicular-rings:before {
  content: "\ffa73";
}
.game-icon-person-in-bed:before {
  content: "\ffa74";
}
.game-icon-person:before {
  content: "\ffa75";
}
.game-icon-perspective-dice-five:before {
  content: "\ffa76";
}
.game-icon-perspective-dice-four:before {
  content: "\ffa77";
}
.game-icon-perspective-dice-one:before {
  content: "\ffa78";
}
.game-icon-perspective-dice-six-faces-five:before {
  content: "\ffa79";
}
.game-icon-perspective-dice-six-faces-four:before {
  content: "\ffa7a";
}
.game-icon-perspective-dice-six-faces-one:before {
  content: "\ffa7b";
}
.game-icon-perspective-dice-six-faces-random:before {
  content: "\ffa7c";
}
.game-icon-perspective-dice-six-faces-six:before {
  content: "\ffa7d";
}
.game-icon-perspective-dice-six-faces-three:before {
  content: "\ffa7e";
}
.game-icon-perspective-dice-six-faces-two:before {
  content: "\ffa7f";
}
.game-icon-perspective-dice-six:before {
  content: "\ffa80";
}
.game-icon-perspective-dice-three:before {
  content: "\ffa81";
}
.game-icon-perspective-dice-two:before {
  content: "\ffa82";
}
.game-icon-peru:before {
  content: "\ffa83";
}
.game-icon-pestle-mortar:before {
  content: "\ffa84";
}
.game-icon-pharoah:before {
  content: "\ffa85";
}
.game-icon-philosopher-bust:before {
  content: "\ffa86";
}
.game-icon-phone:before {
  content: "\ffa87";
}
.game-icon-photo-camera:before {
  content: "\ffa88";
}
.game-icon-phrygian-cap:before {
  content: "\ffa89";
}
.game-icon-pianist:before {
  content: "\ffa8a";
}
.game-icon-piano-keys:before {
  content: "\ffa8b";
}
.game-icon-pick-of-destiny:before {
  content: "\ffa8c";
}
.game-icon-pickelhaube:before {
  content: "\ffa8d";
}
.game-icon-pickle:before {
  content: "\ffa8e";
}
.game-icon-pie-chart:before {
  content: "\ffa8f";
}
.game-icon-pie-slice:before {
  content: "\ffa90";
}
.game-icon-piece-skull:before {
  content: "\ffa91";
}
.game-icon-pierced-body:before {
  content: "\ffa92";
}
.game-icon-pierced-heart:before {
  content: "\ffa93";
}
.game-icon-piercing-sword:before {
  content: "\ffa94";
}
.game-icon-pig-face:before {
  content: "\ffa95";
}
.game-icon-pig:before {
  content: "\ffa96";
}
.game-icon-piggy-bank:before {
  content: "\ffa97";
}
.game-icon-pikeman:before {
  content: "\ffa98";
}
.game-icon-pilgrim-hat:before {
  content: "\ffa99";
}
.game-icon-pill-drop:before {
  content: "\ffa9a";
}
.game-icon-pill:before {
  content: "\ffa9b";
}
.game-icon-pillow:before {
  content: "\ffa9c";
}
.game-icon-pimiento:before {
  content: "\ffa9d";
}
.game-icon-pin:before {
  content: "\ffa9e";
}
.game-icon-pinata:before {
  content: "\ffa9f";
}
.game-icon-pinball-flipper:before {
  content: "\ffaa0";
}
.game-icon-pincers:before {
  content: "\ffaa1";
}
.game-icon-pine-tree:before {
  content: "\ffaa2";
}
.game-icon-pineapple:before {
  content: "\ffaa3";
}
.game-icon-ping-pong-bat:before {
  content: "\ffaa4";
}
.game-icon-pipe-organ:before {
  content: "\ffaa5";
}
.game-icon-pipes:before {
  content: "\ffaa6";
}
.game-icon-piranha:before {
  content: "\ffaa7";
}
.game-icon-pirate-cannon:before {
  content: "\ffaa8";
}
.game-icon-pirate-captain:before {
  content: "\ffaa9";
}
.game-icon-pirate-coat:before {
  content: "\ffaaa";
}
.game-icon-pirate-flag:before {
  content: "\ffaab";
}
.game-icon-pirate-grave:before {
  content: "\ffaac";
}
.game-icon-pirate-hat:before {
  content: "\ffaad";
}
.game-icon-pirate-hook:before {
  content: "\ffaae";
}
.game-icon-pirate-skull:before {
  content: "\ffaaf";
}
.game-icon-pisa-tower:before {
  content: "\ffab0";
}
.game-icon-pisces:before {
  content: "\ffab1";
}
.game-icon-pistol-gun:before {
  content: "\ffab2";
}
.game-icon-pitchfork:before {
  content: "\ffab3";
}
.game-icon-pizza-cutter:before {
  content: "\ffab4";
}
.game-icon-pizza-slice:before {
  content: "\ffab5";
}
.game-icon-plague-doctor-profile:before {
  content: "\ffab6";
}
.game-icon-plain-arrow:before {
  content: "\ffab7";
}
.game-icon-plain-circle:before {
  content: "\ffab8";
}
.game-icon-plain-dagger:before {
  content: "\ffab9";
}
.game-icon-plain-square:before {
  content: "\ffaba";
}
.game-icon-plane-pilot:before {
  content: "\ffabb";
}
.game-icon-plane-wing:before {
  content: "\ffabc";
}
.game-icon-planet-conquest:before {
  content: "\ffabd";
}
.game-icon-planet-core:before {
  content: "\ffabe";
}
.game-icon-planks:before {
  content: "\ffabf";
}
.game-icon-plant-roots:before {
  content: "\ffac0";
}
.game-icon-plant-seed:before {
  content: "\ffac1";
}
.game-icon-plant-watering:before {
  content: "\ffac2";
}
.game-icon-plants-and-animals:before {
  content: "\ffac3";
}
.game-icon-plasma-bolt:before {
  content: "\ffac4";
}
.game-icon-plastic-duck:before {
  content: "\ffac5";
}
.game-icon-plastron:before {
  content: "\ffac6";
}
.game-icon-plate-claw:before {
  content: "\ffac7";
}
.game-icon-platform:before {
  content: "\ffac8";
}
.game-icon-play-button:before {
  content: "\ffac9";
}
.game-icon-player-base:before {
  content: "\ffaca";
}
.game-icon-player-next:before {
  content: "\ffacb";
}
.game-icon-player-previous:before {
  content: "\ffacc";
}
.game-icon-player-time:before {
  content: "\ffacd";
}
.game-icon-plesiosaurus:before {
  content: "\fface";
}
.game-icon-plow:before {
  content: "\ffacf";
}
.game-icon-plug:before {
  content: "\ffad0";
}
.game-icon-plunger:before {
  content: "\ffad1";
}
.game-icon-pocket-bow:before {
  content: "\ffad2";
}
.game-icon-pocket-radio:before {
  content: "\ffad3";
}
.game-icon-pocket-watch:before {
  content: "\ffad4";
}
.game-icon-podium-second:before {
  content: "\ffad5";
}
.game-icon-podium-third:before {
  content: "\ffad6";
}
.game-icon-podium-winner:before {
  content: "\ffad7";
}
.game-icon-podium:before {
  content: "\ffad8";
}
.game-icon-pointing:before {
  content: "\ffad9";
}
.game-icon-pointy-hat:before {
  content: "\ffada";
}
.game-icon-pointy-sword:before {
  content: "\ffadb";
}
.game-icon-poison-bottle:before {
  content: "\ffadc";
}
.game-icon-poison-cloud:before {
  content: "\ffadd";
}
.game-icon-poison-gas:before {
  content: "\ffade";
}
.game-icon-poison:before {
  content: "\ffadf";
}
.game-icon-pokecog:before {
  content: "\ffae0";
}
.game-icon-poker-hand:before {
  content: "\ffae1";
}
.game-icon-poland:before {
  content: "\ffae2";
}
.game-icon-polar-bear:before {
  content: "\ffae3";
}
.game-icon-polar-star:before {
  content: "\ffae4";
}
.game-icon-police-badge:before {
  content: "\ffae5";
}
.game-icon-police-car:before {
  content: "\ffae6";
}
.game-icon-police-officer-head:before {
  content: "\ffae7";
}
.game-icon-police-target:before {
  content: "\ffae8";
}
.game-icon-pollen-dust:before {
  content: "\ffae9";
}
.game-icon-polo-shirt:before {
  content: "\ffaea";
}
.game-icon-poncho:before {
  content: "\ffaeb";
}
.game-icon-pool-dive:before {
  content: "\ffaec";
}
.game-icon-pool-table-corner:before {
  content: "\ffaed";
}
.game-icon-pool-triangle:before {
  content: "\ffaee";
}
.game-icon-popcorn:before {
  content: "\ffaef";
}
.game-icon-pope-crown:before {
  content: "\ffaf0";
}
.game-icon-poppy:before {
  content: "\ffaf1";
}
.game-icon-porcelain-vase:before {
  content: "\ffaf2";
}
.game-icon-porcupine:before {
  content: "\ffaf3";
}
.game-icon-porcupinefish:before {
  content: "\ffaf4";
}
.game-icon-portal:before {
  content: "\ffaf5";
}
.game-icon-portculis:before {
  content: "\ffaf6";
}
.game-icon-portrait:before {
  content: "\ffaf7";
}
.game-icon-portugal:before {
  content: "\ffaf8";
}
.game-icon-position-marker:before {
  content: "\ffaf9";
}
.game-icon-post-office:before {
  content: "\ffafa";
}
.game-icon-post-stamp:before {
  content: "\ffafb";
}
.game-icon-potato:before {
  content: "\ffafc";
}
.game-icon-potion-ball:before {
  content: "\ffafd";
}
.game-icon-potion-of-madness:before {
  content: "\ffafe";
}
.game-icon-pounce:before {
  content: "\ffaff";
}
.game-icon-pouring-chalice:before {
  content: "\ffb00";
}
.game-icon-pouring-pot:before {
  content: "\ffb01";
}
.game-icon-powder-bag:before {
  content: "\ffb02";
}
.game-icon-powder:before {
  content: "\ffb03";
}
.game-icon-power-button:before {
  content: "\ffb04";
}
.game-icon-power-generator:before {
  content: "\ffb05";
}
.game-icon-power-lightning:before {
  content: "\ffb06";
}
.game-icon-power-ring:before {
  content: "\ffb07";
}
.game-icon-prayer-beads:before {
  content: "\ffb08";
}
.game-icon-prayer:before {
  content: "\ffb09";
}
.game-icon-praying-mantis:before {
  content: "\ffb0a";
}
.game-icon-present:before {
  content: "\ffb0b";
}
.game-icon-pressure-cooker:before {
  content: "\ffb0c";
}
.game-icon-pretty-fangs:before {
  content: "\ffb0d";
}
.game-icon-pretzel:before {
  content: "\ffb0e";
}
.game-icon-previous-button:before {
  content: "\ffb0f";
}
.game-icon-price-tag:before {
  content: "\ffb10";
}
.game-icon-primitive-necklace:before {
  content: "\ffb11";
}
.game-icon-primitive-torch:before {
  content: "\ffb12";
}
.game-icon-prism:before {
  content: "\ffb13";
}
.game-icon-prisoner:before {
  content: "\ffb14";
}
.game-icon-private-first-class:before {
  content: "\ffb15";
}
.game-icon-private:before {
  content: "\ffb16";
}
.game-icon-processor:before {
  content: "\ffb17";
}
.game-icon-profit:before {
  content: "\ffb18";
}
.game-icon-progression:before {
  content: "\ffb19";
}
.game-icon-propeller-beanie:before {
  content: "\ffb1a";
}
.game-icon-protection-glasses:before {
  content: "\ffb1b";
}
.game-icon-pschent-double-crown:before {
  content: "\ffb1c";
}
.game-icon-psychic-waves:before {
  content: "\ffb1d";
}
.game-icon-pterodactylus:before {
  content: "\ffb1e";
}
.game-icon-pteruges:before {
  content: "\ffb1f";
}
.game-icon-public-speaker:before {
  content: "\ffb20";
}
.game-icon-pull:before {
  content: "\ffb21";
}
.game-icon-pulley-hook:before {
  content: "\ffb22";
}
.game-icon-pulse:before {
  content: "\ffb23";
}
.game-icon-pummeled:before {
  content: "\ffb24";
}
.game-icon-pumpkin-lantern:before {
  content: "\ffb25";
}
.game-icon-pumpkin-mask:before {
  content: "\ffb26";
}
.game-icon-pumpkin:before {
  content: "\ffb27";
}
.game-icon-punch-blast:before {
  content: "\ffb28";
}
.game-icon-punch:before {
  content: "\ffb29";
}
.game-icon-punching-bag:before {
  content: "\ffb2a";
}
.game-icon-puppet:before {
  content: "\ffb2b";
}
.game-icon-purple-tentacle:before {
  content: "\ffb2c";
}
.game-icon-push:before {
  content: "\ffb2d";
}
.game-icon-puzzle:before {
  content: "\ffb2e";
}
.game-icon-pylon:before {
  content: "\ffb2f";
}
.game-icon-pyre:before {
  content: "\ffb30";
}
.game-icon-pyromaniac:before {
  content: "\ffb31";
}
.game-icon-qaitbay-citadel:before {
  content: "\ffb32";
}
.game-icon-quake-stomp:before {
  content: "\ffb33";
}
.game-icon-queen-crown:before {
  content: "\ffb34";
}
.game-icon-quick-man:before {
  content: "\ffb35";
}
.game-icon-quick-slash:before {
  content: "\ffb36";
}
.game-icon-quicksand:before {
  content: "\ffb37";
}
.game-icon-quill-ink:before {
  content: "\ffb38";
}
.game-icon-quill:before {
  content: "\ffb39";
}
.game-icon-quiver:before {
  content: "\ffb3a";
}
.game-icon-rabbit-head:before {
  content: "\ffb3b";
}
.game-icon-rabbit:before {
  content: "\ffb3c";
}
.game-icon-raccoon-head:before {
  content: "\ffb3d";
}
.game-icon-race-car:before {
  content: "\ffb3e";
}
.game-icon-radar-cross-section:before {
  content: "\ffb3f";
}
.game-icon-radar-dish:before {
  content: "\ffb40";
}
.game-icon-radar-sweep:before {
  content: "\ffb41";
}
.game-icon-raddish:before {
  content: "\ffb42";
}
.game-icon-radial-balance:before {
  content: "\ffb43";
}
.game-icon-radiations:before {
  content: "\ffb44";
}
.game-icon-radio-tower:before {
  content: "\ffb45";
}
.game-icon-radioactive:before {
  content: "\ffb46";
}
.game-icon-raft:before {
  content: "\ffb47";
}
.game-icon-ragged-wound:before {
  content: "\ffb48";
}
.game-icon-rail-road:before {
  content: "\ffb49";
}
.game-icon-railway:before {
  content: "\ffb4a";
}
.game-icon-rainbow-star:before {
  content: "\ffb4b";
}
.game-icon-raining:before {
  content: "\ffb4c";
}
.game-icon-raise-skeleton:before {
  content: "\ffb4d";
}
.game-icon-raise-zombie:before {
  content: "\ffb4e";
}
.game-icon-rake:before {
  content: "\ffb4f";
}
.game-icon-rally-the-troops:before {
  content: "\ffb50";
}
.game-icon-ram-2:before {
  content: "\ffb51";
}
.game-icon-ram-profile:before {
  content: "\ffb52";
}
.game-icon-ram:before {
  content: "\ffb53";
}
.game-icon-ranch-gate:before {
  content: "\ffb54";
}
.game-icon-rank-1:before {
  content: "\ffb55";
}
.game-icon-rank-2:before {
  content: "\ffb56";
}
.game-icon-rank-3:before {
  content: "\ffb57";
}
.game-icon-rapidshare-arrow:before {
  content: "\ffb58";
}
.game-icon-raspberry:before {
  content: "\ffb59";
}
.game-icon-rat:before {
  content: "\ffb5a";
}
.game-icon-rattlesnake:before {
  content: "\ffb5b";
}
.game-icon-raven:before {
  content: "\ffb5c";
}
.game-icon-raw-egg:before {
  content: "\ffb5d";
}
.game-icon-ray-gun:before {
  content: "\ffb5e";
}
.game-icon-razor-blade:before {
  content: "\ffb5f";
}
.game-icon-razor:before {
  content: "\ffb60";
}
.game-icon-reactor:before {
  content: "\ffb61";
}
.game-icon-read:before {
  content: "\ffb62";
}
.game-icon-reaper-scythe:before {
  content: "\ffb63";
}
.game-icon-rear-aura:before {
  content: "\ffb64";
}
.game-icon-receive-money:before {
  content: "\ffb65";
}
.game-icon-recycle:before {
  content: "\ffb66";
}
.game-icon-red-carpet:before {
  content: "\ffb67";
}
.game-icon-reed:before {
  content: "\ffb68";
}
.game-icon-refinery:before {
  content: "\ffb69";
}
.game-icon-regeneration:before {
  content: "\ffb6a";
}
.game-icon-relationship-bounds:before {
  content: "\ffb6b";
}
.game-icon-relic-blade:before {
  content: "\ffb6c";
}
.game-icon-reload-gun-barrel:before {
  content: "\ffb6d";
}
.game-icon-remedy:before {
  content: "\ffb6e";
}
.game-icon-rempart:before {
  content: "\ffb6f";
}
.game-icon-reptile-tail:before {
  content: "\ffb70";
}
.game-icon-resize:before {
  content: "\ffb71";
}
.game-icon-resonance:before {
  content: "\ffb72";
}
.game-icon-resting-vampire:before {
  content: "\ffb73";
}
.game-icon-reticule:before {
  content: "\ffb74";
}
.game-icon-retro-controller:before {
  content: "\ffb75";
}
.game-icon-return-arrow:before {
  content: "\ffb76";
}
.game-icon-revolt-2:before {
  content: "\ffb77";
}
.game-icon-revolt:before {
  content: "\ffb78";
}
.game-icon-revolver-2:before {
  content: "\ffb79";
}
.game-icon-revolver:before {
  content: "\ffb7a";
}
.game-icon-rhinoceros-horn:before {
  content: "\ffb7b";
}
.game-icon-rialto-bridge:before {
  content: "\ffb7c";
}
.game-icon-ribbon-medal:before {
  content: "\ffb7d";
}
.game-icon-ribbon-shield:before {
  content: "\ffb7e";
}
.game-icon-ribbon:before {
  content: "\ffb7f";
}
.game-icon-ribcage:before {
  content: "\ffb80";
}
.game-icon-rice-cooker:before {
  content: "\ffb81";
}
.game-icon-rifle:before {
  content: "\ffb82";
}
.game-icon-ring-box:before {
  content: "\ffb83";
}
.game-icon-ring-mould:before {
  content: "\ffb84";
}
.game-icon-ring:before {
  content: "\ffb85";
}
.game-icon-ringed-beam:before {
  content: "\ffb86";
}
.game-icon-ringed-planet:before {
  content: "\ffb87";
}
.game-icon-ringing-alarm:before {
  content: "\ffb88";
}
.game-icon-ringing-bell:before {
  content: "\ffb89";
}
.game-icon-ringmaster:before {
  content: "\ffb8a";
}
.game-icon-riot-shield:before {
  content: "\ffb8b";
}
.game-icon-riposte:before {
  content: "\ffb8c";
}
.game-icon-river:before {
  content: "\ffb8d";
}
.game-icon-road:before {
  content: "\ffb8e";
}
.game-icon-roast-chicken:before {
  content: "\ffb8f";
}
.game-icon-robber-hand:before {
  content: "\ffb90";
}
.game-icon-robber-mask:before {
  content: "\ffb91";
}
.game-icon-robber:before {
  content: "\ffb92";
}
.game-icon-robe:before {
  content: "\ffb93";
}
.game-icon-robin-hood-hat:before {
  content: "\ffb94";
}
.game-icon-robot-antennas:before {
  content: "\ffb95";
}
.game-icon-robot-golem:before {
  content: "\ffb96";
}
.game-icon-robot-grab:before {
  content: "\ffb97";
}
.game-icon-robot-helmet:before {
  content: "\ffb98";
}
.game-icon-robot-leg:before {
  content: "\ffb99";
}
.game-icon-rock-2:before {
  content: "\ffb9a";
}
.game-icon-rock-golem:before {
  content: "\ffb9b";
}
.game-icon-rock:before {
  content: "\ffb9c";
}
.game-icon-rocket-flight:before {
  content: "\ffb9d";
}
.game-icon-rocket-thruster:before {
  content: "\ffb9e";
}
.game-icon-rocket:before {
  content: "\ffb9f";
}
.game-icon-rocking-chair:before {
  content: "\ffba0";
}
.game-icon-rod-of-asclepius:before {
  content: "\ffba1";
}
.game-icon-rogue:before {
  content: "\ffba2";
}
.game-icon-rolled-cloth:before {
  content: "\ffba3";
}
.game-icon-roller-skate:before {
  content: "\ffba4";
}
.game-icon-rolling-bomb:before {
  content: "\ffba5";
}
.game-icon-rolling-dice-cup:before {
  content: "\ffba6";
}
.game-icon-rolling-dices:before {
  content: "\ffba7";
}
.game-icon-rolling-energy:before {
  content: "\ffba8";
}
.game-icon-rolling-suitcase:before {
  content: "\ffba9";
}
.game-icon-roman-shield:before {
  content: "\ffbaa";
}
.game-icon-roman-toga:before {
  content: "\ffbab";
}
.game-icon-rooster:before {
  content: "\ffbac";
}
.game-icon-root-tip:before {
  content: "\ffbad";
}
.game-icon-rope-bridge:before {
  content: "\ffbae";
}
.game-icon-rope-coil:before {
  content: "\ffbaf";
}
.game-icon-rope-dart:before {
  content: "\ffbb0";
}
.game-icon-ropeway:before {
  content: "\ffbb1";
}
.game-icon-rosa-shield:before {
  content: "\ffbb2";
}
.game-icon-rose:before {
  content: "\ffbb3";
}
.game-icon-rotary-phone:before {
  content: "\ffbb4";
}
.game-icon-rough-wound:before {
  content: "\ffbb5";
}
.game-icon-round-bottom-flask:before {
  content: "\ffbb6";
}
.game-icon-round-knob:before {
  content: "\ffbb7";
}
.game-icon-round-shield:before {
  content: "\ffbb8";
}
.game-icon-round-silo:before {
  content: "\ffbb9";
}
.game-icon-round-star:before {
  content: "\ffbba";
}
.game-icon-round-straw-bale:before {
  content: "\ffbbb";
}
.game-icon-round-struck:before {
  content: "\ffbbc";
}
.game-icon-round-table:before {
  content: "\ffbbd";
}
.game-icon-royal-love:before {
  content: "\ffbbe";
}
.game-icon-rss-2:before {
  content: "\ffbbf";
}
.game-icon-rss:before {
  content: "\ffbc0";
}
.game-icon-rub-el-hizb:before {
  content: "\ffbc1";
}
.game-icon-rubber-boot:before {
  content: "\ffbc2";
}
.game-icon-rugby-conversion:before {
  content: "\ffbc3";
}
.game-icon-rule-book:before {
  content: "\ffbc4";
}
.game-icon-run:before {
  content: "\ffbc5";
}
.game-icon-rune-stone:before {
  content: "\ffbc6";
}
.game-icon-rune-sword:before {
  content: "\ffbc7";
}
.game-icon-running-ninja:before {
  content: "\ffbc8";
}
.game-icon-running-shoe:before {
  content: "\ffbc9";
}
.game-icon-rupee:before {
  content: "\ffbca";
}
.game-icon-rusty-sword:before {
  content: "\ffbcb";
}
.game-icon-s-brick:before {
  content: "\ffbcc";
}
.game-icon-saber-and-pistol:before {
  content: "\ffbcd";
}
.game-icon-saber-slash:before {
  content: "\ffbce";
}
.game-icon-saber-tooth:before {
  content: "\ffbcf";
}
.game-icon-saber-toothed-cat-head:before {
  content: "\ffbd0";
}
.game-icon-sabers-choc:before {
  content: "\ffbd1";
}
.game-icon-sacrificial-dagger:before {
  content: "\ffbd2";
}
.game-icon-sad-crab:before {
  content: "\ffbd3";
}
.game-icon-saddle:before {
  content: "\ffbd4";
}
.game-icon-safety-pin:before {
  content: "\ffbd5";
}
.game-icon-sagittarius:before {
  content: "\ffbd6";
}
.game-icon-sai:before {
  content: "\ffbd7";
}
.game-icon-sail:before {
  content: "\ffbd8";
}
.game-icon-sailboat:before {
  content: "\ffbd9";
}
.game-icon-saint-basil-cathedral:before {
  content: "\ffbda";
}
.game-icon-saiyan-suit:before {
  content: "\ffbdb";
}
.game-icon-salamander:before {
  content: "\ffbdc";
}
.game-icon-salmon:before {
  content: "\ffbdd";
}
.game-icon-saloon-doors:before {
  content: "\ffbde";
}
.game-icon-saloon:before {
  content: "\ffbdf";
}
.game-icon-salt-shaker:before {
  content: "\ffbe0";
}
.game-icon-samara-mosque:before {
  content: "\ffbe1";
}
.game-icon-samurai-helmet:before {
  content: "\ffbe2";
}
.game-icon-samus-helmet:before {
  content: "\ffbe3";
}
.game-icon-sand-castle:before {
  content: "\ffbe4";
}
.game-icon-sand-snake:before {
  content: "\ffbe5";
}
.game-icon-sandal:before {
  content: "\ffbe6";
}
.game-icon-sands-of-time:before {
  content: "\ffbe7";
}
.game-icon-sandstorm:before {
  content: "\ffbe8";
}
.game-icon-sandwich:before {
  content: "\ffbe9";
}
.game-icon-santa-hat:before {
  content: "\ffbea";
}
.game-icon-saphir:before {
  content: "\ffbeb";
}
.game-icon-sarcophagus:before {
  content: "\ffbec";
}
.game-icon-sasquatch:before {
  content: "\ffbed";
}
.game-icon-satellite-communication:before {
  content: "\ffbee";
}
.game-icon-sattelite:before {
  content: "\ffbef";
}
.game-icon-saucepan:before {
  content: "\ffbf0";
}
.game-icon-sauropod-head:before {
  content: "\ffbf1";
}
.game-icon-sauropod-skeleton:before {
  content: "\ffbf2";
}
.game-icon-sausage:before {
  content: "\ffbf3";
}
.game-icon-sausages-ribbon:before {
  content: "\ffbf4";
}
.game-icon-save-arrow:before {
  content: "\ffbf5";
}
.game-icon-save:before {
  content: "\ffbf6";
}
.game-icon-saw-claw:before {
  content: "\ffbf7";
}
.game-icon-sawed-off-shotgun:before {
  content: "\ffbf8";
}
.game-icon-saxophone:before {
  content: "\ffbf9";
}
.game-icon-scabbard:before {
  content: "\ffbfa";
}
.game-icon-scale-mail:before {
  content: "\ffbfb";
}
.game-icon-scales:before {
  content: "\ffbfc";
}
.game-icon-scallop:before {
  content: "\ffbfd";
}
.game-icon-scalpel-strike:before {
  content: "\ffbfe";
}
.game-icon-scalpel:before {
  content: "\ffbff";
}
.game-icon-scar-wound:before {
  content: "\ffc00";
}
.game-icon-scarab-beetle:before {
  content: "\ffc01";
}
.game-icon-scarecrow:before {
  content: "\ffc02";
}
.game-icon-school-bag:before {
  content: "\ffc03";
}
.game-icon-school-of-fish:before {
  content: "\ffc04";
}
.game-icon-scissors-2:before {
  content: "\ffc05";
}
.game-icon-scissors:before {
  content: "\ffc06";
}
.game-icon-scooter:before {
  content: "\ffc07";
}
.game-icon-scorpio:before {
  content: "\ffc08";
}
.game-icon-scorpion-tail:before {
  content: "\ffc09";
}
.game-icon-scorpion:before {
  content: "\ffc0a";
}
.game-icon-scout-ship:before {
  content: "\ffc0b";
}
.game-icon-screaming:before {
  content: "\ffc0c";
}
.game-icon-screen-impact:before {
  content: "\ffc0d";
}
.game-icon-screw-2:before {
  content: "\ffc0e";
}
.game-icon-screw:before {
  content: "\ffc0f";
}
.game-icon-screwdriver:before {
  content: "\ffc10";
}
.game-icon-scroll-quill:before {
  content: "\ffc11";
}
.game-icon-scroll-unfurled:before {
  content: "\ffc12";
}
.game-icon-scuba-mask:before {
  content: "\ffc13";
}
.game-icon-scuba-tanks:before {
  content: "\ffc14";
}
.game-icon-scythe:before {
  content: "\ffc15";
}
.game-icon-sea-cliff:before {
  content: "\ffc16";
}
.game-icon-sea-creature:before {
  content: "\ffc17";
}
.game-icon-sea-dragon:before {
  content: "\ffc18";
}
.game-icon-sea-serpent:before {
  content: "\ffc19";
}
.game-icon-sea-star:before {
  content: "\ffc1a";
}
.game-icon-sea-turtle:before {
  content: "\ffc1b";
}
.game-icon-seagull:before {
  content: "\ffc1c";
}
.game-icon-seahorse:before {
  content: "\ffc1d";
}
.game-icon-seated-mouse:before {
  content: "\ffc1e";
}
.game-icon-secret-book:before {
  content: "\ffc1f";
}
.game-icon-secret-door:before {
  content: "\ffc20";
}
.game-icon-security-gate:before {
  content: "\ffc21";
}
.game-icon-seedling:before {
  content: "\ffc22";
}
.game-icon-select:before {
  content: "\ffc23";
}
.game-icon-self-love:before {
  content: "\ffc24";
}
.game-icon-sell-card:before {
  content: "\ffc25";
}
.game-icon-semi-closed-eye:before {
  content: "\ffc26";
}
.game-icon-sensuousness:before {
  content: "\ffc27";
}
.game-icon-sentry-gun:before {
  content: "\ffc28";
}
.game-icon-sergeant:before {
  content: "\ffc29";
}
.game-icon-serrated-slash:before {
  content: "\ffc2a";
}
.game-icon-server-rack:before {
  content: "\ffc2b";
}
.game-icon-sesame:before {
  content: "\ffc2c";
}
.game-icon-settings-knobs:before {
  content: "\ffc2d";
}
.game-icon-seven-pointed-star:before {
  content: "\ffc2e";
}
.game-icon-severed-hand:before {
  content: "\ffc2f";
}
.game-icon-sewed-shell:before {
  content: "\ffc30";
}
.game-icon-sewing-machine:before {
  content: "\ffc31";
}
.game-icon-sewing-needle:before {
  content: "\ffc32";
}
.game-icon-sewing-string:before {
  content: "\ffc33";
}
.game-icon-sextant:before {
  content: "\ffc34";
}
.game-icon-shadow-follower:before {
  content: "\ffc35";
}
.game-icon-shadow-grasp:before {
  content: "\ffc36";
}
.game-icon-shaking-hands:before {
  content: "\ffc37";
}
.game-icon-shambling-mound:before {
  content: "\ffc38";
}
.game-icon-shambling-zombie:before {
  content: "\ffc39";
}
.game-icon-shamrock:before {
  content: "\ffc3a";
}
.game-icon-shard-sword:before {
  content: "\ffc3b";
}
.game-icon-share:before {
  content: "\ffc3c";
}
.game-icon-shark-bite:before {
  content: "\ffc3d";
}
.game-icon-shark-fin:before {
  content: "\ffc3e";
}
.game-icon-shark-jaws:before {
  content: "\ffc3f";
}
.game-icon-sharp-axe:before {
  content: "\ffc40";
}
.game-icon-sharp-crown:before {
  content: "\ffc41";
}
.game-icon-sharp-halberd:before {
  content: "\ffc42";
}
.game-icon-sharp-lips:before {
  content: "\ffc43";
}
.game-icon-sharp-shuriken:before {
  content: "\ffc44";
}
.game-icon-sharp-smile:before {
  content: "\ffc45";
}
.game-icon-sharped-teeth-skull:before {
  content: "\ffc46";
}
.game-icon-shatter:before {
  content: "\ffc47";
}
.game-icon-shattered-glass:before {
  content: "\ffc48";
}
.game-icon-shattered-heart:before {
  content: "\ffc49";
}
.game-icon-shattered-sword:before {
  content: "\ffc4a";
}
.game-icon-shears:before {
  content: "\ffc4b";
}
.game-icon-sheep:before {
  content: "\ffc4c";
}
.game-icon-sheikah-eye:before {
  content: "\ffc4d";
}
.game-icon-shepherds-crook:before {
  content: "\ffc4e";
}
.game-icon-sherlock-holmes:before {
  content: "\ffc4f";
}
.game-icon-shield-bash:before {
  content: "\ffc50";
}
.game-icon-shield-bounces:before {
  content: "\ffc51";
}
.game-icon-shield-disabled:before {
  content: "\ffc52";
}
.game-icon-shield-echoes:before {
  content: "\ffc53";
}
.game-icon-shield-impact:before {
  content: "\ffc54";
}
.game-icon-shield-opposition:before {
  content: "\ffc55";
}
.game-icon-shield-reflect:before {
  content: "\ffc56";
}
.game-icon-shield:before {
  content: "\ffc57";
}
.game-icon-shieldcomb:before {
  content: "\ffc58";
}
.game-icon-shining-claw:before {
  content: "\ffc59";
}
.game-icon-shining-heart:before {
  content: "\ffc5a";
}
.game-icon-shining-sword:before {
  content: "\ffc5b";
}
.game-icon-shinto-shrine-mirror:before {
  content: "\ffc5c";
}
.game-icon-shinto-shrine:before {
  content: "\ffc5d";
}
.game-icon-shiny-apple:before {
  content: "\ffc5e";
}
.game-icon-shiny-entrance:before {
  content: "\ffc5f";
}
.game-icon-shiny-iris:before {
  content: "\ffc60";
}
.game-icon-shiny-omega:before {
  content: "\ffc61";
}
.game-icon-shiny-purse:before {
  content: "\ffc62";
}
.game-icon-ship-bow:before {
  content: "\ffc63";
}
.game-icon-ship-wheel:before {
  content: "\ffc64";
}
.game-icon-ship-wreck:before {
  content: "\ffc65";
}
.game-icon-shirt-button:before {
  content: "\ffc66";
}
.game-icon-shirt:before {
  content: "\ffc67";
}
.game-icon-shoebill-stork:before {
  content: "\ffc68";
}
.game-icon-shooner-sailboat:before {
  content: "\ffc69";
}
.game-icon-shop:before {
  content: "\ffc6a";
}
.game-icon-shopping-bag:before {
  content: "\ffc6b";
}
.game-icon-shopping-cart:before {
  content: "\ffc6c";
}
.game-icon-shorts:before {
  content: "\ffc6d";
}
.game-icon-shotgun-rounds:before {
  content: "\ffc6e";
}
.game-icon-shotgun:before {
  content: "\ffc6f";
}
.game-icon-shoulder-armor:before {
  content: "\ffc70";
}
.game-icon-shoulder-bag:before {
  content: "\ffc71";
}
.game-icon-shoulder-scales:before {
  content: "\ffc72";
}
.game-icon-shouting:before {
  content: "\ffc73";
}
.game-icon-shower:before {
  content: "\ffc74";
}
.game-icon-shrimp:before {
  content: "\ffc75";
}
.game-icon-shrug:before {
  content: "\ffc76";
}
.game-icon-shuriken-2:before {
  content: "\ffc77";
}
.game-icon-shuriken-aperture:before {
  content: "\ffc78";
}
.game-icon-shuriken:before {
  content: "\ffc79";
}
.game-icon-shut-rose:before {
  content: "\ffc7a";
}
.game-icon-shuttlecock:before {
  content: "\ffc7b";
}
.game-icon-sickle:before {
  content: "\ffc7c";
}
.game-icon-sideswipe:before {
  content: "\ffc7d";
}
.game-icon-siege-ram:before {
  content: "\ffc7e";
}
.game-icon-siege-tower:before {
  content: "\ffc7f";
}
.game-icon-sight-disabled:before {
  content: "\ffc80";
}
.game-icon-silence:before {
  content: "\ffc81";
}
.game-icon-silenced:before {
  content: "\ffc82";
}
.game-icon-silex:before {
  content: "\ffc83";
}
.game-icon-silver-bullet:before {
  content: "\ffc84";
}
.game-icon-sinagot:before {
  content: "\ffc85";
}
.game-icon-sing:before {
  content: "\ffc86";
}
.game-icon-sinking-ship:before {
  content: "\ffc87";
}
.game-icon-sinking-trap:before {
  content: "\ffc88";
}
.game-icon-sinusoidal-beam:before {
  content: "\ffc89";
}
.game-icon-siren:before {
  content: "\ffc8a";
}
.game-icon-sitting-dog:before {
  content: "\ffc8b";
}
.game-icon-six-eyes:before {
  content: "\ffc8c";
}
.game-icon-skateboard:before {
  content: "\ffc8d";
}
.game-icon-skeletal-hand:before {
  content: "\ffc8e";
}
.game-icon-skeleton-inside:before {
  content: "\ffc8f";
}
.game-icon-skeleton-key:before {
  content: "\ffc90";
}
.game-icon-skeleton:before {
  content: "\ffc91";
}
.game-icon-ski-boot:before {
  content: "\ffc92";
}
.game-icon-skid-mark:before {
  content: "\ffc93";
}
.game-icon-skier:before {
  content: "\ffc94";
}
.game-icon-skills:before {
  content: "\ffc95";
}
.game-icon-skipping-rope:before {
  content: "\ffc96";
}
.game-icon-skirt:before {
  content: "\ffc97";
}
.game-icon-skis:before {
  content: "\ffc98";
}
.game-icon-skull-bolt:before {
  content: "\ffc99";
}
.game-icon-skull-crack:before {
  content: "\ffc9a";
}
.game-icon-skull-crossed-bones:before {
  content: "\ffc9b";
}
.game-icon-skull-in-jar:before {
  content: "\ffc9c";
}
.game-icon-skull-mask:before {
  content: "\ffc9d";
}
.game-icon-skull-ring:before {
  content: "\ffc9e";
}
.game-icon-skull-sabertooth:before {
  content: "\ffc9f";
}
.game-icon-skull-shield:before {
  content: "\ffca0";
}
.game-icon-skull-signet:before {
  content: "\ffca1";
}
.game-icon-skull-slices:before {
  content: "\ffca2";
}
.game-icon-skull-staff:before {
  content: "\ffca3";
}
.game-icon-skull-with-syringe:before {
  content: "\ffca4";
}
.game-icon-slalom:before {
  content: "\ffca5";
}
.game-icon-slap:before {
  content: "\ffca6";
}
.game-icon-slashed-shield:before {
  content: "\ffca7";
}
.game-icon-slavery-whip:before {
  content: "\ffca8";
}
.game-icon-sleeping-bag:before {
  content: "\ffca9";
}
.game-icon-sleepy:before {
  content: "\ffcaa";
}
.game-icon-sleeveless-jacket:before {
  content: "\ffcab";
}
.game-icon-sleeveless-top:before {
  content: "\ffcac";
}
.game-icon-sliced-bread:before {
  content: "\ffcad";
}
.game-icon-sliced-mushroom:before {
  content: "\ffcae";
}
.game-icon-sliced-sausage:before {
  content: "\ffcaf";
}
.game-icon-slicing-arrow:before {
  content: "\ffcb0";
}
.game-icon-slime:before {
  content: "\ffcb1";
}
.game-icon-sling:before {
  content: "\ffcb2";
}
.game-icon-slingshot:before {
  content: "\ffcb3";
}
.game-icon-slipknot:before {
  content: "\ffcb4";
}
.game-icon-slippers:before {
  content: "\ffcb5";
}
.game-icon-slot-machine:before {
  content: "\ffcb6";
}
.game-icon-sloth:before {
  content: "\ffcb7";
}
.game-icon-slow-blob:before {
  content: "\ffcb8";
}
.game-icon-slumbering-sanctuary:before {
  content: "\ffcb9";
}
.game-icon-sly:before {
  content: "\ffcba";
}
.game-icon-small-fire:before {
  content: "\ffcbb";
}
.game-icon-small-fishing-sailboat:before {
  content: "\ffcbc";
}
.game-icon-smart:before {
  content: "\ffcbd";
}
.game-icon-smartphone-2:before {
  content: "\ffcbe";
}
.game-icon-smartphone:before {
  content: "\ffcbf";
}
.game-icon-smash-arrows:before {
  content: "\ffcc0";
}
.game-icon-smitten:before {
  content: "\ffcc1";
}
.game-icon-smoke-bomb:before {
  content: "\ffcc2";
}
.game-icon-smoking-finger:before {
  content: "\ffcc3";
}
.game-icon-smoking-orb:before {
  content: "\ffcc4";
}
.game-icon-smoking-pipe:before {
  content: "\ffcc5";
}
.game-icon-smoking-volcano:before {
  content: "\ffcc6";
}
.game-icon-snail-eyes:before {
  content: "\ffcc7";
}
.game-icon-snail:before {
  content: "\ffcc8";
}
.game-icon-snake-bite:before {
  content: "\ffcc9";
}
.game-icon-snake-egg:before {
  content: "\ffcca";
}
.game-icon-snake-jar:before {
  content: "\ffccb";
}
.game-icon-snake-spiral:before {
  content: "\ffccc";
}
.game-icon-snake-tongue:before {
  content: "\ffccd";
}
.game-icon-snake-totem:before {
  content: "\ffcce";
}
.game-icon-snake:before {
  content: "\ffccf";
}
.game-icon-snatch:before {
  content: "\ffcd0";
}
.game-icon-sniffing-dog:before {
  content: "\ffcd1";
}
.game-icon-snitch-quidditch-ball:before {
  content: "\ffcd2";
}
.game-icon-snorkel:before {
  content: "\ffcd3";
}
.game-icon-snout:before {
  content: "\ffcd4";
}
.game-icon-snow-bottle:before {
  content: "\ffcd5";
}
.game-icon-snowboard:before {
  content: "\ffcd6";
}
.game-icon-snowflake-1:before {
  content: "\ffcd7";
}
.game-icon-snowflake-2:before {
  content: "\ffcd8";
}
.game-icon-snowing:before {
  content: "\ffcd9";
}
.game-icon-snowman:before {
  content: "\ffcda";
}
.game-icon-soap-experiment:before {
  content: "\ffcdb";
}
.game-icon-soap:before {
  content: "\ffcdc";
}
.game-icon-soccer-ball:before {
  content: "\ffcdd";
}
.game-icon-soccer-field:before {
  content: "\ffcde";
}
.game-icon-soccer-kick:before {
  content: "\ffcdf";
}
.game-icon-socks:before {
  content: "\ffce0";
}
.game-icon-soda-can:before {
  content: "\ffce1";
}
.game-icon-sofa:before {
  content: "\ffce2";
}
.game-icon-solar-power:before {
  content: "\ffce3";
}
.game-icon-solar-system:before {
  content: "\ffce4";
}
.game-icon-solar-time:before {
  content: "\ffce5";
}
.game-icon-soldering-iron:before {
  content: "\ffce6";
}
.game-icon-solid-leaf:before {
  content: "\ffce7";
}
.game-icon-sombrero:before {
  content: "\ffce8";
}
.game-icon-sonic-boom:before {
  content: "\ffce9";
}
.game-icon-sonic-lightning:before {
  content: "\ffcea";
}
.game-icon-sonic-screech:before {
  content: "\ffceb";
}
.game-icon-sonic-shoes:before {
  content: "\ffcec";
}
.game-icon-sonic-shout:before {
  content: "\ffced";
}
.game-icon-soul-vessel:before {
  content: "\ffcee";
}
.game-icon-sound-off:before {
  content: "\ffcef";
}
.game-icon-sound-on:before {
  content: "\ffcf0";
}
.game-icon-sound-waves:before {
  content: "\ffcf1";
}
.game-icon-south-africa-flag:before {
  content: "\ffcf2";
}
.game-icon-south-africa:before {
  content: "\ffcf3";
}
.game-icon-south-america:before {
  content: "\ffcf4";
}
.game-icon-south-korea:before {
  content: "\ffcf5";
}
.game-icon-space-needle:before {
  content: "\ffcf6";
}
.game-icon-space-shuttle:before {
  content: "\ffcf7";
}
.game-icon-space-suit:before {
  content: "\ffcf8";
}
.game-icon-spaceship:before {
  content: "\ffcf9";
}
.game-icon-spade-skull:before {
  content: "\ffcfa";
}
.game-icon-spade:before {
  content: "\ffcfb";
}
.game-icon-spades:before {
  content: "\ffcfc";
}
.game-icon-spain:before {
  content: "\ffcfd";
}
.game-icon-spanner:before {
  content: "\ffcfe";
}
.game-icon-spark-plug:before {
  content: "\ffcff";
}
.game-icon-spark-spirit:before {
  content: "\ffd00";
}
.game-icon-sparkles:before {
  content: "\ffd01";
}
.game-icon-sparkling-sabre:before {
  content: "\ffd02";
}
.game-icon-sparky-bomb:before {
  content: "\ffd03";
}
.game-icon-sparrow:before {
  content: "\ffd04";
}
.game-icon-spartan-helmet:before {
  content: "\ffd05";
}
.game-icon-spartan:before {
  content: "\ffd06";
}
.game-icon-spatter:before {
  content: "\ffd07";
}
.game-icon-spawn-node:before {
  content: "\ffd08";
}
.game-icon-speaker-off:before {
  content: "\ffd09";
}
.game-icon-speaker:before {
  content: "\ffd0a";
}
.game-icon-spear-feather:before {
  content: "\ffd0b";
}
.game-icon-spear-hook:before {
  content: "\ffd0c";
}
.game-icon-spearfishing:before {
  content: "\ffd0d";
}
.game-icon-spears:before {
  content: "\ffd0e";
}
.game-icon-spectacle-lenses:before {
  content: "\ffd0f";
}
.game-icon-spectacles:before {
  content: "\ffd10";
}
.game-icon-spectre-m4:before {
  content: "\ffd11";
}
.game-icon-spectre:before {
  content: "\ffd12";
}
.game-icon-speed-boat:before {
  content: "\ffd13";
}
.game-icon-speedometer:before {
  content: "\ffd14";
}
.game-icon-spell-book:before {
  content: "\ffd15";
}
.game-icon-sperm-whale:before {
  content: "\ffd16";
}
.game-icon-spider-alt:before {
  content: "\ffd17";
}
.game-icon-spider-bot:before {
  content: "\ffd18";
}
.game-icon-spider-eye:before {
  content: "\ffd19";
}
.game-icon-spider-face:before {
  content: "\ffd1a";
}
.game-icon-spider-mask:before {
  content: "\ffd1b";
}
.game-icon-spider-web:before {
  content: "\ffd1c";
}
.game-icon-spikeball:before {
  content: "\ffd1d";
}
.game-icon-spiked-armor:before {
  content: "\ffd1e";
}
.game-icon-spiked-ball:before {
  content: "\ffd1f";
}
.game-icon-spiked-bat:before {
  content: "\ffd20";
}
.game-icon-spiked-collar:before {
  content: "\ffd21";
}
.game-icon-spiked-dragon-head:before {
  content: "\ffd22";
}
.game-icon-spiked-fence:before {
  content: "\ffd23";
}
.game-icon-spiked-halo:before {
  content: "\ffd24";
}
.game-icon-spiked-mace:before {
  content: "\ffd25";
}
.game-icon-spiked-shell:before {
  content: "\ffd26";
}
.game-icon-spiked-shield:before {
  content: "\ffd27";
}
.game-icon-spiked-shoulder-armor:before {
  content: "\ffd28";
}
.game-icon-spiked-snail:before {
  content: "\ffd29";
}
.game-icon-spiked-tail:before {
  content: "\ffd2a";
}
.game-icon-spiked-tentacle:before {
  content: "\ffd2b";
}
.game-icon-spiked-trunk:before {
  content: "\ffd2c";
}
.game-icon-spiked-wall:before {
  content: "\ffd2d";
}
.game-icon-spikes-full:before {
  content: "\ffd2e";
}
.game-icon-spikes-half:before {
  content: "\ffd2f";
}
.game-icon-spikes-init:before {
  content: "\ffd30";
}
.game-icon-spikes:before {
  content: "\ffd31";
}
.game-icon-spiky-eclipse:before {
  content: "\ffd32";
}
.game-icon-spiky-explosion:before {
  content: "\ffd33";
}
.game-icon-spiky-field:before {
  content: "\ffd34";
}
.game-icon-spiky-pit:before {
  content: "\ffd35";
}
.game-icon-spiky-wing:before {
  content: "\ffd36";
}
.game-icon-spill:before {
  content: "\ffd37";
}
.game-icon-spinal-coil:before {
  content: "\ffd38";
}
.game-icon-spine-arrow:before {
  content: "\ffd39";
}
.game-icon-spinning-blades:before {
  content: "\ffd3a";
}
.game-icon-spinning-ribbons:before {
  content: "\ffd3b";
}
.game-icon-spinning-sword:before {
  content: "\ffd3c";
}
.game-icon-spinning-top:before {
  content: "\ffd3d";
}
.game-icon-spinning-wheel:before {
  content: "\ffd3e";
}
.game-icon-spiral-arrow:before {
  content: "\ffd3f";
}
.game-icon-spiral-bloom:before {
  content: "\ffd40";
}
.game-icon-spiral-bottle:before {
  content: "\ffd41";
}
.game-icon-spiral-hilt:before {
  content: "\ffd42";
}
.game-icon-spiral-lollipop:before {
  content: "\ffd43";
}
.game-icon-spiral-shell:before {
  content: "\ffd44";
}
.game-icon-spiral-tentacle:before {
  content: "\ffd45";
}
.game-icon-spiral-thrust:before {
  content: "\ffd46";
}
.game-icon-splash-2:before {
  content: "\ffd47";
}
.game-icon-splash:before {
  content: "\ffd48";
}
.game-icon-splashy-stream:before {
  content: "\ffd49";
}
.game-icon-split-arrows:before {
  content: "\ffd4a";
}
.game-icon-split-body:before {
  content: "\ffd4b";
}
.game-icon-split-cross:before {
  content: "\ffd4c";
}
.game-icon-splurt:before {
  content: "\ffd4d";
}
.game-icon-spock-hand:before {
  content: "\ffd4e";
}
.game-icon-spooky-house:before {
  content: "\ffd4f";
}
.game-icon-spoon:before {
  content: "\ffd50";
}
.game-icon-sport-medal:before {
  content: "\ffd51";
}
.game-icon-spoted-flower:before {
  content: "\ffd52";
}
.game-icon-spotted-arrowhead:before {
  content: "\ffd53";
}
.game-icon-spotted-bug:before {
  content: "\ffd54";
}
.game-icon-spotted-mushroom:before {
  content: "\ffd55";
}
.game-icon-spotted-wound:before {
  content: "\ffd56";
}
.game-icon-spoutnik:before {
  content: "\ffd57";
}
.game-icon-spray:before {
  content: "\ffd58";
}
.game-icon-spring:before {
  content: "\ffd59";
}
.game-icon-sprint:before {
  content: "\ffd5a";
}
.game-icon-sprout-disc:before {
  content: "\ffd5b";
}
.game-icon-sprout:before {
  content: "\ffd5c";
}
.game-icon-spy:before {
  content: "\ffd5d";
}
.game-icon-spyglass:before {
  content: "\ffd5e";
}
.game-icon-square-bottle:before {
  content: "\ffd5f";
}
.game-icon-square:before {
  content: "\ffd60";
}
.game-icon-squib:before {
  content: "\ffd61";
}
.game-icon-squid-head:before {
  content: "\ffd62";
}
.game-icon-squid:before {
  content: "\ffd63";
}
.game-icon-squirrel:before {
  content: "\ffd64";
}
.game-icon-sri-lanka:before {
  content: "\ffd65";
}
.game-icon-stabbed-note:before {
  content: "\ffd66";
}
.game-icon-stable:before {
  content: "\ffd67";
}
.game-icon-stack:before {
  content: "\ffd68";
}
.game-icon-stag-head:before {
  content: "\ffd69";
}
.game-icon-stahlhelm:before {
  content: "\ffd6a";
}
.game-icon-stairs-cake:before {
  content: "\ffd6b";
}
.game-icon-stairs-goal:before {
  content: "\ffd6c";
}
.game-icon-stairs:before {
  content: "\ffd6d";
}
.game-icon-stake-hammer:before {
  content: "\ffd6e";
}
.game-icon-stakes-fence:before {
  content: "\ffd6f";
}
.game-icon-stalactites:before {
  content: "\ffd70";
}
.game-icon-stalagtite:before {
  content: "\ffd71";
}
.game-icon-stamper:before {
  content: "\ffd72";
}
.game-icon-standing-potion:before {
  content: "\ffd73";
}
.game-icon-stapler-heavy-duty:before {
  content: "\ffd74";
}
.game-icon-stapler-pneumatic:before {
  content: "\ffd75";
}
.game-icon-stapler:before {
  content: "\ffd76";
}
.game-icon-star-altar:before {
  content: "\ffd77";
}
.game-icon-star-cycle:before {
  content: "\ffd78";
}
.game-icon-star-flag:before {
  content: "\ffd79";
}
.game-icon-star-formation:before {
  content: "\ffd7a";
}
.game-icon-star-gate:before {
  content: "\ffd7b";
}
.game-icon-star-key:before {
  content: "\ffd7c";
}
.game-icon-star-medal:before {
  content: "\ffd7d";
}
.game-icon-star-prominences:before {
  content: "\ffd7e";
}
.game-icon-star-pupil:before {
  content: "\ffd7f";
}
.game-icon-star-sattelites:before {
  content: "\ffd80";
}
.game-icon-star-shuriken:before {
  content: "\ffd81";
}
.game-icon-star-skull:before {
  content: "\ffd82";
}
.game-icon-star-struck:before {
  content: "\ffd83";
}
.game-icon-star-swirl:before {
  content: "\ffd84";
}
.game-icon-starfighter:before {
  content: "\ffd85";
}
.game-icon-stars-stack:before {
  content: "\ffd86";
}
.game-icon-staryu:before {
  content: "\ffd87";
}
.game-icon-static-guard:before {
  content: "\ffd88";
}
.game-icon-static-waves:before {
  content: "\ffd89";
}
.game-icon-static:before {
  content: "\ffd8a";
}
.game-icon-steak:before {
  content: "\ffd8b";
}
.game-icon-stealth-bomber:before {
  content: "\ffd8c";
}
.game-icon-steam-blast:before {
  content: "\ffd8d";
}
.game-icon-steam-locomotive:before {
  content: "\ffd8e";
}
.game-icon-steam:before {
  content: "\ffd8f";
}
.game-icon-steampunk-goggles:before {
  content: "\ffd90";
}
.game-icon-steamroller:before {
  content: "\ffd91";
}
.game-icon-steel-claws-2:before {
  content: "\ffd92";
}
.game-icon-steel-claws:before {
  content: "\ffd93";
}
.game-icon-steel-door:before {
  content: "\ffd94";
}
.game-icon-steeltoe-boots:before {
  content: "\ffd95";
}
.game-icon-steelwing-emblem:before {
  content: "\ffd96";
}
.game-icon-steering-wheel:before {
  content: "\ffd97";
}
.game-icon-stegosaurus-scales:before {
  content: "\ffd98";
}
.game-icon-stethoscope:before {
  content: "\ffd99";
}
.game-icon-steyr-aug:before {
  content: "\ffd9a";
}
.game-icon-stick-frame:before {
  content: "\ffd9b";
}
.game-icon-stick-grenade:before {
  content: "\ffd9c";
}
.game-icon-stick-splitting:before {
  content: "\ffd9d";
}
.game-icon-sticking-plaster:before {
  content: "\ffd9e";
}
.game-icon-sticky-boot:before {
  content: "\ffd9f";
}
.game-icon-stigmata:before {
  content: "\ffda0";
}
.game-icon-stiletto-2:before {
  content: "\ffda1";
}
.game-icon-stiletto:before {
  content: "\ffda2";
}
.game-icon-stitched-wound:before {
  content: "\ffda3";
}
.game-icon-stockpiles:before {
  content: "\ffda4";
}
.game-icon-stomach:before {
  content: "\ffda5";
}
.game-icon-stomp-tornado:before {
  content: "\ffda6";
}
.game-icon-stomp:before {
  content: "\ffda7";
}
.game-icon-stone-axe:before {
  content: "\ffda8";
}
.game-icon-stone-block:before {
  content: "\ffda9";
}
.game-icon-stone-bridge:before {
  content: "\ffdaa";
}
.game-icon-stone-bust:before {
  content: "\ffdab";
}
.game-icon-stone-crafting:before {
  content: "\ffdac";
}
.game-icon-stone-path:before {
  content: "\ffdad";
}
.game-icon-stone-pile:before {
  content: "\ffdae";
}
.game-icon-stone-spear:before {
  content: "\ffdaf";
}
.game-icon-stone-sphere:before {
  content: "\ffdb0";
}
.game-icon-stone-stack:before {
  content: "\ffdb1";
}
.game-icon-stone-tablet:before {
  content: "\ffdb2";
}
.game-icon-stone-throne:before {
  content: "\ffdb3";
}
.game-icon-stone-tower:before {
  content: "\ffdb4";
}
.game-icon-stone-wall:before {
  content: "\ffdb5";
}
.game-icon-stone-wheel:before {
  content: "\ffdb6";
}
.game-icon-stoned-skull:before {
  content: "\ffdb7";
}
.game-icon-stop-sign:before {
  content: "\ffdb8";
}
.game-icon-stopwatch-2:before {
  content: "\ffdb9";
}
.game-icon-stopwatch:before {
  content: "\ffdba";
}
.game-icon-stork-delivery:before {
  content: "\ffdbb";
}
.game-icon-strafe:before {
  content: "\ffdbc";
}
.game-icon-straight-pipe:before {
  content: "\ffdbd";
}
.game-icon-strawberry:before {
  content: "\ffdbe";
}
.game-icon-street-light:before {
  content: "\ffdbf";
}
.game-icon-striking-arrows:before {
  content: "\ffdc0";
}
.game-icon-striking-balls:before {
  content: "\ffdc1";
}
.game-icon-striking-clamps:before {
  content: "\ffdc2";
}
.game-icon-striking-diamonds:before {
  content: "\ffdc3";
}
.game-icon-striking-splinter:before {
  content: "\ffdc4";
}
.game-icon-striped-sun:before {
  content: "\ffdc5";
}
.game-icon-striped-sword:before {
  content: "\ffdc6";
}
.game-icon-strong-man:before {
  content: "\ffdc7";
}
.game-icon-strong:before {
  content: "\ffdc8";
}
.game-icon-strongbox:before {
  content: "\ffdc9";
}
.game-icon-stump-regrowth:before {
  content: "\ffdca";
}
.game-icon-stun-grenade:before {
  content: "\ffdcb";
}
.game-icon-submarine-missile:before {
  content: "\ffdcc";
}
.game-icon-submarine:before {
  content: "\ffdcd";
}
.game-icon-subway:before {
  content: "\ffdce";
}
.game-icon-suckered-tentacle:before {
  content: "\ffdcf";
}
.game-icon-sugar-cane:before {
  content: "\ffdd0";
}
.game-icon-suicide:before {
  content: "\ffdd1";
}
.game-icon-suitcase:before {
  content: "\ffdd2";
}
.game-icon-suits:before {
  content: "\ffdd3";
}
.game-icon-summits:before {
  content: "\ffdd4";
}
.game-icon-sun-cloud:before {
  content: "\ffdd5";
}
.game-icon-sun-priest:before {
  content: "\ffdd6";
}
.game-icon-sun-radiations:before {
  content: "\ffdd7";
}
.game-icon-sun-spear:before {
  content: "\ffdd8";
}
.game-icon-sun:before {
  content: "\ffdd9";
}
.game-icon-sunbeams:before {
  content: "\ffdda";
}
.game-icon-sundial:before {
  content: "\ffddb";
}
.game-icon-sunflower:before {
  content: "\ffddc";
}
.game-icon-sunglasses:before {
  content: "\ffddd";
}
.game-icon-sunken-eye:before {
  content: "\ffdde";
}
.game-icon-sunrise-2:before {
  content: "\ffddf";
}
.game-icon-sunrise:before {
  content: "\ffde0";
}
.game-icon-sunset:before {
  content: "\ffde1";
}
.game-icon-super-mushroom:before {
  content: "\ffde2";
}
.game-icon-supersonic-arrow:before {
  content: "\ffde3";
}
.game-icon-supersonic-bullet:before {
  content: "\ffde4";
}
.game-icon-surf-board:before {
  content: "\ffde5";
}
.game-icon-surfer-van:before {
  content: "\ffde6";
}
.game-icon-surprised-skull:before {
  content: "\ffde7";
}
.game-icon-surprised:before {
  content: "\ffde8";
}
.game-icon-surrounded-eye:before {
  content: "\ffde9";
}
.game-icon-surrounded-shield:before {
  content: "\ffdea";
}
.game-icon-sushis:before {
  content: "\ffdeb";
}
.game-icon-suspension-bridge:before {
  content: "\ffdec";
}
.game-icon-suspicious:before {
  content: "\ffded";
}
.game-icon-sverd-i-fjell:before {
  content: "\ffdee";
}
.game-icon-swallow-2:before {
  content: "\ffdef";
}
.game-icon-swallow:before {
  content: "\ffdf0";
}
.game-icon-swallower:before {
  content: "\ffdf1";
}
.game-icon-swamp-bat:before {
  content: "\ffdf2";
}
.game-icon-swamp:before {
  content: "\ffdf3";
}
.game-icon-swan-breeze:before {
  content: "\ffdf4";
}
.game-icon-swan:before {
  content: "\ffdf5";
}
.game-icon-swap-bag:before {
  content: "\ffdf6";
}
.game-icon-swimfins:before {
  content: "\ffdf7";
}
.game-icon-swipe-card:before {
  content: "\ffdf8";
}
.game-icon-swirl-ring:before {
  content: "\ffdf9";
}
.game-icon-swirl-string:before {
  content: "\ffdfa";
}
.game-icon-swirled-shell:before {
  content: "\ffdfb";
}
.game-icon-swiss-army-knife:before {
  content: "\ffdfc";
}
.game-icon-switch-weapon:before {
  content: "\ffdfd";
}
.game-icon-switchblade:before {
  content: "\ffdfe";
}
.game-icon-switzerland:before {
  content: "\ffdff";
}
.game-icon-sword-altar:before {
  content: "\ffe00";
}
.game-icon-sword-array:before {
  content: "\ffe01";
}
.game-icon-sword-brandish:before {
  content: "\ffe02";
}
.game-icon-sword-break:before {
  content: "\ffe03";
}
.game-icon-sword-clash:before {
  content: "\ffe04";
}
.game-icon-sword-hilt:before {
  content: "\ffe05";
}
.game-icon-sword-in-stone:before {
  content: "\ffe06";
}
.game-icon-sword-mold:before {
  content: "\ffe07";
}
.game-icon-sword-slice:before {
  content: "\ffe08";
}
.game-icon-sword-smithing:before {
  content: "\ffe09";
}
.game-icon-sword-spade:before {
  content: "\ffe0a";
}
.game-icon-sword-spin:before {
  content: "\ffe0b";
}
.game-icon-sword-tie:before {
  content: "\ffe0c";
}
.game-icon-sword-wound:before {
  content: "\ffe0d";
}
.game-icon-swordman:before {
  content: "\ffe0e";
}
.game-icon-swords-emblem:before {
  content: "\ffe0f";
}
.game-icon-swords-power:before {
  content: "\ffe10";
}
.game-icon-swordwoman:before {
  content: "\ffe11";
}
.game-icon-sydney-opera-house:before {
  content: "\ffe12";
}
.game-icon-syringe-2:before {
  content: "\ffe13";
}
.game-icon-syringe:before {
  content: "\ffe14";
}
.game-icon-t-brick:before {
  content: "\ffe15";
}
.game-icon-t-rex-skull:before {
  content: "\ffe16";
}
.game-icon-t-shirt:before {
  content: "\ffe17";
}
.game-icon-tabi-boot:before {
  content: "\ffe18";
}
.game-icon-table:before {
  content: "\ffe19";
}
.game-icon-tablet:before {
  content: "\ffe1a";
}
.game-icon-tabletop-players:before {
  content: "\ffe1b";
}
.game-icon-tacos:before {
  content: "\ffe1c";
}
.game-icon-tadpole:before {
  content: "\ffe1d";
}
.game-icon-take-my-money:before {
  content: "\ffe1e";
}
.game-icon-talk:before {
  content: "\ffe1f";
}
.game-icon-tall-bridge:before {
  content: "\ffe20";
}
.game-icon-tambourine:before {
  content: "\ffe21";
}
.game-icon-tangerine:before {
  content: "\ffe22";
}
.game-icon-tank-top:before {
  content: "\ffe23";
}
.game-icon-tank-tread:before {
  content: "\ffe24";
}
.game-icon-tank:before {
  content: "\ffe25";
}
.game-icon-tanzania:before {
  content: "\ffe26";
}
.game-icon-tap:before {
  content: "\ffe27";
}
.game-icon-tapir:before {
  content: "\ffe28";
}
.game-icon-target-arrows:before {
  content: "\ffe29";
}
.game-icon-target-dummy:before {
  content: "\ffe2a";
}
.game-icon-target-laser:before {
  content: "\ffe2b";
}
.game-icon-target-poster:before {
  content: "\ffe2c";
}
.game-icon-target-prize:before {
  content: "\ffe2d";
}
.game-icon-target-shot:before {
  content: "\ffe2e";
}
.game-icon-targeted:before {
  content: "\ffe2f";
}
.game-icon-targeting:before {
  content: "\ffe30";
}
.game-icon-tattered-banner:before {
  content: "\ffe31";
}
.game-icon-taurus:before {
  content: "\ffe32";
}
.game-icon-tavern-sign:before {
  content: "\ffe33";
}
.game-icon-teacher:before {
  content: "\ffe34";
}
.game-icon-team-downgrade:before {
  content: "\ffe35";
}
.game-icon-team-idea:before {
  content: "\ffe36";
}
.game-icon-team-upgrade:before {
  content: "\ffe37";
}
.game-icon-teapot-leaves:before {
  content: "\ffe38";
}
.game-icon-teapot:before {
  content: "\ffe39";
}
.game-icon-tear-tracks:before {
  content: "\ffe3a";
}
.game-icon-tearing:before {
  content: "\ffe3b";
}
.game-icon-tec-9:before {
  content: "\ffe3c";
}
.game-icon-techno-heart:before {
  content: "\ffe3d";
}
.game-icon-tee-pipe:before {
  content: "\ffe3e";
}
.game-icon-telefrag:before {
  content: "\ffe3f";
}
.game-icon-telepathy:before {
  content: "\ffe40";
}
.game-icon-teleport:before {
  content: "\ffe41";
}
.game-icon-telescopic-baton:before {
  content: "\ffe42";
}
.game-icon-teller-mine:before {
  content: "\ffe43";
}
.game-icon-templar-eye:before {
  content: "\ffe44";
}
.game-icon-templar-heart:before {
  content: "\ffe45";
}
.game-icon-templar-shield:before {
  content: "\ffe46";
}
.game-icon-temple-door:before {
  content: "\ffe47";
}
.game-icon-temple-gate:before {
  content: "\ffe48";
}
.game-icon-temporary-shield:before {
  content: "\ffe49";
}
.game-icon-temptation:before {
  content: "\ffe4a";
}
.game-icon-tennis-ball:before {
  content: "\ffe4b";
}
.game-icon-tennis-court:before {
  content: "\ffe4c";
}
.game-icon-tennis-racket:before {
  content: "\ffe4d";
}
.game-icon-tension-snowflake:before {
  content: "\ffe4e";
}
.game-icon-tentacle-heart:before {
  content: "\ffe4f";
}
.game-icon-tentacle-strike:before {
  content: "\ffe50";
}
.game-icon-tentacles-barrier:before {
  content: "\ffe51";
}
.game-icon-tentacles-skull:before {
  content: "\ffe52";
}
.game-icon-tentacurl:before {
  content: "\ffe53";
}
.game-icon-terror:before {
  content: "\ffe54";
}
.game-icon-tesla-coil:before {
  content: "\ffe55";
}
.game-icon-tesla-turret:before {
  content: "\ffe56";
}
.game-icon-tesla:before {
  content: "\ffe57";
}
.game-icon-test-tubes:before {
  content: "\ffe58";
}
.game-icon-texas:before {
  content: "\ffe59";
}
.game-icon-theater-curtains:before {
  content: "\ffe5a";
}
.game-icon-theater:before {
  content: "\ffe5b";
}
.game-icon-thermometer-cold:before {
  content: "\ffe5c";
}
.game-icon-thermometer-hot:before {
  content: "\ffe5d";
}
.game-icon-thermometer-scale:before {
  content: "\ffe5e";
}
.game-icon-think:before {
  content: "\ffe5f";
}
.game-icon-third-eye:before {
  content: "\ffe60";
}
.game-icon-thompson-m1:before {
  content: "\ffe61";
}
.game-icon-thompson-m1928:before {
  content: "\ffe62";
}
.game-icon-thor-fist:before {
  content: "\ffe63";
}
.game-icon-thor-hammer:before {
  content: "\ffe64";
}
.game-icon-thorn-helix:before {
  content: "\ffe65";
}
.game-icon-thorned-arrow:before {
  content: "\ffe66";
}
.game-icon-thorny-tentacle:before {
  content: "\ffe67";
}
.game-icon-thorny-vine:before {
  content: "\ffe68";
}
.game-icon-three-burning-balls:before {
  content: "\ffe69";
}
.game-icon-three-friends:before {
  content: "\ffe6a";
}
.game-icon-three-keys:before {
  content: "\ffe6b";
}
.game-icon-three-leaves:before {
  content: "\ffe6c";
}
.game-icon-three-pointed-shuriken:before {
  content: "\ffe6d";
}
.game-icon-throne-king:before {
  content: "\ffe6e";
}
.game-icon-throwing-ball:before {
  content: "\ffe6f";
}
.game-icon-thrown-charcoal:before {
  content: "\ffe70";
}
.game-icon-thrown-daggers:before {
  content: "\ffe71";
}
.game-icon-thrown-knife:before {
  content: "\ffe72";
}
.game-icon-thrown-spear:before {
  content: "\ffe73";
}
.game-icon-thrust-bend:before {
  content: "\ffe74";
}
.game-icon-thrust:before {
  content: "\ffe75";
}
.game-icon-thumb-down:before {
  content: "\ffe76";
}
.game-icon-thumb-up:before {
  content: "\ffe77";
}
.game-icon-thunder-blade:before {
  content: "\ffe78";
}
.game-icon-thunder-skull:before {
  content: "\ffe79";
}
.game-icon-thunder-struck:before {
  content: "\ffe7a";
}
.game-icon-thunderball:before {
  content: "\ffe7b";
}
.game-icon-thwomp:before {
  content: "\ffe7c";
}
.game-icon-tiara:before {
  content: "\ffe7d";
}
.game-icon-tic-tac-toe:before {
  content: "\ffe7e";
}
.game-icon-tick:before {
  content: "\ffe7f";
}
.game-icon-ticket:before {
  content: "\ffe80";
}
.game-icon-tie:before {
  content: "\ffe81";
}
.game-icon-tied-scroll:before {
  content: "\ffe82";
}
.game-icon-tiger-head:before {
  content: "\ffe83";
}
.game-icon-tiger:before {
  content: "\ffe84";
}
.game-icon-tightrope:before {
  content: "\ffe85";
}
.game-icon-time-bomb:before {
  content: "\ffe86";
}
.game-icon-time-dynamite:before {
  content: "\ffe87";
}
.game-icon-time-synchronization:before {
  content: "\ffe88";
}
.game-icon-time-trap:before {
  content: "\ffe89";
}
.game-icon-tinker:before {
  content: "\ffe8a";
}
.game-icon-tipi:before {
  content: "\ffe8b";
}
.game-icon-tire-iron-cross:before {
  content: "\ffe8c";
}
.game-icon-tire-iron:before {
  content: "\ffe8d";
}
.game-icon-tire-tracks:before {
  content: "\ffe8e";
}
.game-icon-tired-eye:before {
  content: "\ffe8f";
}
.game-icon-toad-teeth:before {
  content: "\ffe90";
}
.game-icon-toaster:before {
  content: "\ffe91";
}
.game-icon-toggles:before {
  content: "\ffe92";
}
.game-icon-token:before {
  content: "\ffe93";
}
.game-icon-tomahawk:before {
  content: "\ffe94";
}
.game-icon-tomato:before {
  content: "\ffe95";
}
.game-icon-tombstone-2:before {
  content: "\ffe96";
}
.game-icon-tombstone:before {
  content: "\ffe97";
}
.game-icon-tongue:before {
  content: "\ffe98";
}
.game-icon-toolbox:before {
  content: "\ffe99";
}
.game-icon-tooth:before {
  content: "\ffe9a";
}
.game-icon-toothbrush:before {
  content: "\ffe9b";
}
.game-icon-top-hat:before {
  content: "\ffe9c";
}
.game-icon-top-paw:before {
  content: "\ffe9d";
}
.game-icon-topaz:before {
  content: "\ffe9e";
}
.game-icon-torc:before {
  content: "\ffe9f";
}
.game-icon-torch:before {
  content: "\ffea0";
}
.game-icon-tornado-discs:before {
  content: "\ffea1";
}
.game-icon-tornado:before {
  content: "\ffea2";
}
.game-icon-torpedo:before {
  content: "\ffea3";
}
.game-icon-tortoise:before {
  content: "\ffea4";
}
.game-icon-totem-head:before {
  content: "\ffea5";
}
.game-icon-totem-mask:before {
  content: "\ffea6";
}
.game-icon-totem:before {
  content: "\ffea7";
}
.game-icon-toucan:before {
  content: "\ffea8";
}
.game-icon-tow-truck:before {
  content: "\ffea9";
}
.game-icon-towel:before {
  content: "\ffeaa";
}
.game-icon-tower-bridge:before {
  content: "\ffeab";
}
.game-icon-tower-fall:before {
  content: "\ffeac";
}
.game-icon-tower-flag:before {
  content: "\ffead";
}
.game-icon-toy-mallet:before {
  content: "\ffeae";
}
.game-icon-tracked-robot:before {
  content: "\ffeaf";
}
.game-icon-trade:before {
  content: "\ffeb0";
}
.game-icon-traffic-cone:before {
  content: "\ffeb1";
}
.game-icon-traffic-lights-green:before {
  content: "\ffeb2";
}
.game-icon-traffic-lights-orange:before {
  content: "\ffeb3";
}
.game-icon-traffic-lights-ready-to-go:before {
  content: "\ffeb4";
}
.game-icon-traffic-lights-red:before {
  content: "\ffeb5";
}
.game-icon-trail:before {
  content: "\ffeb6";
}
.game-icon-trample:before {
  content: "\ffeb7";
}
.game-icon-transform:before {
  content: "\ffeb8";
}
.game-icon-transfuse:before {
  content: "\ffeb9";
}
.game-icon-transparent-slime:before {
  content: "\ffeba";
}
.game-icon-transparent-tubes:before {
  content: "\ffebb";
}
.game-icon-transportation-rings:before {
  content: "\ffebc";
}
.game-icon-trap-mask:before {
  content: "\ffebd";
}
.game-icon-trash-can:before {
  content: "\ffebe";
}
.game-icon-travel-dress:before {
  content: "\ffebf";
}
.game-icon-tread:before {
  content: "\ffec0";
}
.game-icon-treasure-map:before {
  content: "\ffec1";
}
.game-icon-trebuchet:before {
  content: "\ffec2";
}
.game-icon-tree-beehive:before {
  content: "\ffec3";
}
.game-icon-tree-branch:before {
  content: "\ffec4";
}
.game-icon-tree-door:before {
  content: "\ffec5";
}
.game-icon-tree-face:before {
  content: "\ffec6";
}
.game-icon-tree-growth:before {
  content: "\ffec7";
}
.game-icon-tree-roots:before {
  content: "\ffec8";
}
.game-icon-tree-swing:before {
  content: "\ffec9";
}
.game-icon-treehouse:before {
  content: "\ffeca";
}
.game-icon-trefoil-lily:before {
  content: "\ffecb";
}
.game-icon-trefoil-shuriken:before {
  content: "\ffecc";
}
.game-icon-trench-assault:before {
  content: "\ffecd";
}
.game-icon-trench-body-armor:before {
  content: "\ffece";
}
.game-icon-trench-knife:before {
  content: "\ffecf";
}
.game-icon-trench-spade:before {
  content: "\ffed0";
}
.game-icon-triangle-target:before {
  content: "\ffed1";
}
.game-icon-tribal-gear:before {
  content: "\ffed2";
}
.game-icon-tribal-mask:before {
  content: "\ffed3";
}
.game-icon-tribal-pendant:before {
  content: "\ffed4";
}
.game-icon-tribal-shield:before {
  content: "\ffed5";
}
.game-icon-tribunal-jury:before {
  content: "\ffed6";
}
.game-icon-triceratops-head:before {
  content: "\ffed7";
}
.game-icon-trident-shield:before {
  content: "\ffed8";
}
.game-icon-trident:before {
  content: "\ffed9";
}
.game-icon-triforce:before {
  content: "\ffeda";
}
.game-icon-trigger-hurt:before {
  content: "\ffedb";
}
.game-icon-trilobite:before {
  content: "\ffedc";
}
.game-icon-trinacria:before {
  content: "\ffedd";
}
.game-icon-triorb:before {
  content: "\ffede";
}
.game-icon-triple-beak:before {
  content: "\ffedf";
}
.game-icon-triple-claws:before {
  content: "\ffee0";
}
.game-icon-triple-corn:before {
  content: "\ffee1";
}
.game-icon-triple-gate:before {
  content: "\ffee2";
}
.game-icon-triple-lock:before {
  content: "\ffee3";
}
.game-icon-triple-needle:before {
  content: "\ffee4";
}
.game-icon-triple-plier:before {
  content: "\ffee5";
}
.game-icon-triple-scratches:before {
  content: "\ffee6";
}
.game-icon-triple-shells:before {
  content: "\ffee7";
}
.game-icon-triple-skulls:before {
  content: "\ffee8";
}
.game-icon-triple-yin:before {
  content: "\ffee9";
}
.game-icon-tripwire:before {
  content: "\ffeea";
}
.game-icon-triquetra:before {
  content: "\ffeeb";
}
.game-icon-trireme:before {
  content: "\ffeec";
}
.game-icon-triton-head:before {
  content: "\ffeed";
}
.game-icon-troglodyte:before {
  content: "\ffeee";
}
.game-icon-trojan-horse:before {
  content: "\ffeef";
}
.game-icon-troll:before {
  content: "\ffef0";
}
.game-icon-trombone:before {
  content: "\ffef1";
}
.game-icon-tron-arrow:before {
  content: "\ffef2";
}
.game-icon-trophies-shelf:before {
  content: "\ffef3";
}
.game-icon-trophy-cup:before {
  content: "\ffef4";
}
.game-icon-trophy:before {
  content: "\ffef5";
}
.game-icon-tropical-fish:before {
  content: "\ffef6";
}
.game-icon-trousers-2:before {
  content: "\ffef7";
}
.game-icon-trousers:before {
  content: "\ffef8";
}
.game-icon-trowel:before {
  content: "\ffef9";
}
.game-icon-truce:before {
  content: "\ffefa";
}
.game-icon-truck:before {
  content: "\ffefb";
}
.game-icon-trumpet-flag:before {
  content: "\ffefc";
}
.game-icon-trumpet:before {
  content: "\ffefd";
}
.game-icon-trunk-mushroom:before {
  content: "\ffefe";
}
.game-icon-tuba:before {
  content: "\ffeff";
}
.game-icon-tumbleweed:before {
  content: "\fff00";
}
.game-icon-tumor:before {
  content: "\fff01";
}
.game-icon-tumulus:before {
  content: "\fff02";
}
.game-icon-tune-pitch:before {
  content: "\fff03";
}
.game-icon-tunisia:before {
  content: "\fff04";
}
.game-icon-turban:before {
  content: "\fff05";
}
.game-icon-turbine:before {
  content: "\fff06";
}
.game-icon-turd:before {
  content: "\fff07";
}
.game-icon-turnstile:before {
  content: "\fff08";
}
.game-icon-turret:before {
  content: "\fff09";
}
.game-icon-turtle-shell:before {
  content: "\fff0a";
}
.game-icon-turtle:before {
  content: "\fff0b";
}
.game-icon-tusks-flag:before {
  content: "\fff0c";
}
.game-icon-tv-remote:before {
  content: "\fff0d";
}
.game-icon-tv-tower:before {
  content: "\fff0e";
}
.game-icon-tv:before {
  content: "\fff0f";
}
.game-icon-twin-shell:before {
  content: "\fff10";
}
.game-icon-twirl-center:before {
  content: "\fff11";
}
.game-icon-twirly-flower:before {
  content: "\fff12";
}
.game-icon-twister:before {
  content: "\fff13";
}
.game-icon-two-coins:before {
  content: "\fff14";
}
.game-icon-two-feathers:before {
  content: "\fff15";
}
.game-icon-two-handed-sword:before {
  content: "\fff16";
}
.game-icon-two-shadows:before {
  content: "\fff17";
}
.game-icon-tyre:before {
  content: "\fff18";
}
.game-icon-ubisoft-sun:before {
  content: "\fff19";
}
.game-icon-udder:before {
  content: "\fff1a";
}
.game-icon-ufo:before {
  content: "\fff1b";
}
.game-icon-ultrasound:before {
  content: "\fff1c";
}
.game-icon-uluru:before {
  content: "\fff1d";
}
.game-icon-umbrella-bayonet:before {
  content: "\fff1e";
}
.game-icon-umbrella:before {
  content: "\fff1f";
}
.game-icon-unbalanced:before {
  content: "\fff20";
}
.game-icon-uncertainty:before {
  content: "\fff21";
}
.game-icon-underground-cave:before {
  content: "\fff22";
}
.game-icon-underhand:before {
  content: "\fff23";
}
.game-icon-underwear-shorts:before {
  content: "\fff24";
}
.game-icon-underwear:before {
  content: "\fff25";
}
.game-icon-unfriendly-fire:before {
  content: "\fff26";
}
.game-icon-unicorn:before {
  content: "\fff27";
}
.game-icon-unicycle:before {
  content: "\fff28";
}
.game-icon-union-jack:before {
  content: "\fff29";
}
.game-icon-unlit-bomb:before {
  content: "\fff2a";
}
.game-icon-unlit-candelabra:before {
  content: "\fff2b";
}
.game-icon-unlocking:before {
  content: "\fff2c";
}
.game-icon-unplugged:before {
  content: "\fff2d";
}
.game-icon-unstable-orb:before {
  content: "\fff2e";
}
.game-icon-unstable-projectile:before {
  content: "\fff2f";
}
.game-icon-up-card:before {
  content: "\fff30";
}
.game-icon-upgrade:before {
  content: "\fff31";
}
.game-icon-uprising:before {
  content: "\fff32";
}
.game-icon-ursa-major:before {
  content: "\fff33";
}
.game-icon-uruguay:before {
  content: "\fff34";
}
.game-icon-usa-flag:before {
  content: "\fff35";
}
.game-icon-usable:before {
  content: "\fff36";
}
.game-icon-usb-key:before {
  content: "\fff37";
}
.game-icon-ushanka:before {
  content: "\fff38";
}
.game-icon-uzi:before {
  content: "\fff39";
}
.game-icon-vacuum-cleaner:before {
  content: "\fff3a";
}
.game-icon-valley:before {
  content: "\fff3b";
}
.game-icon-valve:before {
  content: "\fff3c";
}
.game-icon-vampire-cape:before {
  content: "\fff3d";
}
.game-icon-vampire-dracula:before {
  content: "\fff3e";
}
.game-icon-van-damme-split:before {
  content: "\fff3f";
}
.game-icon-vanilla-flower:before {
  content: "\fff40";
}
.game-icon-velocipede:before {
  content: "\fff41";
}
.game-icon-velociraptor-tracks:before {
  content: "\fff42";
}
.game-icon-velociraptor:before {
  content: "\fff43";
}
.game-icon-vending-machine:before {
  content: "\fff44";
}
.game-icon-venezuela:before {
  content: "\fff45";
}
.game-icon-venus-flytrap:before {
  content: "\fff46";
}
.game-icon-venus-of-willendorf:before {
  content: "\fff47";
}
.game-icon-vertical-banner:before {
  content: "\fff48";
}
.game-icon-vertical-flip:before {
  content: "\fff49";
}
.game-icon-vhs:before {
  content: "\fff4a";
}
.game-icon-vial:before {
  content: "\fff4b";
}
.game-icon-vibrating-ball:before {
  content: "\fff4c";
}
.game-icon-vibrating-shield:before {
  content: "\fff4d";
}
.game-icon-vibrating-smartphone:before {
  content: "\fff4e";
}
.game-icon-video-camera:before {
  content: "\fff4f";
}
.game-icon-video-conference:before {
  content: "\fff50";
}
.game-icon-viking-church:before {
  content: "\fff51";
}
.game-icon-viking-head:before {
  content: "\fff52";
}
.game-icon-viking-helmet:before {
  content: "\fff53";
}
.game-icon-viking-longhouse:before {
  content: "\fff54";
}
.game-icon-viking-shield:before {
  content: "\fff55";
}
.game-icon-vile-fluid:before {
  content: "\fff56";
}
.game-icon-village:before {
  content: "\fff57";
}
.game-icon-vine-flower:before {
  content: "\fff58";
}
.game-icon-vine-leaf:before {
  content: "\fff59";
}
.game-icon-vine-whip:before {
  content: "\fff5a";
}
.game-icon-vines:before {
  content: "\fff5b";
}
.game-icon-vintage-robot:before {
  content: "\fff5c";
}
.game-icon-viola:before {
  content: "\fff5d";
}
.game-icon-violin:before {
  content: "\fff5e";
}
.game-icon-virgo:before {
  content: "\fff5f";
}
.game-icon-virtual-marker:before {
  content: "\fff60";
}
.game-icon-virus:before {
  content: "\fff61";
}
.game-icon-visored-helm:before {
  content: "\fff62";
}
.game-icon-vitruvian-man:before {
  content: "\fff63";
}
.game-icon-volcano:before {
  content: "\fff64";
}
.game-icon-volleyball-ball:before {
  content: "\fff65";
}
.game-icon-vomiting:before {
  content: "\fff66";
}
.game-icon-voodoo-doll:before {
  content: "\fff67";
}
.game-icon-vortex:before {
  content: "\fff68";
}
.game-icon-vote:before {
  content: "\fff69";
}
.game-icon-vr-headset:before {
  content: "\fff6a";
}
.game-icon-vulture:before {
  content: "\fff6b";
}
.game-icon-vuvuzelas:before {
  content: "\fff6c";
}
.game-icon-walk:before {
  content: "\fff6d";
}
.game-icon-walkie-talkie:before {
  content: "\fff6e";
}
.game-icon-walking-boot:before {
  content: "\fff6f";
}
.game-icon-walking-scout:before {
  content: "\fff70";
}
.game-icon-walking-turret:before {
  content: "\fff71";
}
.game-icon-wall-light:before {
  content: "\fff72";
}
.game-icon-wallet:before {
  content: "\fff73";
}
.game-icon-walrus-head:before {
  content: "\fff74";
}
.game-icon-walther-ppk:before {
  content: "\fff75";
}
.game-icon-wanted-reward:before {
  content: "\fff76";
}
.game-icon-war-axe:before {
  content: "\fff77";
}
.game-icon-war-bonnet:before {
  content: "\fff78";
}
.game-icon-war-pick:before {
  content: "\fff79";
}
.game-icon-warhammer:before {
  content: "\fff7a";
}
.game-icon-warlock-eye:before {
  content: "\fff7b";
}
.game-icon-warlock-hood:before {
  content: "\fff7c";
}
.game-icon-warp-pipe:before {
  content: "\fff7d";
}
.game-icon-washing-machine:before {
  content: "\fff7e";
}
.game-icon-wasp-sting:before {
  content: "\fff7f";
}
.game-icon-watch:before {
  content: "\fff80";
}
.game-icon-watchtower:before {
  content: "\fff81";
}
.game-icon-water-bolt:before {
  content: "\fff82";
}
.game-icon-water-bottle:before {
  content: "\fff83";
}
.game-icon-water-diviner-stick:before {
  content: "\fff84";
}
.game-icon-water-drop:before {
  content: "\fff85";
}
.game-icon-water-flask:before {
  content: "\fff86";
}
.game-icon-water-fountain:before {
  content: "\fff87";
}
.game-icon-water-gallon:before {
  content: "\fff88";
}
.game-icon-water-gun:before {
  content: "\fff89";
}
.game-icon-water-mill:before {
  content: "\fff8a";
}
.game-icon-water-polo:before {
  content: "\fff8b";
}
.game-icon-water-recycling:before {
  content: "\fff8c";
}
.game-icon-water-splash:before {
  content: "\fff8d";
}
.game-icon-water-tank:before {
  content: "\fff8e";
}
.game-icon-water-tower:before {
  content: "\fff8f";
}
.game-icon-waterfall:before {
  content: "\fff90";
}
.game-icon-watering-can:before {
  content: "\fff91";
}
.game-icon-watermelon:before {
  content: "\fff92";
}
.game-icon-wave-crest:before {
  content: "\fff93";
}
.game-icon-wave-strike:before {
  content: "\fff94";
}
.game-icon-wave-surfer:before {
  content: "\fff95";
}
.game-icon-waves:before {
  content: "\fff96";
}
.game-icon-wavy-chains:before {
  content: "\fff97";
}
.game-icon-wavy-itinerary:before {
  content: "\fff98";
}
.game-icon-wax-seal:before {
  content: "\fff99";
}
.game-icon-wax-tablet:before {
  content: "\fff9a";
}
.game-icon-web-spit:before {
  content: "\fff9b";
}
.game-icon-weight-crush:before {
  content: "\fff9c";
}
.game-icon-weight-lifting-down:before {
  content: "\fff9d";
}
.game-icon-weight-lifting-up:before {
  content: "\fff9e";
}
.game-icon-weight-scale:before {
  content: "\fff9f";
}
.game-icon-weight:before {
  content: "\fffa0";
}
.game-icon-well:before {
  content: "\fffa1";
}
.game-icon-werewolf:before {
  content: "\fffa2";
}
.game-icon-western-hat:before {
  content: "\fffa3";
}
.game-icon-whale-tail:before {
  content: "\fffa4";
}
.game-icon-wheat:before {
  content: "\fffa5";
}
.game-icon-wheelbarrow:before {
  content: "\fffa6";
}
.game-icon-whip:before {
  content: "\fffa7";
}
.game-icon-whiplash:before {
  content: "\fffa8";
}
.game-icon-whirlpool-shuriken:before {
  content: "\fffa9";
}
.game-icon-whirlwind:before {
  content: "\fffaa";
}
.game-icon-whisk:before {
  content: "\fffab";
}
.game-icon-whistle:before {
  content: "\fffac";
}
.game-icon-white-book:before {
  content: "\fffad";
}
.game-icon-white-cat:before {
  content: "\fffae";
}
.game-icon-white-tower:before {
  content: "\fffaf";
}
.game-icon-wide-arrow-dunk:before {
  content: "\fffb0";
}
.game-icon-wifi-router:before {
  content: "\fffb1";
}
.game-icon-wildfires:before {
  content: "\fffb2";
}
.game-icon-william-tell-skull:before {
  content: "\fffb3";
}
.game-icon-william-tell:before {
  content: "\fffb4";
}
.game-icon-willow-tree:before {
  content: "\fffb5";
}
.game-icon-winchester-rifle:before {
  content: "\fffb6";
}
.game-icon-wind-hole:before {
  content: "\fffb7";
}
.game-icon-wind-slap:before {
  content: "\fffb8";
}
.game-icon-wind-turbine:before {
  content: "\fffb9";
}
.game-icon-windchimes:before {
  content: "\fffba";
}
.game-icon-windmill:before {
  content: "\fffbb";
}
.game-icon-window-bars:before {
  content: "\fffbc";
}
.game-icon-window:before {
  content: "\fffbd";
}
.game-icon-windpump:before {
  content: "\fffbe";
}
.game-icon-windsock:before {
  content: "\fffbf";
}
.game-icon-windy-stripes:before {
  content: "\fffc0";
}
.game-icon-wine-bottle:before {
  content: "\fffc1";
}
.game-icon-wine-glass:before {
  content: "\fffc2";
}
.game-icon-wing-cloak:before {
  content: "\fffc3";
}
.game-icon-winged-arrow:before {
  content: "\fffc4";
}
.game-icon-winged-emblem:before {
  content: "\fffc5";
}
.game-icon-winged-leg:before {
  content: "\fffc6";
}
.game-icon-winged-scepter:before {
  content: "\fffc7";
}
.game-icon-winged-shield:before {
  content: "\fffc8";
}
.game-icon-winged-sword:before {
  content: "\fffc9";
}
.game-icon-wingfoot:before {
  content: "\fffca";
}
.game-icon-winter-gloves:before {
  content: "\fffcb";
}
.game-icon-winter-hat:before {
  content: "\fffcc";
}
.game-icon-wire-coil:before {
  content: "\fffcd";
}
.game-icon-wireframe-globe:before {
  content: "\fffce";
}
.game-icon-wisdom:before {
  content: "\fffcf";
}
.game-icon-witch-face:before {
  content: "\fffd0";
}
.game-icon-witch-flight:before {
  content: "\fffd1";
}
.game-icon-wizard-face:before {
  content: "\fffd2";
}
.game-icon-wizard-staff:before {
  content: "\fffd3";
}
.game-icon-wok:before {
  content: "\fffd4";
}
.game-icon-wolf-head:before {
  content: "\fffd5";
}
.game-icon-wolf-howl:before {
  content: "\fffd6";
}
.game-icon-wolf-trap:before {
  content: "\fffd7";
}
.game-icon-wolverine-claws-2:before {
  content: "\fffd8";
}
.game-icon-wolverine-claws:before {
  content: "\fffd9";
}
.game-icon-woman-elf-face:before {
  content: "\fffda";
}
.game-icon-wood-axe:before {
  content: "\fffdb";
}
.game-icon-wood-beam:before {
  content: "\fffdc";
}
.game-icon-wood-cabin:before {
  content: "\fffdd";
}
.game-icon-wood-canoe:before {
  content: "\fffde";
}
.game-icon-wood-club:before {
  content: "\fffdf";
}
.game-icon-wood-frame:before {
  content: "\fffe0";
}
.game-icon-wood-pile:before {
  content: "\fffe1";
}
.game-icon-wood-stick:before {
  content: "\fffe2";
}
.game-icon-wooden-chair:before {
  content: "\fffe3";
}
.game-icon-wooden-clogs:before {
  content: "\fffe4";
}
.game-icon-wooden-crate:before {
  content: "\fffe5";
}
.game-icon-wooden-door:before {
  content: "\fffe6";
}
.game-icon-wooden-fence:before {
  content: "\fffe7";
}
.game-icon-wooden-helmet:before {
  content: "\fffe8";
}
.game-icon-wooden-pegleg:before {
  content: "\fffe9";
}
.game-icon-wooden-pier:before {
  content: "\fffea";
}
.game-icon-wooden-sign:before {
  content: "\fffeb";
}
.game-icon-wool:before {
  content: "\fffec";
}
.game-icon-world:before {
  content: "\fffed";
}
.game-icon-worm-mouth:before {
  content: "\fffee";
}
.game-icon-worms:before {
  content: "\fffef";
}
.game-icon-worried-eyes:before {
  content: "\ffff0";
}
.game-icon-wrapped-heart:before {
  content: "\ffff1";
}
.game-icon-wrapped-sweet:before {
  content: "\ffff2";
}
.game-icon-wrapping-star:before {
  content: "\ffff3";
}
.game-icon-wrecking-ball:before {
  content: "\ffff4";
}
.game-icon-wrench:before {
  content: "\ffff5";
}
.game-icon-wyvern:before {
  content: "\ffff6";
}
.game-icon-xylophone:before {
  content: "\ffff7";
}
.game-icon-yarn:before {
  content: "\ffff8";
}
.game-icon-yin-yang:before {
  content: "\ffff9";
}
.game-icon-yunluo:before {
  content: "\ffffa";
}
.game-icon-z-brick:before {
  content: "\ffffb";
}
.game-icon-zat-gun:before {
  content: "\ffffc";
}
.game-icon-zebra-shield:before {
  content: "\ffffd";
}
.game-icon-zeppelin:before {
  content: "\ffffe";
}
.game-icon-zeus-sword:before {
  content: "\fffff";
}
.game-icon-zig-arrow:before {
  content: "\100000";
}
.game-icon-zigzag-cage:before {
  content: "\100001";
}
.game-icon-zigzag-hieroglyph:before {
  content: "\100002";
}
.game-icon-zigzag-leaf:before {
  content: "\100003";
}
.game-icon-zigzag-tune:before {
  content: "\100004";
}
.game-icon-zipper:before {
  content: "\100005";
}
